import { FirebaseError } from "firebase/app";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleFirebaseError = (error: FirebaseError | any) => {
	console.log(error.code);
	if (error instanceof FirebaseError) {
		switch (error.code) {
			case "auth/invalid-email":
				error.message = "Invalid email address";
				break;
			case "auth/wrong-password":
				error.message = "Incorrect email or password";
				break;
			case "auth/user-not-found":
				error.message = "Account does not exist. Please sign up first";
				break;
			case "auth/email-already-in-use":
				error.message = "Email already in use by another account";
				break;
			case "auth/weak-password":
				error.message =
					"Password is too weak. Must be longer than 6 charcters";
				break;
			case "auth/account-exists-with-different-credential":
				error.message =
					"An account with the same email already exists. Please sign in with that account or use a different email";
				break;
			case "auth/internal-error":
				error.message =
					"This email is not registered for Beta testing. Redirecting you to the sign up page";
				break;
			default:
				error.message = "An unknown error occurred. Please try again";
		}
	} else {
		error.message = "An unknown error occurred. Please try again";
	}

	return error;
};
