export const banks = [
    {
        displayName: "Absa Bank",
        branchCode: 632005,
    },
    {
        displayName: "African Bank",
        branchCode: 430000,
    },
    {
        displayName: "Bank of Athens",
        branchCode: 410506,
    },
    {
        displayName: "Bank Zero",
        branchCode: 888000,
    },
    {
        displayName: "Barclays Bank",
        branchCode: 590000,
    },
    {
        displayName: "Bidvest Bank",
        branchCode: 462005,
    },
    {
        displayName: "Capitec Bank",
        branchCode: 470010,
    },
    {
        displayName: "Discovery Bank",
        branchCode: 679000,
    },
    {
        displayName: "First National Bank",
        branchCode: 250655,
    },
    {
        displayName: "FirstRand Bank Ltd",
        branchCode: 201419,
    },
    {
        displayName: "Grindrod Bank",
        branchCode: 223626,
    },
    {
        displayName: "HSBC Bank ",
        branchCode: 587000,
    },
    {
        displayName: "Investec ",
        branchCode: 580105,
    },
    {
        displayName: "Mercantile Bank",
        branchCode: 450905,
    },
    {
        displayName: "Nedbank ",
        branchCode: 198765,
    },
    {
        displayName: "Old Mutual ",
        branchCode: 462005,
    },
    {
        displayName: "PEP Bank",
        branchCode: 400005,
    },
    {
        displayName: "Permanent Bank",
        branchCode: 760005,
    },
    {
        displayName: "Rand Merchant Bank",
        branchCode: 261251,
    },
    {
        displayName: "RMB Private Bank",
        branchCode: 222026,
    },
    {
        displayName: "Sasfin Bank",
        branchCode: 683000,
    },
    {
        displayName: "SA Post Office Bank",
        branchCode: 460005,
    },
    {
        displayName: "Standard Chartered Bank",
        branchCode: 730020,
    },
    {
        displayName: "Standard Bank of South Africa ",
        branchCode: 0o51001,
    },
    {
        displayName: "Tyme Bank ",
        branchCode: 	678910,
    },
];

export const accountType = [
    {
        displayName: "Cheque Account",
    },
    {
        displayName: "Savings Account",
    },
    {
        displayName: "Transmission Account",
    },
    {
        displayName: "Bond Account",
    },
    {
        displayName: "Credit Card Account",
    },
    {
        displayName: "Subscription Account",
    },
];