// import your custom CSS file
import { Hit } from "@algolia/client-search";
import {
	Artwork,
	Artwork_Medium,
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { List } from "antd";
import ShopPrintsImage from "assets/images/printButtonBackground.png";
import ArtworkCard from "components/ArtworkCard/ArtworkCard";
import PillButton from "components/Buttons/PillButton";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";
import { useMessage } from "components/Messages/MessageProvider";
import FilterModal from "components/Modals/FilterModal/FilterModal";
import CollapsedFilter from "components/Modals/FilterModal/Views/CollapsedFilter";
import { logEvent } from "firebase/analytics";
import { onSnapshot } from "firebase/firestore";
import { useAuth } from "hooks/useAuth";
import useScrollPosition from "hooks/useScrollPosition";
import React, { useCallback, useEffect, useState } from "react";
import { use100vh } from "react-div-100vh";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import StackGrid from "react-stack-grid";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-md.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { analytics } from "../../config/firebase";
import { artworkMediums } from "../../enums/artworks";
import { apiService } from "../../services/api";
import { databaseService } from "../../services/database";
import UserListItem from "../Network/Components/UserListItem";

const SearchPage = () => {
	const navigate = useNavigate();
	useScrollPosition("SearchPageScrollPosition");
	const user = useAuth();
	const location = useLocation();
	// States for user tab
	const [tabValue, setTabValue] = useState("art");
	const [query, setQuery] = useState("");
	const [userResults, setUserResults] = useState<Hit<any>[]>(
		[] as Hit<any>[]
	);
	const [showCancelButton, setShowCancelButton] = useState<boolean>(false); // state for search bar cancel button

	// state for orderByCriteria --> default ascending
	const [selectedPriceOrder, setSelectedPriceOrder] = useState("asc");

	const [artworkResults, setArtworkResults] = useState<Artwork[]>([]);
	const [artworksLoading, setArtworksLoading] = useState(false);

	// states to manage pagination in users search
	const [currentPage, setCurrentPage] = useState(0);
	const [upToDate, setUpToDate] = useState(false);

	const [imageLoaded, setImageLoaded] = useState(false);

	const [priceRangeMin, setPriceRangeMin] = useState(null);
	const [priceRangeMax, setPriceRangeMax] = useState(null);

	// Medium and style filtering
	const [mediumFilter, setMediumFilter] = useState("");

	//pagination useStates
	const [nextPageToken, setNextPageToken] = useState<string | undefined>(
		undefined
	);
	const [hasMore, setHasMore] = useState(true);
	const isUserTab = tabValue === "users";

	const { errorMessage } = useMessage();

	// For medium filter pill button
	const [selectedMedium, setSelectedMedium] = useState<Artwork_Medium | "">(
		""
	);

	//State to check if modal is expanded
	const [isExpanded, setIsExpanded] = useState(false);

	// Function to switch tab to "users"
	const switchToUserTab = () => {
		setTabValue("users");
		setShowCancelButton(true); // Show the "Cancel" button
	};

	// Function to hide the "Cancel" button and switch tab
	const handleCancel = () => {
		setQuery(""); // Clear the search query
		setTabValue("art");
		setShowCancelButton(false); // Hide the "Cancel" button
	};

	const handleMediumClick = (medium) => {
		setMediumFilter(medium);

		if (selectedMedium === medium) {
			// Set medium to empty string
			setSelectedMedium("");
			setArtworkResults([]); // Clear previous artworks
			// Resets page token
			setNextPageToken(null);
		} else {
			setSelectedMedium(medium);
			// Fetch artworks based on the selected medium
			//setArtworkResults([]); // Clear previous artworks
		}
	};

	// Function to render PillButton for selected medium
	const renderSelectedMediumPill = () => {
		if (selectedMedium) {
			// Find the human-readable name for the selected medium enum
			const foundMedium = artworkMediums.find(
				(medium) => medium.enum === selectedMedium
			);

			// If found, use the display name; otherwise, fall back to the enum value
			const label = foundMedium
				? foundMedium.displayName
				: selectedMedium;

			return (
				<PillButton
					label={`${label}`}
					className="text-sm font-inter px-2.5 py-1 whitespace-nowrap bg-teal-900 border-teal-100 text-teal-100 border"
					icon={
						<XClose
							className="mt-1 w-4 h-4"
							onClick={() => handleMediumClick("")}
						/>
					}
				/>
			);
		}
		return null;
	};

	// Function to render PillButton for selected min price
	const renderMinPricePill = () => {
		if (priceRangeMin > 0) {
			return (
				<PillButton
					label={`More than ${priceRangeMin}`}
					// Add the functionality to remove the min price filter
					icon={
						<XClose
							className="mt-1 w-4 h-4"
							onClick={() => setPriceRangeMin(0)}
						/>
					}
					className="text-sm font-inter px-2.5 py-1 whitespace-nowrap bg-teal-900 border-teal-100 text-teal-100 border"
				/>
			);
		}
		return null;
	};

	// Function to render PillButton for selected max price
	const renderMaxPricePill = () => {
		if (priceRangeMax > 0) {
			return (
				<PillButton
					label={`Less than ${priceRangeMax}`}
					// Add the functionality to remove the max price filter
					icon={
						<XClose
							className="mt-1 w-4 h-4"
							onClick={() => setPriceRangeMax(0)}
						/>
					}
					className="text-sm font-inter px-2.5 py-1 whitespace-nowrap bg-teal-900 border-teal-100 text-teal-100 border"
				/>
			);
		}
		return null;
	};

	// function to search users
	const searchUsers = useCallback(async (query: string, page?: number) => {
		try {
			console.log("Searching for user...");
			const results = await databaseService.searchUsers(query, page);
			console.log(results);
			// Append new users to existing list
			setUserResults((prevResults) => [...prevResults, ...results.hits]);

			// Update pagination state
			if (results.hits.length < 15) {
				setUpToDate(true);
			}
		} catch (error) {
			errorMessage("There was a problem searching users");
		}
	}, []);

	const fetchArtworks = useCallback(async () => {
		try {
			setArtworksLoading(true);

			const filterCriteria = [
				selectedMedium ? `medium = ${selectedMedium}` : "", // Include selected medium filter if provided
				priceRangeMin
					? `current_price >= ${priceRangeMin.toFixed(2)}`
					: "", // Min price filter
				priceRangeMax
					? `current_price <= ${priceRangeMax.toFixed(2)}`
					: "", // Max price filter
			]
				.filter(Boolean)
				.join(" AND "); // Join all criteria with "AND"

			console.log(filterCriteria);

			const orderByCriteria =
				priceRangeMin || priceRangeMax
					? `current_price ${selectedPriceOrder}`
					: "";

			const artworks = await apiService.listArtworks({
				pageSize: 15,
				orderBy: orderByCriteria,
				readPaths: ["name"],
				filter: filterCriteria,
			});

			setNextPageToken(artworks.nextPageToken);
			console.log(
				"Number of fetched artworks:",
				artworks.artworks.length
			);

			setArtworkResults((prevArtworks) => {
				const newUniqueArtworks = artworks.artworks.filter(
					(artwork) =>
						!prevArtworks.some(
							(prevArtwork) => prevArtwork.name === artwork.name
						)
				);
				return [...prevArtworks, ...newUniqueArtworks];
			});

			if (artworks.artworks.length < 15) {
				setHasMore(false);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setArtworksLoading(false);
		}
	}, [selectedMedium, priceRangeMin, priceRangeMax, selectedPriceOrder]);

	const fetchMoreArtworks = async () => {
		if (hasMore) {
			try {
				setArtworksLoading(true);

				const filterCriteria = [
					selectedMedium ? `medium = ${selectedMedium}` : "", // Include selected medium filter if provided
					priceRangeMin
						? `current_price >= ${priceRangeMin.toFixed(2)}`
						: "", // Min price filter
					priceRangeMax
						? `current_price <= ${priceRangeMax.toFixed(2)}`
						: "", // Max price filter
				]
					.filter(Boolean)
					.join(" AND "); // Join all criteria with "AND"

				console.log(filterCriteria);

				const orderByCriteria =
					priceRangeMin || priceRangeMax
						? `current_price ${selectedPriceOrder}`
						: "";

				const newArtworks = await apiService.listArtworks({
					readPaths: ["name"],
					pageSize: 15,
					orderBy: orderByCriteria,
					pageToken: nextPageToken,
					filter: filterCriteria,
				});

				console.log(newArtworks);
				if (newArtworks.artworks.length < 15) {
					setHasMore(false);
				}

				if (newArtworks.artworks.length === 0) {
					console.log("No more artworks");
					setHasMore(false);
				}

				setArtworkResults((prevArtworks) => {
					const newUniqueArtworks = newArtworks.artworks.filter(
						(artwork) =>
							!prevArtworks.some(
								(prevArtwork) =>
									prevArtwork.name === artwork.name
							)
					);
					return [...prevArtworks, ...newUniqueArtworks];
				});
				setNextPageToken(newArtworks.nextPageToken);
			} catch (error) {
				errorMessage("There was a problem loading artworks");
			} finally {
				setArtworksLoading(false);
			}
		}
	};

	const subscribeArtworks = useCallback(() => {
		const unsubscribe = onSnapshot(databaseService.artworks, () => {
			console.log("fetch artworks 1");
			fetchArtworks();
		});

		return unsubscribe;
	}, [fetchArtworks]);

	const goToNextPage = () => {
		setCurrentPage((prevPage) => prevPage + 1);
	};

	useEffect(() => {
		if (isUserTab) {
			if (query === "") {
				searchUsers("", currentPage);
			} else {
				searchUsers(query, currentPage);
			}
		}
	}, [query, currentPage, isUserTab]);

	useEffect(() => {
		const img = new Image();
		img.src = ShopPrintsImage;
		img.onload = () => {
			setImageLoaded(true);
		};
	}, []);

	useEffect(() => {
		if (priceRangeMin || priceRangeMax || selectedMedium) {
			// This will be called whenever `priceRangeMin` or `priceRangeMax` or selectedMedium changes
			setArtworkResults([]); // Clear previous artworks
			// Resets page token
			setNextPageToken(null);
			console.log("fetch artworks 3");
			fetchArtworks();
			console.log("fetchArtworks with price and/or medium called");
		}
	}, [priceRangeMin, priceRangeMax, selectedMedium, selectedPriceOrder]);

	useEffect(() => {
		console.log("Initial fetchArtworks called");
		setNextPageToken(null);
		fetchArtworks();

		// Subscribe to artworks
		const unsubscribe = subscribeArtworks();

		// Unsubscribe when we unmount the component
		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		setCurrentPage(0);
		setUpToDate(false);
		setUserResults([]);
	}, [query]);

	const ContainerHeight = use100vh() - 130;

	useEffect(() => {
		document.title = "Search | Artbeat ";
	}, []);

	return (
		<>
			<div className="flex flex-row px-4 pt-4 w-full gap-3">
				<div className="SearchBarBorder flex-grow">
					<div className="InnerSearchDiv">
						<SearchIcon className="w-5 h-5" />
						<div className="SearchInput">
							<input
								className=" placeholder-greyscale-450 text-white bg-transparent border-none w-full focus:outline-none"
								placeholder="Artists, Collectors & Community"
								onClick={switchToUserTab} // Set tab to "users" when input is clicked
								onChange={(e) => setQuery(e.target.value)}
								value={query}
							/>
						</div>
					</div>
				</div>
				{/* Conditionally render the "Cancel" button */}
				{showCancelButton && (
					<button
						onClick={handleCancel}
						className="bg-transparent border-none text-red-600 font-inter text-lg not-italic font-normal"
					>
						Cancel
					</button>
				)}
			</div>

			{isUserTab ? (
				<>
					<div
						id="scrollableDiv"
						style={{
							height: `${ContainerHeight}px`,
							overflowY: "scroll",
						}}
					>
						<InfiniteScroll
							dataLength={userResults.length} // This is important field to render the next data
							next={goToNextPage}
							hasMore={!upToDate}
							loader={<></>}
							endMessage={
								<p style={{ textAlign: "center" }}>
									<b></b>
								</p>
							}
							scrollableTarget="scrollableDiv"
						>
							<List
								style={{
									height: "100%",
								}}
								itemLayout="horizontal"
								dataSource={userResults}
								renderItem={(item) => (
									<UserListItem
										name={item.Name}
									></UserListItem>
								)}
							/>
						</InfiniteScroll>
					</div>
				</>
			) : (
				<>
					<div className="relative">
						<div className="sticky top-0 z-50">
							<div className="flex flex-row horizontal-scroll w-full px-4 py-2 items-center gap-3 backdrop-blur-sm bg-[#000000] bg-opacity-90 ">
								<CollapsedFilter
									onExpand={() => setIsExpanded(true)}
								/>
								{renderSelectedMediumPill()}{" "}
								{renderMinPricePill()}
								{renderMaxPricePill()}
								{/* Render PillButton conditionally */}
							</div>
						</div>

						{imageLoaded ? (
							// Render the button when the image is loaded
							<div className="w-full pb-2 px-4 bg-transparent border-none">
								<button
									className="w-full rounded-2xl py-8 text-white font-ubuntu relative overflow-hidden border-black bg-greyscale-550"
									onClick={() => navigate("/prints")}
								>
									<img
										src={ShopPrintsImage}
										alt="Hero Background"
										className="border-none"
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											position: "absolute",
											top: 0,
											left: 0,
											zIndex: 1,
										}}
									/>
									<span
										className="text-lg "
										style={{
											position: "relative",
											zIndex: 3,
										}}
									>
										Shop Prints
									</span>
								</button>
							</div>
						) : (
							// Render a skeleton loader when the image is not loaded
							<div className="w-full pb-2 px-4 bg-transparent border-none">
								<div className="w-full rounded-2xl h-[92px] bg-greyscale-550 animate-pulse"></div>
							</div>
						)}

						<div className="mx-auto px-2">
							<InfiniteScroll
								dataLength={artworkResults.length}
								next={fetchMoreArtworks}
								hasMore={hasMore}
								loader={
									artworksLoading && (
										<div
											className="flex justify-center items-center"
											style={{ height: "100px" }}
										>
											{LoadingSpinner("#1EB9A5")}
										</div>
									)
								}
								scrollThreshold={0.7}
								endMessage={
									!hasMore && (
										<div
											className="flex justify-center items-center"
											style={{ height: "60px" }}
										>
											<p className="align-center justify-center font-inter text-sm"></p>
										</div>
									)
								}
								refreshFunction={fetchArtworks}
								pullDownToRefresh
								pullDownToRefreshThreshold={50}
								pullDownToRefreshContent={
									<p className="justify-center font-inter text-center align-middle text-sm">
										Pull down to refresh
									</p>
								}
								releaseToRefreshContent={
									<h3
										style={{
											textAlign: "center",
											color: "white",
										}}
									>
										&#8593; Release to refresh
									</h3>
								}
							>
								<StackGrid
									className="mx-2"
									columnWidth={"50%"}
									gutterWidth={8}
									gutterHeight={8}
									monitorImagesLoaded
									duration={0}
								>
									{artworkResults.map((artwork) => (
										<div
											key={artwork.name}
											style={{ marginTop: 0 }}
										>
											<ArtworkCard
												artwork={artwork}
												onClick={() => {
													navigate(
														`/${artwork.name}`,
														{
															state: {
																from: location.pathname,
															},
														}
													);
													console.log("testing");
													logEvent(
														analytics,
														"select_item",
														{
															item_id:
																artwork.name,
															item_name:
																artwork.title,
															item_category:
																artwork.medium,
															item_variant:
																artwork.style,
															item_list:
																"search_page", // Specifies the location from which the item was selected
															// ... any other relevant details about the artwork or the action
														}
													);
												}}
											></ArtworkCard>
										</div>
									))}
								</StackGrid>
							</InfiniteScroll>
						</div>

						<div
							// set the height as that of the navbar which has id navbar-bottom
							style={{
								height: "55px",
							}}
						></div>
					</div>
				</>
			)}

			{/* Transparent div to avoid the clicking of ArtworkCard components when exiting the FilterModal */}
			{isExpanded && (
				<div
					className="fixed inset-0 z-20" // Fixed positioning and z-index higher than other elements but lower than FilterModal
					onClick={() => setIsExpanded(false)} // Collapse FilterModal when clicked
				></div>
			)}
			<FilterModal
				selectedMedium={selectedMedium}
				handleMediumClick={handleMediumClick}
				artworkMediums={artworkMediums}
				setPriceRangeMin={setPriceRangeMin}
				setPriceRangeMax={setPriceRangeMax}
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
				priceRangeMax={priceRangeMax}
				priceRangeMin={priceRangeMin}
				setSelectedPriceOrder={setSelectedPriceOrder}
				selectedPriceOrder={selectedPriceOrder}
			/>
		</>
	);
};

export default SearchPage;
