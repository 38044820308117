import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { ReactComponent as ArtBeatWhiteWordMark } from "../../assets/icons/Artbeat White SVG.svg";

export const LoadingPage = () => {
	return (
		<div className="h-full w-full flex flex-col justify-center items-center">
			<ArtBeatWhiteWordMark className="mb-8" />
			<Spin size="large" indicator={<LoadingOutlined spin />} />
		</div>
	);
};
