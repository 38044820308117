import { auth } from "config/firebase";
import { onAuthStateChanged, User } from "firebase/auth";
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { authenticationService } from "services/authentication";

interface IAuthContext {
	user?: User | null;
}

const AuthContext = createContext<IAuthContext>({});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<User | null>();

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user == null) {
				authenticationService.signInAnonymously();
			} else if (user == undefined) {
				return;
			} else if (user) {
				setUser(user);
				authenticationService.fetchUser(user);
			}
		});

		return () => {
			unsubscribe();
		};
	}, []);

	const value = useMemo(() => {
		return {
			user,
		};
	}, [user]);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
