import React, { useState } from "react";
import SplashScreen from "./SplashScreen";

export const useSplashScreen = () => {
  const [isSplashScreenOpen, setSplashScreenOpen] = useState(false);

  const toggleSplashScreen = () => setSplashScreenOpen(!isSplashScreenOpen);

  const SplashScreenComponent = () => (
    <>{isSplashScreenOpen && <SplashScreen onClose={toggleSplashScreen}></SplashScreen>}</>
  );

  return { isSplashScreenOpen, toggleSplashScreen, SplashScreenComponent };
}; 