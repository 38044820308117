import { createPromiseClient,PromiseClient } from "@bufbuild/connect";
import { createGrpcWebTransport } from "@bufbuild/connect-web";
import { FieldMask } from "@bufbuild/protobuf";
import {
Artwork,
BatchGetArtworksRequest,
CreateArtworkRequest,
GetArtworkRequest,
LikeArtworkRequest,
ListArtworksRequest,
UnlikeArtworkRequest
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb.js";
import {
Comment,
CreateCommentRequest,
DeleteCommentRequest,
GetCommentRequest,
ListCommentsRequest,
UpdateCommentRequest
} from "@hockney-app/proto/artworks/v1alpha1/comments_pb";
import {
AddArtworkRequest,
GetCartRequest,
RemoveArtworkRequest
} from "@hockney-app/proto/carts/v1alpha1/carts_pb";
import { ConsoleService } from "@hockney-app/proto/console/v1alpha1/console_connect";
import { CheckoutRequest } from "@hockney-app/proto/console/v1alpha1/console_pb";
import {
CreateOrderRequest,
GetOrderRequest
} from "@hockney-app/proto/orders/v1alpha1/orders_pb";
import {
CreatePaymentRequest,
GetPaymentRequest,
Payment,
} from "@hockney-app/proto/payments/v1alpha1/payments_pb";
import {
InitializeTransactionRequest,
} from "@hockney-app/proto/payments/v1alpha1/paystack_pb";
import { InitiateRedirectBasedCheckoutRequest } from "@hockney-app/proto/payments/v1alpha1/peach_pb";
import { Address } from "@hockney-app/proto/types/v1alpha1/address_pb";
import { Contact } from "@hockney-app/proto/types/v1alpha1/contact_details_pb";
import {
	AddToWishlistRequest,
	CreateUserRequest,
	FollowUserRequest,
	GetUserRequest,
	ListUsersRequest,
	RemoveFromWishlistRequest,
	UnfollowUserRequest,
	UpdateUserRequest,
	User,
} from "@hockney-app/proto/users/v1alpha1/users_pb";

interface GetMethodOptions {
	readPaths?: string[];
}

interface ListMethodOptions extends GetMethodOptions {
	pageSize?: number | 0;
	pageToken?: string | "";
	filter?: string | "";
	orderBy?: string | "";
}

interface BatchGetMethodOptions {
	names: string[];
}

/**
 * APIService handles all communication to the grpc backend
 */
class APIService {
	private client: PromiseClient<typeof ConsoleService>;
	private idToken?: string | null;

	constructor(hostname: string) {
		const transport = createGrpcWebTransport({
			baseUrl: hostname,
		});
		this.client = createPromiseClient(ConsoleService, transport);
	}

	/**
	 * Sets a default idToken to use instead of providing it on each method call.
	 * Can be overriden by passing the token parameter to any method call
	 * @param idToken
	 */
	setIDToken(idToken: string | null) {
		this.idToken = idToken;
	}

	/**
	 * Creates a new user
	 * @param newUser The user to create
	 * @param idToken The id token of the currently logged in user.
	 */
	createUser(newUser: User, idToken?: string) {
		// Create a new request
		const req = new CreateUserRequest();

		// Set the user field
		req.user = newUser;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.createUser(req, {
			headers: headers,
		});
	}

	/**
	 * Gets a user using their qualified resource name
	 * @param name The qualified resource name. e.g. users/123
	 * @param idToken The id token of the currently logged in user.
	 */
	getUser(name: string, { readPaths }: GetMethodOptions, idToken?: string) {
		// Create a new request
		const req = new GetUserRequest();

		// Set the user field
		req.name = name;

		// Set the read mask
		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;

			req.readMask = readMask;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.getUser(req, {
			headers: headers,
		});
	}

	/**
	 * Creates a new user
	 * @param user The user to update
	 * @param idToken The id token of the currently logged in user.
	 */
	updateUser(user: User, updatePaths: string[], idToken?: string) {
		// Create a new request
		const req = new UpdateUserRequest();

		// Set the user field
		req.user = user;

		// Set the update mask
		const updateMask = new FieldMask();
		updateMask.paths = updatePaths;

		req.updateMask = updateMask;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.updateUser(req, {
			headers: headers,
		});
	}

	/**
	 * Lists users
	 * @param options
	 * @param idToken The id token of the currently logged in user.
	 */
	listUsers(
		{ pageSize, pageToken, filter, readPaths }: ListMethodOptions,
		idToken?: string
	) {
		const req = new ListUsersRequest();

		if (pageSize) {
			req.pageSize = pageSize;
		}

		if (pageToken) {
			req.pageToken = pageToken;
		}

		if (filter) {
			req.filter = filter;
		}

		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;

			req.readMask = readMask;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.listUsers(req, {
			headers: headers,
		});
	}

	/**
	 * Follows a user
	 * @param user
	 * @param idToken
	 */
	followUser(user: string, idToken?: string) {
		const req = new FollowUserRequest();
		req.userToFollow = user;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.followUser(req, {
			headers: headers,
		});
	}

	/**
	 * Unfollows a user
	 * @param user
	 * @param idToken
	 */
	unfollowUser(user: string, idToken?: string) {
		const req = new UnfollowUserRequest();
		req.userToUnfollow = user;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.unfollowUser(req, {
			headers: headers,
		});
	}

	createArtwork(artwork: Artwork, artworkId?: string, idToken?: string) {
		const req = new CreateArtworkRequest();

		req.artwork = artwork;
		if (artworkId) {
			req.artworkId = artworkId;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.createArtwork(req, {
			headers: headers,
		});
	}
	getArtwork(
		name: string,
		{ readPaths }: GetMethodOptions,
		idToken?: string
	) {
		const req = new GetArtworkRequest();

		req.name = name;

		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;

			// TODO: Set the read mask
			// req.readMask = readMask;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.getArtwork(req, {
			headers: headers,
		});
	}
	/**
	 * Lists artworks
	 * @param options
	 * @param idToken The id token of the currently logged in user.
	 */
	listArtworks(
		{ pageSize, pageToken, filter, orderBy, readPaths }: ListMethodOptions,
		idToken?: string
	) {
		console.log("list artworks called in backend"); 
		const req = new ListArtworksRequest();

		if (pageSize) {
			req.pageSize = pageSize;
		}

		if (pageToken) {
			req.pageToken = pageToken;
		}

		if (filter) {
			req.filter = filter;
		}

		if (orderBy) {
			req.orderBy = orderBy;
		}

		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;

			req.readMask = readMask;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.listArtworks(req, {
			headers: headers,
		});
	}
	/** Batch get artworks **/
	batchGetArtworks({ names }: BatchGetMethodOptions, idToken?: string) {
		const req = new BatchGetArtworksRequest();
		console.log("Batch get artworks called in backend");

		if (names) {
			req.names = names;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.batchGetArtworks(req, {
			headers: headers,
		});
	}

	likeArtwork(artwork: string, idToken?: string) {
		const req = new LikeArtworkRequest();
		req.name = artwork;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.likeArtwork(req, {
			headers: headers,
		});
	}
	unlikeArtwork(artwork: string, idToken?: string) {
		const req = new UnlikeArtworkRequest();
		req.name = artwork;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.unlikeArtwork(req, {
			headers: headers,
		});
	}

	CreateComment(artwork: string, comment: Comment, idToken?: string) {
		const req = new CreateCommentRequest();
		req.parent = artwork;
		req.comment = comment;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.createComment(req, {
			headers: headers,
		});
	}

	GetComment(
		name: string,
		{ readPaths }: GetMethodOptions,
		idToken?: string
	) {
		const req = new GetCommentRequest();

		req.name = name;

		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;

			// TODO: Set the read mask
			// req.readMask = readMask;
		}
		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.getComment(req, {
			headers: headers,
		});
	}

	updateComment(comment: Comment, updatePaths: string[], idToken?: string) {
		const req = new UpdateCommentRequest();
		req.comment = comment;

		// Set the update mask
		const updateMask = new FieldMask();
		updateMask.paths = updatePaths;

		req.updateMask = updateMask;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.updateComment(req, {
			headers: headers,
		});
	}

	DeleteComment(name: string, idToken?: string) {
		const req = new DeleteCommentRequest();

		req.name = name;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.deleteComment(req, {
			headers: headers,
		});
	}

	listComments(
		parent: string,
		{ pageSize, pageToken, filter, orderBy, readPaths }: ListMethodOptions,
		idToken?: string
	) {
		const req = new ListCommentsRequest();
		req.parent = parent;

		if (pageSize) {
			req.pageSize = pageSize;
		}

		if (pageToken) {
			req.pageToken = pageToken;
		}

		if (filter) {
			req.filter = filter;
		}

		if (orderBy) {
			req.orderBy = orderBy;
		}

		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;

			req.readMask = readMask;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client
			.listComments(req, {
				headers: headers,
			})
			.then((response) => {
				return response;
			})
			.catch((error) => {
				throw error;
			});
	}

	getCart(idToken?: string) {
		const req = new GetCartRequest();

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.getCart(req, {
			headers: headers,
		});
	}
	addArtworkToCart(artwork: string, idToken?: string) {
		const req = new AddArtworkRequest();
		req.artwork = artwork;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.addArtworkToCart(req, {
			headers: headers,
		});
	}
	/**
	 * Adds artwork to users wishlist
	 * @param options
	 * @param idToken The id token of the currently logged in user.
	 */
	addToWishlist(user: string, artwork: string, idToken?: string) {
		const req = new AddToWishlistRequest();
		req.user = user;
		req.artwork = artwork;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.addToWishlist(req, {
			headers: headers,
		});
	}

	/**
	 * Adds artwork to users wishlist
	 * @param options
	 * @param idToken The id token of the currently logged in user.
	 */
	removeFromWishlist(user: string, artwork: string, idToken?: string) {
		const req = new RemoveFromWishlistRequest();
		req.user = user;
		req.artwork = artwork;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.removeFromWishlist(req, {
			headers: headers,
		});
	}

	removeArtworkFromCart(artwork: string, idToken?: string) {
		const req = new RemoveArtworkRequest();
		req.artwork = artwork;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.removeArtworkFromCart(req, {
			headers: headers,
		});
	}

	checkout(idToken?: string) {
		const req = new CheckoutRequest();

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.checkout(req, {
			headers: headers,
		});
	}

	createOrder(idToken?: string) {
		const req = new CreateOrderRequest();

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.createOrder(req, {
			headers: headers,
		});
	}
	getOrder(name: string, { readPaths }: GetMethodOptions, idToken?: string) {
		const req = new GetOrderRequest();

		req.name = name;

		if (readPaths) {
			const readMask = new FieldMask();
			readMask.paths = readPaths;
		}

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.getOrder(req, {
			headers: headers,
		});
	}

	createPayment(
		artworks: string[],
		deliveryAddress: Address,
		anonymousUserContact?: Contact,
		idToken?: string
	) {
		const req = new CreatePaymentRequest();

		const payment = new Payment();
		payment.artworks = artworks;
		payment.deliveryAddress = deliveryAddress;
		payment.anonymousUserContact = anonymousUserContact;
		req.payment = payment;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.createPayment(req, {
			headers: headers,
		});
	}

	// updatePayment(payment: Payment, updatePaths: string[], idToken?: string) {
	// 	const req = new UpdatePaymentRequest(); 
	// 	req.payment = payment;

	// 	// Set the update mask
	// 	const updateMask = new FieldMask();
	// 	updateMask.paths = updatePaths;

	// 	req.updateMask = updateMask;

	// 	const headers = new Headers();
	// 	headers.set("authorization", idToken ?? this.idToken ?? "");

	// 	return this.client.updatePayment(req, {
	// 		headers: headers,
	// 	});
	// }

	peachPaymentsCheckout(payment: string, idToken?: string) {
		const req = new InitiateRedirectBasedCheckoutRequest();

		req.payment = payment;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.peachPaymentsCheckout(req, {
			headers: headers,
		});
	}

	initializePaystackTransaction(payment: string, idToken?: string) {
		const req = new InitializeTransactionRequest();

		req.payment = payment;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.initializePaystackTransaction(req, {
			headers: headers,
		});
	}

	getPayment(name: string, idToken?: string) {
		// Create a new request
		const req = new GetPaymentRequest();

		// Set the payment name field
		req.name = name;

		const headers = new Headers();
		headers.set("authorization", idToken ?? this.idToken ?? "");

		return this.client.getPayment(req, {
			headers: headers,
		});
	}
}

let hostname = "https://console-v1alpha1-qhhmirl2vq-ew.a.run.app";
if (process.env.REACT_APP_ENV == "development") {
	hostname = "https://console-v1alpha1-qhhmirl2vq-ew.a.run.app";
} else if (process.env.REACT_APP_ENV == "production") {
	hostname = "https://console-v1alpha1-7h2ve2jt4q-ew.a.run.app";
}

export const apiService = new APIService(hostname);
