import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share-01.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/link-01.svg";
import {
  WhatsappShareButton, WhatsappIcon,
  FacebookShareButton, FacebookIcon,
  LinkedinShareButton, LinkedinIcon, 
  EmailShareButton , EmailIcon
} from "react-share";
import { useMessage } from "components/Messages/MessageProvider";  

interface ShareComponentProps {
  path: string;
  title: string; 
} 

function ShareComponent({ path, title }: ShareComponentProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);
    const {successMessage} = useMessage(); 

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
          .then(() => {
            console.log("URL copied to clipboard" , url);
            successMessage("URL copied to clipboard"); 
            // You can also set state here to show a confirmation message to the user
          })
          .catch(err => {
            console.error("Failed to copy URL: ", err);
            // Handle error case
          });
      }; 
    
    const fullUrl = `${process.env.REACT_APP_BASE_URL}/${path}`;
    const iconSize = 48;
    const buttonSpacing = "m-2"; 

    const modalContent = (
        <div ref={modalRef} 
             className={`fixed bottom-0 left-0 w-full h-1/2 z-[1000] rounded-t-3xl bg-greyscale-700 flex flex-col ${
                isModalOpen ? "modal-enter-active" : "modal-enter"
              }`}>
            <div className="flex items-center justify-center w-full">
                <p className="text-white font-ubuntu text-base font-medium text-center m-4 flex items-center space-x-2">
                    <span>Share</span>
                </p>
            </div>

            <div className="flex-grow overflow-y-scroll pl-4" id="target">
                            <div className="p-4 flex flex-wrap justify-center items-center gap-8">

                                <div className="flex flex-col items-center justify-center">
                                    <button 
                                    className={`rounded-full items-center justify-center p-2 w-12 h-12 bg-greyscale-450 ${buttonSpacing}`}
                                    onClick={() => copyToClipboard(fullUrl)}>
                                        <LinkIcon className="stroke-white"/>
                                    </button>
                                    <p>Copy Link</p>
                                </div>

                                <div className="flex flex-col items-center">
                                    <WhatsappShareButton url={fullUrl} title={title} className={buttonSpacing}>
                                        <WhatsappIcon size={iconSize} round />
                                    </WhatsappShareButton>
                                    <p>WhatsApp</p>
                                </div>

                                <div className="flex flex-col items-center">
                                    <FacebookShareButton url={fullUrl} quote={title} className={buttonSpacing}>
                                        <FacebookIcon size={iconSize} round />
                                    </FacebookShareButton>
                                    <p>Facebook</p>
                                </div>

                                <div className="flex flex-col items-center">
                                    <EmailShareButton url={fullUrl} title={title} className={buttonSpacing} subject={title}>
                                        <EmailIcon size={iconSize} round />
                                    </EmailShareButton>
                                    <p>Email</p>
                                </div>

                                <div className="flex flex-col items-center">
                                    <LinkedinShareButton url={fullUrl} className={buttonSpacing}>
                                        <LinkedinIcon size={iconSize} round />
                                    </LinkedinShareButton>
                                    <p>LinkedIn</p>
                                </div>
                </div>
            </div> 
        </div>
    );

    return (
        <>
            <button
                onClick={() => setIsModalOpen(true)}
                className="pt-4 px-0 mr-6 flex items-center justify-start text-body-sm font-medium font-inter bg-transparent border-none"
            >
                <ShareIcon className="w-6 h-6 stroke-2 text-white stroke-white" />
            </button>
            {isModalOpen && (
                <>
                    <div 
                        className={"fixed top-0 left-0 w-full h-full z-[99] bg-transparent"}
                        onClick={handleOutsideClick}
                    />
                    {ReactDOM.createPortal(
                        modalContent,
                        document.getElementById("portal-root")
                    )}
                </>
            )}
        </>
    );
}

export default ShareComponent; 