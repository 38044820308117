import ArtworkPage from "pages/Artwork/Artwork";
import CheckoutPage from "pages/Checkout/Checkout";
import HomePage from "pages/Home/Home";
import HomeLayout from "pages/Home/HomeLayout/HomeLayout";
import LandingPage from "pages/Landing/Landing";
import LoginPage from "pages/Login/Login";
import NetworkPage from "pages/Network/Network";
import PaymentResultPage from "pages/PaymentResultPage/PaymentResultPage";
import ProfilePage from "pages/Profile/Profile";
import SearchPage from "pages/Search/Search";
import TermsAndConditions from "pages/Terms and Conditions/Terms_and_conditions";
import SignUpPage from "pages/SignUp/SignUp";
import EmailVerifiedPage from "pages/EmailVerified/EmailVerified";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Prints from "pages/Prints/prints";

export default function AnonymousNavigation() {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/home/search" replace />} />
			<Route path="/landing" element={<LandingPage />}></Route>
			<Route path="/login" element={<LoginPage />}></Route>
			<Route path="/signup" element={<SignUpPage />}></Route>
			<Route path="/home" element={<HomeLayout showHeader={true} />}>
				<Route path="" element={<HomePage />} />
			</Route>
			<Route path="/emailverified" element={<EmailVerifiedPage />} />
			<Route path="/home" element={<HomeLayout showHeader={false} />}>
				<Route path="/home/search" element={<SearchPage />} />
			</Route>
			<Route path="/@" element={<HomeLayout showHeader={false} />}>
				<Route path=":username" element={<ProfilePage />} />
				<Route
					path=":username/network/:followTab"
					element={<NetworkPage />}
				/>
			</Route>
			<Route path="/prints" element={<Prints />}></Route>

			<Route path="/artworks/:artworkID" element={<ArtworkPage />} />
			<Route
				path="/terms_and_conditions"
				element={<TermsAndConditions />}
			/>
			<Route path="/checkout/:artworkID" element={<CheckoutPage />} />
			<Route
				path="/payments/:paymentId"
				element={<PaymentResultPage />}
			/>
			<Route path="*" element={<Navigate to="/home" replace />} />
		</Routes>
	);
}
