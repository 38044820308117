import { LoadingPage } from "pages/Loading/Loading";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { useAuth } from "../hooks/useAuth";
import AnonymousNavigation from "./anonNavigation";
import UserNavigation from "./userNavigation";
import { setUser } from "store/user/userSlice";

export default function RootNavigation() {
	const { user } = useAuth();
	const currentUser = useSelector((state: RootState) => state.user.user);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			// Wait for 1.5 seconds to allow the user to be loaded from the store
			console.log(currentUser);
			console.log(user);

			if (user) {
				console.log("Auth and user loaded");
				// The user is not anonymous - they have signed up and authenticated
				if (!user?.isAnonymous) {
					console.log("Not anon");
					console.log(currentUser);
					console.log(user);
					// If the user's email is not verified, send them to the verify email page
					if (user?.emailVerified == false) {
						console.log("Verifying email");
						navigate("/verify-email");
						setIsLoading(false); // Set isLoading to false after your logic
						return;
					}
					// If the user object is null, send them to the onboarding page
					if (currentUser == null) {
						console.log("User needs to be onboarded");
						navigate("/onboarding");
						setIsLoading(false); // Set isLoading to false after your logic
						return;
					}

					console.log("currentUser is = ", currentUser);
					console.log("username is = ", currentUser?.username);
					console.log("User is not anonymous");

					if (
						user?.isAnonymous == false &&
						currentUser != undefined
					) {
						//Seems to be a problem with signing in with Google, but works for email sign up.
						if (
							currentUser?.username == "" ||
							currentUser == null
						) {
							console.log("navigating to onboarding");
							navigate("/onboarding");
							setIsLoading(false); // Set isLoading to false after your logic
							return;
						}
					}
				}
			}
			setIsLoading(false); // Set isLoading to false after your logic
			console.log("isLoading is = ", isLoading);
		}, 1000); // waits 1 second before executing the logic

		// Cleanup function to clear the timeout if the component unmounts before the timeout finishes
		return () => clearTimeout(timer);
	}, [user, currentUser]);

	if (isLoading || (!user && !currentUser)) {
		return <LoadingPage />;
	}

	if (user?.isAnonymous) {
		return <AnonymousNavigation />;
	} else {
		return (
			<>
				<UserNavigation />
			</>
		);
	}
}
