import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Phone } from "assets/icons/phone-plus.svg";
import InputField from "components/Inputs/InputField";
import { apiService } from "services/api";
import { RootState } from "store";
import { setUser, setUserSuccess } from "store/user/userSlice";
import { Contact } from "@hockney-app/proto/types/v1alpha1/contact_details_pb";
import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { useMessage } from "components/Messages/MessageProvider";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";

function PhoneNumberInput() {
	const dispatch = useDispatch();
	const currentUser = useSelector((state: RootState) => state.user.user);
	// states for button
	const [numberUploading, setNumberUploading] = useState(false);
	const [formValues, setFormValues] = useState({
		mobileNumber: currentUser?.contactDetails?.mobileNumber || "",
	});
	const { errorMessage, successMessage } = useMessage();
	const [edited, setEdited] = useState(false);

	const isValidSAPhoneNumber = (phoneNumber) => {
		const regex = /^(\+27|27|0)[0-9]{2}( |-)?[0-9]{3}( |-)?[0-9]{4}$/;
		return regex.test(phoneNumber);
	};

	const handlePhoneNumberChange = (e) => {
		const { value } = e.target;
		setFormValues({ mobileNumber: value });

		// Mark as edited if the new number is different.
		if (value !== currentUser?.contactDetails?.mobileNumber) {
			setEdited(true);
		} else {
			setEdited(false);
		}
	};

	const resetToOriginalNumber = () => {
		setFormValues({
			mobileNumber: currentUser?.contactDetails?.mobileNumber || "",
		});
		setEdited(false);
	};

	const handleUpdatePhoneNumber = async (e) => {
		e.preventDefault();
		setNumberUploading(true);
		setEdited(false);
		const validNumber = isValidSAPhoneNumber(formValues.mobileNumber);
		if (
			formValues.mobileNumber ===
			currentUser?.contactDetails?.mobileNumber
		) {
			errorMessage(
				"The new mobile number is the same as the current one."
			);
		} else if (!validNumber) {
			errorMessage("Please enter a valid South African mobile number.");
			setNumberUploading(false);
		} else {
			try {
				setNumberUploading(true);
				const newContactDetails = new Contact();
				newContactDetails.name =
					currentUser.givenName + " " + currentUser.familyName;
				newContactDetails.email = currentUser.email;
				newContactDetails.mobileNumber = formValues.mobileNumber;

				let updatedUser = new User();
				updatedUser.name = currentUser?.name;
				updatedUser.contactDetails = newContactDetails;

				updatedUser = await apiService.updateUser(updatedUser, [
					"contact_details",
				]);

				// //tells store to update the cached user
				dispatch(setUserSuccess(updatedUser));
				successMessage("Successfully updated number ");
			} catch (error) {
				errorMessage(
					"There was an error updating your mobile number, please try again"
				);
				console.error(error);
				resetToOriginalNumber();
			} finally {
				setNumberUploading(false);
			}
		}
	};

	return (
		<>
			<form onSubmit={handleUpdatePhoneNumber} className="inputForm mx-4">
				<InputField
					Icon={Phone}
					inputProps={{
						id: "mobileNumber",
						name: "mobileNumber",
						title: "Please enter a valid South African mobile number.",
						type: "text",
						value: formValues.mobileNumber,
						onChange: handlePhoneNumberChange,
					}}
				/>
				{edited && (
					<button
						type="submit"
						color="primary"
						className="btn btn-secondary btn-sm w-full mt-4 mb-2"
						disabled={
							!isValidSAPhoneNumber(formValues.mobileNumber) ||
							numberUploading
						}
						onClick={handleUpdatePhoneNumber}
					>
						{numberUploading
							? LoadingSpinner("white")
							: "Update Phone Number"}
					</button>
				)}
			</form>
		</>
	);
}

export default PhoneNumberInput;
