import Dinero from "dinero.js";

export function getDineroPrice(
	priceInRands: number,
	currency: string,
	rate: number
) {
	if (!priceInRands) {
		const price = Dinero({
			amount: 0,
			currency: currency as Dinero.Currency,
		}).setLocale("en-SA");

		return price
			.toFormat("$0,0.00")
			.replace("ZAR", "R")
			.replace("USD", "$");
	}

	console.log(rate);
	const price = Dinero({
		amount: Math.ceil((priceInRands / rate) * 100),
		currency: currency as Dinero.Currency,
	}).setLocale("en-SA");

	return price.toFormat("$0,0.00").replace("ZAR", "R").replace("USD", "$");
}
