import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface ArtworkDetailsType {
	title: string;
	description: string;
	medium: number | null;
	height: number;
	width: number;
}

interface UploadContextType {
	user?: User | null;
	artworkDetails: ArtworkDetailsType;
	setArtworkDetails: React.Dispatch<React.SetStateAction<ArtworkDetailsType>>;
	price: number;
	setPrice: React.Dispatch<React.SetStateAction<number>>;
	artworkImage: File | null;
	setArtworkImage: React.Dispatch<React.SetStateAction<File | null>>;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);

interface UploadProviderProps {
	children: ReactNode;
}

export default function UploadProvider({ children }: UploadProviderProps) {
	const [artworkDetails, setArtworkDetails] = useState<ArtworkDetailsType>({
		title: "",
		description: "",
		medium: null,
		height: 0,
		width: 0,
	});
	const [price, setPrice] = useState<number>(null);
	const [artworkImage, setArtworkImage] = useState<File | null>(null);

	const currentUser = useSelector((state: RootState) => state.user.user);
	const [isVerified, setIsVerified] = React.useState(false);

	useEffect(() => {
		if (currentUser?.artistVerified) {
			setIsVerified(true);
		}
	});

	return (
		<UploadContext.Provider
			value={{
				artworkDetails,
				setArtworkDetails,
				price,
				setPrice,
				artworkImage,
				setArtworkImage,
				user: currentUser,
			}}
		>
			{isVerified ? (
				children
			) : (
				<div className="h-[600px] w-full flex flex-col justify-center items-center overflow-y-hidden p-8 text-center">
					<h1>You will be selling in no time!</h1>
					<p>
						Please give us a day to verify you as an artist. For any
						help or questions, feel free to contact us at
						support@artbeat.ink
					</p>
				</div>
			)}
		</UploadContext.Provider>
	);
}

export function useUpload() {
	const context = useContext(UploadContext);
	if (context === undefined) {
		throw new Error("useUpload must be used within an UploadProvider");
	}
	return context;
}
