import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch/lite";
import { FirebaseApp } from "firebase/app";
import { collection, CollectionReference, doc, DocumentData, DocumentReference, Firestore, getFirestore, query, Query, where } from "firebase/firestore";

import { app } from "../../config/firebase";

const USERS_COLLECTION = "users";
const ARTWORKS_COLLECTION = "artworks";

// DatabaseService holds any DB related functions
class DatabaseService {
	// Initialize Firestore and get a reference to the service
	private db: Firestore;
	private usersCollection: CollectionReference<DocumentData>;
	private artworksCollection: CollectionReference<DocumentData>;

	// Initialize Algolia and get a reference to the service
	private algoliaClient: SearchClient;
	private usersIndex: SearchIndex;

	constructor(
		app: FirebaseApp,
		algoliaConfig: { appId: string; apiKey: string }
	) {
		this.db = getFirestore(app);

		this.artworksCollection = collection(this.db, ARTWORKS_COLLECTION);
		this.usersCollection = collection(this.db, USERS_COLLECTION);

		this.algoliaClient = algoliasearch(
			algoliaConfig.appId,
			algoliaConfig.apiKey
		);
		if (process.env.REACT_APP_ENV === "production") {
			this.usersIndex = this.algoliaClient.initIndex("prod_USERS");
		} else {
			this.usersIndex = this.algoliaClient.initIndex("dev_USERS");
		}
	}

	public get artworks(): CollectionReference<DocumentData> {
		return this.artworksCollection ?? null;
	}

	public get users(): CollectionReference<DocumentData> {
		return this.usersCollection ?? null;
	}

	public userDoc(uid: string): DocumentReference<DocumentData> {
		return doc(this.db, `${this.usersCollection.path}/${uid}`);
	}

	public usernameQuery(username: string): Query<DocumentData> {
		return query(this.usersCollection, where("Username", "==", username));
	}

	public userFollowers(uid: string): CollectionReference<DocumentData> {
		return collection(this.db, uid);
	}

	public searchUsers(query: string, page?: number) {
		return this.usersIndex.search(query, {
			hitsPerPage: 15,
			page: page,
		});
	}
}

// Export a singleton instance
export const databaseService = new DatabaseService(app, {
	appId: "CZP4KF6XH3",
	apiKey: "551a6104fe9cccc1c8a916eb7c12eb15",
});
