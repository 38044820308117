import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import RichThreeWallOne from "assets/images/3-new.jpg";
import LouTwo from "assets/images/Bolamakiesie.jpg";
import BolamakasieRender from "assets/images/BolamakiesieRender.jpg";
import LouOne from "assets/images/Boskind.jpg";
import BoskindRender from "assets/images/BoskindRender.jpg";
import backgroundImageUrl from "assets/images/christmas pattern.png";
import LouFive from "assets/images/Hemels copy.jpg";
import HemelsRender from "assets/images/HemelsRender.jpg";
import LouSix from "assets/images/Kinderlik.jpg";
import KinderlikRender from "assets/images/KinderlikRender.jpg";
import LouDp from "assets/images/Lou-Headshot.jpg";
import RichOneWallOne from "assets/images/Rich-1-Wall-1.jpg";
import RichOneWallTwo from "assets/images/Rich-1-Wall-2.jpg";
import RichOne from "assets/images/Rich-1.jpg";
import RichTwoWallOne from "assets/images/Rich-2-Wall-1.jpg";
import RichTwoWallTwo from "assets/images/Rich-2-Wall-2.jpg";
import RichTwo from "assets/images/Rich-2.jpg";
import RichThree from "assets/images/Rich-3.jpg";
import RichDP from "assets/images/Rich-Headshot.jpeg";
import LouThree from "assets/images/Skaterlag.jpg";
import SkaterlagRender from "assets/images/SkaterlagRender.jpg";
import LouFour from "assets/images/Verbeelding.jpg";
import VerbeeldingRender from "assets/images/VerbeeldingRender.jpg";
import UserCarousel from "components/Carousels/UserCarousel";
import Header from "components/Headers/Header";
import { useRef } from "react";
import FeatureBar from "./FeatureBar";
import PrintPost from "./PrintPost";

import AlixDP from "assets/images/Alix_profile_pic.jpg";
import AlixOne from "assets/images/Miami Yachts x artbeat copy.jpg";
import AlixTwo from "assets/images/Refreshing x Artbeat (1) copy.jpg";

export default function Prints() {
	// React router hook to navigate between routes
	const navigate = useNavigate();
	const innerImageUrl = RichTwo;
	const LouRef = useRef(null);
	const AlixRef = useRef(null);
	const RichRef = useRef(null);
	const [innerImageLoaded, setInnerImageLoaded] = useState(false);

	useEffect(() => {
		document.title = "Christmas Prints | Artbeat";
	}, []);

	useEffect(() => {
		const img = new Image();
		img.src = innerImageUrl;
		img.onload = () => {
			setInnerImageLoaded(true);
		};
	}, [innerImageUrl]);

	const louPricing = {
		A5: { Framed: 840, Unframed: 430 },
		A4: { Framed: 1005, Unframed: 580 },
		A3: { Framed: 1220, Unframed: 725 },
		A2: { Framed: 1830, Unframed: 999 },
		A1: { Framed: 2820, Unframed: 1330 },
	};

	const RichPricing = {
		A5: { Framed: 950, Unframed: 550 },
		A4: { Framed: 1200, Unframed: 750 },
		A3: { Framed: 1600, Unframed: 950 },
		A2: { Framed: 2200, Unframed: 1400 },
		A1: { Framed: 3500, Unframed: 2000 },
	};

	const AlixPricing = {
		A2: { Framed: 2000, Unframed: 1150 },
	};

	const users = [
		{ name: "Art After Man", imageUrl: RichDP, ref: RichRef },
		{ name: "Afrillustrations", imageUrl: AlixDP, ref: AlixRef },
		{ name: "Lou Lou Van Der West", imageUrl: LouDp, ref: LouRef },
	];

	return (
		<>
			<Header title="Prints" onBack={() => navigate("/")} />
			<div className="flex flex-col px-4 min-h-screen relative">
				{/* <img
					src={backgroundImageUrl}
					alt="Background"
					className="w-full h-[400px] object-cover absolute top-0 left-0 z-0 rounded-[16px]"
				/> */}

				<div
					className="w-full h-[400px] object-cover absolute top-0 left-0 z-0"
					style={{
						background:
							"linear-gradient(180deg, #13BCA6 0%, rgba(19, 188, 166, 0) 100%)",
					}}
				></div>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "400px",
						borderRadius: "var(--border-radius-rounded-none, 0px)",
						background:
							"linear-gradient(180deg, rgba(0, 0, 0, 0.71) 1.56%, rgba(0, 0, 0, 0.00) 37.5%, #000 100%)",
						zIndex: 1,
					}}
				></div>
				<div className="w-full h-[400px] flex justify-center items-center relative z-5">
					{innerImageLoaded ? (
						<img
							src={innerImageUrl}
							alt="Featured Print"
							className="w-[280px] h-[280px] rounded-2xl object-cover"
						/>
					) : (
						<div className="w-[280px] h-[280px] rounded-2xl bg-greyscale-550 animate-pulse"></div>
					)}
				</div>

				<div className="flex flex-col justify-center items-center">
					<p className=" font-inter text-body-sm text-greyscale-400">
						{" "}
						Prints are available both framed and unframed, delivered
						straight to your door.{" "}
					</p>
				</div>
				<div className="mb-8">
					<FeatureBar></FeatureBar>
				</div>

				<UserCarousel users={users} />

				<div className="mt-4">
					<h2 className="flex flex-grow font-ubuntu text-h2-medium justify-start mb-3">
						{" "}
						All Prints
					</h2>
				</div>

				<div ref={AlixRef} id="Alix Rother">
					<PrintPost
						title="Miami Yachts"
						images={[AlixOne]}
						sizePricing={AlixPricing}
					/>

					<PrintPost
						title="Refreshing"
						images={[AlixTwo]}
						sizePricing={AlixPricing}
					/>
				</div>

				<div ref={RichRef} id="Richard Braithwaite">
					<PrintPost
						title="Nautilus 4"
						permalink="art-after-man-3"
						images={[RichThree, RichThreeWallOne]}
						sizePricing={RichPricing}
					/>

					<PrintPost
						title="Surfaccia 1"
						permalink="art-after-man-1"
						images={[RichOne, RichOneWallOne, RichOneWallTwo]}
						sizePricing={RichPricing}
					/>

					<PrintPost
						title="Torrent 7"
						permalink="art-after-man-2"
						images={[RichTwo, RichTwoWallOne, RichTwoWallTwo]}
						sizePricing={RichPricing}
					/>
				</div>

				<div ref={LouRef} id="Lou Van Der West">
					<PrintPost
						title="Skaterlag"
						images={[LouThree, SkaterlagRender]}
						sizePricing={louPricing}
					/>

					<PrintPost
						title="Bolamakiesie"
						images={[LouTwo, BolamakasieRender]}
						sizePricing={louPricing}
					/>

					<PrintPost
						title="Boskind"
						images={[LouOne, BoskindRender]}
						sizePricing={louPricing}
					/>

					<PrintPost
						title="Verbeelding"
						images={[LouFour, VerbeeldingRender]}
						sizePricing={louPricing}
					/>

					<PrintPost
						title="Hemels"
						images={[LouFive, HemelsRender]}
						sizePricing={louPricing}
					/>

					<PrintPost
						title="Kinderlik"
						images={[LouSix, KinderlikRender]}
						sizePricing={louPricing}
					/>
				</div>
			</div>
		</>
	);
}
