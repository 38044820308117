import { LoadingOutlined } from "@ant-design/icons";
import {
	Artwork,
	Artwork_State,
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { Payment } from "@hockney-app/proto/payments/v1alpha1/payments_pb";
import { Contact } from "@hockney-app/proto/types/v1alpha1/contact_details_pb";
import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { Spin } from "antd";
import { ReactComponent as Phone } from "assets/icons/phone-plus.svg";
import AnonAddressSelector from "components/Checkout/AnonymousAddressSelector";
import Header from "components/Headers/Header";
import PhoneNumberInput from "components/Inputs/PhoneNumberInput";
import { useLocationAndRates } from "hooks/useLocation";
import React, { useCallback, useEffect, useState } from "react";
import { use100vh } from "react-div-100vh";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDineroPrice } from "utils/price/price";
import { ReactComponent as MailIcon } from "../../assets/icons/mail-01.svg";
import { ReactComponent as SignatureVerified } from "../../assets/icons/signatureverified.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/user-02.svg";
import AddressSelector from "../../components/Checkout/AddressSelector";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";
import { useAuth } from "../../hooks/useAuth";
import { apiService } from "../../services/api";
import { RootState } from "../../store";

export default function CheckoutPage() {
	const currentUser = useSelector((state: RootState) => state.user.user);
	const navigate = useNavigate();
	const { location, currency, rate } = useLocationAndRates();
	const [selectedAddress, setSelectedAddress] = useState(null);
	const vh = use100vh();
	const handleAddressChange = (address) => {
		setSelectedAddress(address);
	};
	const { user } = useAuth();

	useEffect(() => {
		console.log("Selected Address:", selectedAddress);
	}, [selectedAddress]);

	useEffect(() => {
		document.title = "Checkout | Artbeat ";
	}, []);

	const { artworkID } = useParams();

	// State variable for paymentID
	const [paymentId, setPaymentId] = useState("");
	const [artwork, setArtwork] = useState<Artwork>();
	const [artworkLoading, setArtworkLoading] = useState(true);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [sold, setSold] = useState(false);
	const [imageUrls, setImageUrls] = useState([]);
	const [artist, setArtist] = useState<User | null>();

	const getArtwork = useCallback(async () => {
		try {
			setArtworkLoading(true);
			const path = "artworks/" + artworkID;
			const artworkPromise = await apiService.getArtwork(path, {});
			const artwork = await artworkPromise;
			const artistPromise = await apiService.getUser(artwork.artist, {});
			const artist = await artistPromise;

			setArtwork(artwork);
			setArtist(artist);
			setSold(
				artwork.state !== Artwork_State.FOR_SALE ||
					currentUser?.name === artwork.artist
			);
			setImageUrls(artwork.imageUrls);
		} catch (error) {
			console.error(error);
		} finally {
			setArtworkLoading(false);
		}
	}, [artworkID, currentUser?.name, user?.isAnonymous]);

	const totalArtworkPrice = () => {
		let price = 0.0;
		price += artwork?.currentPrice || 0; // Add a fallback value in case artwork?.currentPrice is undefined
		return getDineroPrice(price, currency, rate);
	};

	let shippingFee = 0;
	location == "ZAF" ? (shippingFee = 150.0) : (shippingFee = 5000.0);

	const shippingFees = () => {
		return getDineroPrice(shippingFee, currency, rate);
	};

	function calculateTotalWithFee(setPrice) {
		const feeRate = 0.029; // Payment gateway's fee rate (2.9%)
		const transactionFee = (setPrice * feeRate) / (1 - feeRate);
		const totalWithFee = setPrice + transactionFee;
		return { totalWithFee, transactionFee };
	}

	const totalAmount = () => {
		// Sum of artwork price and shipping fees
		const subtotal = artwork?.currentPrice + shippingFee;

		console.log(subtotal);
		// Calculate total amount with payment gateway's fee
		const { totalWithFee, transactionFee } =
			calculateTotalWithFee(subtotal);

		return {
			total: totalWithFee,
			fee: transactionFee,
		};
	};

	const { total, fee } = totalAmount();

	console.log("Total Amount to Pay: $", total);
	console.log("Transaction Fee: $", fee);

	useEffect(() => {
		setArtworkLoading(true);
		if (artworkID) {
			getArtwork();
		}
	}, [artworkID, getArtwork]);

	const handlePurchase = async () => {
		try {
			setPaymentLoading(true);

			let payment;
			if (user.isAnonymous) {
				// Update the anonymous_user_contact field with the form values
				const anonymousUserContact = new Contact();

				anonymousUserContact.name =
					anonFormValues.firstName + " " + anonFormValues.lastName;
				anonymousUserContact.mobileNumber = anonFormValues.mobileNumber;
				anonymousUserContact.email = anonFormValues.email;

				payment = await apiService.createPayment(
					["artworks/" + artworkID],
					selectedAddress,
					anonymousUserContact
				);
			} else {
				payment = await apiService.createPayment(
					["artworks/" + artworkID],
					selectedAddress
				);
			}

			console.log(payment);
			setPaymentId(payment.name);
			console.log(paymentId);

			const res = await apiService.initializePaystackTransaction(
				payment.name
			);
			console.log(res);
			// Redirect the user to the response URL returned
			window.open(res.redirectUrl, "_self");
		} catch (error) {
			console.error(error);
		} finally {
			setPaymentLoading(false);
		}
	};

	const [anonFormValues, setAnonFormValues] = useState({
		firstName: "",
		lastName: "",
		mobileNumber: "",
		email: "",
	});

	const handleAnonInputChange = (e) => {
		const { name, value } = e.target;
		setAnonFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const isGuestCheckoutFormValid = () => {
		const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
		const mobileNumberRegex = /^\+?[0-9]{10,15}$/; // Adjust regex as per your requirements
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // A more lenient regex for email

		const isFirstNameValid = nameRegex.test(
			anonFormValues.firstName.trim()
		);
		const isLastNameValid = nameRegex.test(anonFormValues.lastName.trim());
		const isMobileNumberValid = mobileNumberRegex.test(
			anonFormValues.mobileNumber.trim()
		);
		const isEmailValid = emailRegex.test(anonFormValues.email.trim());

		return (
			isFirstNameValid &&
			isLastNameValid &&
			isMobileNumberValid &&
			isEmailValid &&
			selectedAddress !== null
		);
	};

	useEffect(() => {
		console.log("Selected Address:", selectedAddress);
		console.log("Artwork Sold:", sold);
		console.log("User is Anonymous:", user?.isAnonymous);
		console.log("Guest Checkout Form Valid:", isGuestCheckoutFormValid());
		// Log the form values for anonymous user
		if (user?.isAnonymous) {
			console.log("Anonymous Form Values:", anonFormValues);
		}
	}, [selectedAddress, sold, user?.isAnonymous, anonFormValues]);

	return (
		<>
			<header>
				<Header title={"Collect"} onBack={() => navigate(-1)} />
			</header>

			{artworkLoading || !artwork || !artist ? (
				<div className="flex items-center justify-center h-screen w-full fixed top-0 left-0 z-50">
					<Spin size="large" indicator={<LoadingOutlined spin />} />
				</div>
			) : (
				<main>
					<div
						style={{
							position: "relative",
							height: `calc(${vh}px - var(--header-height))`,
							overflowY: "scroll",
							paddingTop: "3rem",
							paddingBottom: "5rem",
						}}
					>
						<div className="flex items-center p-4 mb-2">
							<img
								src={imageUrls[0]}
								alt={artwork?.title}
								className="mr-5 w-28 h-auto rounded-lg"
							/>
							<div className="text-left text-lg">
								<p className="mb-1">{artwork?.title}</p>
								<p className="text-sm text-greyscale-400 mb-1">
									@{artist?.username}{" "}
									<SignatureVerified className="w-4 h-4 inline-block align-middle" />
								</p>
								<p className="m-0">{totalArtworkPrice()}</p>
							</div>
						</div>

						<div className="bg-greyscale-650 mx-4 rounded-xl p-4 flex flex-col gap-2 mb-2">
							<div className="flex justify-between items-center">
								<p className="font-inter font-regular text-body-sm text-greyscale-400 m-0">
									Price to collect
								</p>
								<div className="font-inter font-regular text-body-sm text-greyscale-400 m-0">
									{totalArtworkPrice()}
								</div>
							</div>
							<div className="flex justify-between items-center">
								<p className="font-inter font-regular text-body-sm text-greyscale-400 m-0">
									Delivery fee
								</p>
								<div className="font-inter font-regular text-body-sm text-greyscale-400 m-0">
									{shippingFees()}
								</div>
							</div>
							<div className="flex justify-between items-center">
								<p className="font-inter font-regular text-body-sm text-greyscale-400 m-0">
									Payment Gateway Fee
								</p>
								<div className="font-inter font-regular text-body-sm text-greyscale-400 m-0">
									{getDineroPrice(fee, currency, rate)}
								</div>
							</div>
							<hr className="mt-2 mb-0 border-greyscale-450 h-px  w-full" />{" "}
							{/* The line/divider */}
							<div className="flex justify-between items-center">
								<p className="font-inter font-regular text-body-md text-greyscale-100 m-0">
									Total
								</p>
								<div className="font-inter font-regular text-body-md text-greyscale-100 m-0">
									{getDineroPrice(total, currency, rate)}
								</div>
							</div>
						</div>

						{user.isAnonymous ? (
							<div className="p-4">
								<p className="text-base font-inter pl-2">
									{" "}
									Guest Checkout
								</p>
								<div className="ml-2">
									<p className="mt-4 font-inter font-regular text-body-md text-greyscale-450  ">
										{" "}
										Contact details for delivery:{" "}
									</p>
								</div>
								<form className="mt-4 ">
									<div className="flex flex-row space-x-2">
										<div className="flex flex-col flex-1 relative">
											<PersonIcon className="absolute left-3 top-3.5" />
											<input
												placeholder="First Name"
												autoComplete="given-name"
												type="text"
												name="firstName"
												required
												className="inputTextArea"
												value={anonFormValues.firstName}
												onChange={handleAnonInputChange}
											/>
										</div>

										<div className="flex flex-col flex-1 relative">
											<PersonIcon className="absolute left-3 top-3.5" />
											<input
												placeholder="Last Name"
												autoComplete="family-name"
												type="text"
												name="lastName"
												required
												className="inputTextArea"
												value={anonFormValues.lastName}
												onChange={handleAnonInputChange}
											/>
										</div>
									</div>
									<div className="inputDiv">
										<Phone className="fieldIcon" />
										<input
											type="mobileNumber"
											name="mobileNumber"
											pattern="^\+?[0-9]{1,3}?[-. ]?(\([0-9]{1,}\)|[0-9]{1,})[-. ]?[0-9]{1,}[-. ]?[0-9]{1,}[-. ]?[0-9]{1,}$"
											required
											placeholder="Phone Number"
											className="inputTextArea"
											value={anonFormValues.mobileNumber}
											onChange={handleAnonInputChange}
										/>
									</div>

									<div className="inputDiv">
										<MailIcon className="fieldIcon" />
										<input
											type="email"
											name="email"
											pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
											required
											placeholder="Email Address"
											className="inputTextArea"
											value={anonFormValues.email}
											onChange={handleAnonInputChange}
										/>
									</div>
								</form>
								<div className="mt-4">
									<AnonAddressSelector
										onAddressChange={handleAddressChange}
									/>
								</div>
							</div>
						) : (
							<>
								<div className="px-4 mb-2">
									<p className="mb-3 mt-4 font-inter font-regular text-body-md text-greyscale-450  ">
										{" "}
										Contact details for delivery:{" "}
									</p>
								</div>
								<div>
									<PhoneNumberInput />
								</div>

								<div className="px-4 mb-2">
									<p className="mb-3 mt-4 font-inter font-regular text-body-md text-greyscale-450  ">
										{" "}
										Deliver to:{" "}
									</p>
									<AddressSelector
										onAddressChange={handleAddressChange}
									/>
								</div>
							</>
						)}

						<div className="fixed bottom-8 w-full px-4 flex justify-center z-20">
							<button
								className="btn btn-primary btn-md w-full"
								id="pay-button"
								disabled={
									sold ||
									!selectedAddress ||
									(user.isAnonymous &&
										!isGuestCheckoutFormValid())
								}
								onClick={() => handlePurchase()}
							>
								{paymentLoading
									? LoadingSpinner("white")
									: "Proceed to Payment"}
							</button>
						</div>
					</div>
				</main>
			)}
		</>
	);
}
