import React from "react";

function UserListItemSkeleton() {
    return (
        <div className="flex w-full justify-between items-center px-4 pb-2 pt-2 animate-pulse">
            {/* Avatar Skeleton */}
            <div className="w-10 h-10 mr-4 bg-greyscale-550 rounded-full"></div>

            {/* Name and Username Skeleton */}
            <div className="flex-grow">
                <div className="h-4 bg-greyscale-550 rounded-full w-1/2 mb-2"></div>
                <div className="h-3 bg-greyscale-550 rounded-full w-1/3"></div>
            </div>

            {/* Follow Button Skeleton */}
            <div className="px-3.5 py-5.5 h-6 w-20 bg-greyscale-550 rounded-full"></div>
        </div>
    );
}

export default UserListItemSkeleton;