import React from "react";
import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { RootState } from "store";
import { useSelector } from "react-redux"; 
import {ReactComponent as DownIcon} from "assets/icons/chevron-down.svg"; 
import Avatar from "components/Avatar/Avatar";


interface CollapsedViewProps {
    onExpand: () => void;
    commentCount: number;
}


const CollapsedView: React.FC<CollapsedViewProps> = ({ onExpand, commentCount }) => {
    const [comment, setComment] = useState("");
    const me = useSelector((state: RootState) => state.user.user); 

    return (
        <div className="cursor-pointer" onClick={onExpand}>
            <div className="w-full rounded-xl bg-greyscale-700 relative px-4 py-4">
                <div className="flex justify-between w-full ">
                    <p className="text-white font-ubuntu text-base font-medium flex space-x-2 mb-2">
                        <span>Comments</span>
                        <span className="relative before:content-['•'] text-greyscale-450"></span>
                        <span className="text-greyscale-450 text-base font-inter">{commentCount}</span>
                    </p>
                    <DownIcon className="align-middle text-white"/>
                </div>

                <div className="relative flex items-center pt-1">
						{/* Avatar - Positioned to the left inside the TextArea */}
						<div className="absolute z-10 w-8 h-8 left-4 ">
						<Avatar 
							user={me}
							className=" w-8 h-8"
						/>
						</div>

						<TextArea
							className=" rounded-lg flex-grow font-inter text-greyscale-450 bg-greyscale-700 border-greyscale-600 text-sm font-normal py-3 pl-14 pr-20"
							id="commentInput"
							value={comment}
							showCount={false}
							placeholder="Share your thoughts"
							autoSize={{ minRows: 1, maxRows: 1 }}
							onChange={(e) => {
								setComment(e.target.value);
							}}
						/>
					</div>
				</div>
        </div>
    );
}; 

export default CollapsedView;