import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as Xclose } from "assets/icons/x-close.svg";
import { useNavigate } from "react-router-dom";

interface SplashScreenProps {
  onClose: () => void;
}

const SplashScreen = ({ onClose }: SplashScreenProps) => {
  const navigate = useNavigate(); 

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex justify-center items-center flex-col z-[1000] p-4 animate-fadeIn">
      <button className="absolute top-4 left-4 bg-transparent border-none" onClick={onClose}>
        <Xclose className="text-white w-6 h-6" />
      </button>
      <p className="font-ubuntu font-normal text-4xl text-white mb-8">Artbeat</p>

      <button 
        className="btn btn-sm bg-white border-none text-black text-base font-semibold font-inter w-full"
        onClick={() => navigate("/signup")}> 
             Create Your Account
      </button>  

      <p className = "my-2 font-inter text-lg font-semibold"> or </p>

      <button className = "btn btn-sm btn-primary w-full text-base font-semibold font-inter"
        onClick={() => navigate("/login")}> 
        Login 
      </button>
    </div>,
    document.getElementById("portal-root") // this is the portal target
  );
};

export default SplashScreen;