import { Button } from "antd";
import { useMessage } from "components/Messages/MessageProvider";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MailCircleIcon } from "../../assets/icons/mail-circle.svg";
import { useAuth } from "../../hooks/useAuth";
import { authenticationService } from "../../services/authentication";

export default function VerifyEmailPage() {
	//const queryParameters = new URLSearchParams(window.location.search);
	// TODO: do something with email
	//const email = queryParameters.get("email");

	// React router hook to navigate between routes
	const navigate = useNavigate();

	// Custom auth hook to easily get logged in user
	const { user } = useAuth();

	//variables holding message state
	const { errorMessage, successMessage } = useMessage();

	const sendVerificationEmail = useCallback(async () => {
		try {
			await authenticationService.sendVerificationEmail();
			successMessage("Verification email sent");
			console.log("Verification email sent");
		} catch (error) {
			errorMessage(
				"Too many requests. Please wait a bit a then try again"
			);
		}
	}, []);

	useEffect(() => {
		console.log(user);
		if (user?.emailVerified) {
			navigate("/onboarding");
			return;
		}
		if (user) {
			sendVerificationEmail();
		}
	}, [user, sendVerificationEmail, navigate]);

	const onFinish = async () => {
		try {
			await authenticationService.reloadUser();

			if (!user?.emailVerified) {
				errorMessage(
					"Your email is not verified. Please check your inbox and verify your email"
				);
				return;
			}

			navigate("/onboarding");
		} catch (error) {
			errorMessage(
				"Too many requests. Please wait a bit a then try again"
			);
		}
	};

	const onReturn = async () => {
		try {
			await authenticationService.signOut();

			navigate("/");
		} catch (error) {
			errorMessage(
				"There was a problem. Please contact support@artbeat.ink"
			);
		}
	};

	useEffect(() => {
		document.title = "Verification | Artbeat ";
	}, []);

	return (
		<div className="w-full h-full flex flex-col justify-center items-center p-4 overflow-hidden">
			<div className=" w-full flex flex-col items-center justify-center bg-greyscale-700 py-6 px-4 rounded-xl shadow-lg  ">
				<div>
					<MailCircleIcon></MailCircleIcon>
					<p className="text-body-lg font-semibold font-inter mt-4">
						{" "}
						Check your Inbox{" "}
					</p>
					<p className="font-regular font-inter text-greyscale-500 text-body-sm">
						{" "}
						We have sent a verifcation email to you! Tap on that
						link to verify your account{" "}
					</p>
				</div>
				<div className="mt-6 w-full flex flex-col justify-center">
					<button
						className="btn-primary btn-md btn font-inter "
						onClick={() => {
							sendVerificationEmail();
						}}
					>
						{" "}
						Resend Link{" "}
					</button>
				</div>
			</div>
			<div className="relative w-full flex justify-center items-center mt-4">
				<span className="text-greyscale-500 font-inter font-regular">
					Already Verified?
				</span>
				<Button
					className="text-teal-500 font-inter font-semibold !active:text-teal-700 !visited:text-teal-500"
					type="link"
					onClick={onFinish}
				>
					Click Here to Continue
				</Button>
			</div>
			<div className="relative w-full flex justify-center items-center mt-4">
				<span className="text-greyscale-500 font-inter font-regular">
					Continue later?
				</span>
				<Button
					className="text-teal-500 font-inter font-semibold !active:text-teal-700 !visited:text-teal-500"
					type="link"
					onClick={onReturn}
				>
					Click Here to return home
				</Button>
			</div>
		</div>
	);
}
