import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AtSign } from "../../../../assets/icons/at-sign.svg";
import Button from "../../../../components/Buttons/Button";
import Header from "../../../../components/Headers/Header";
import Message from "../../../../components/Messages/Message";
import { apiService } from "../../../../services/api";
import { RootState } from "../../../../store";
import { setUser, setUserSuccess } from "../../../../store/user/userSlice";

interface UpdateUsernameFormValues {
	username: string;
}

export default function UpdateUsername() {
	const [formValues, setFormValues] = useState<UpdateUsernameFormValues>({
		username: "",
	});
	const [usernameValid, setUsernameValid] = useState<boolean | null>(null);

	// states for button
	const [usernameUploading, setUsernameUploading] = useState(false);

	// Error message hooks
	const [errorMessage, setErrorMessage] = useState("");
	const [errorCount, setErrorCount] = useState(0);

	// States and dispatch from store
	const dispatch = useDispatch();
	const currentUser = useSelector((state: RootState) => state.user.user);

	const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const isFormValid = () => {
		return (
			formValues.username && formValues.username !== currentUser?.username
		);
	};

	// handles submit of confirm new username. Makes update user call
	const handleUpdateUsername = async (e) => {
		e.preventDefault();
		try {
			setUsernameUploading(true);

			const updateUser = new User();
			updateUser.name = currentUser?.name;
			updateUser.username = formValues.username;

			const updatedUser = await apiService.updateUser(updateUser, [
				"username",
			]);
			dispatch(setUserSuccess(updatedUser));
		} catch (error) {
			console.error(error);
		} finally {
			setUsernameUploading(false);
		}
	};

	const onChangeUsername = async (e: ChangeEvent<HTMLInputElement>) => {
		setUsernameValid(null);
		try {
			const inputUsername = e.target.value;

			// Check if the inputted username is the same as the current username
			if (currentUser?.username === inputUsername) {
				setErrorMessage(
					"The inputted username is the same as the current username"
				);
				setErrorCount((count) => count + 1);
				setUsernameValid(false);
				return;
			}

			const res = await apiService.listUsers({
				pageSize: 1,
				filter: `username = '${e.target.value}'`,
			});

			if (
				res.users.length === 0 ||
				res.users[0].username === currentUser?.username
			) {
				setUsernameValid(true);
				return;
			}

			setUsernameValid(false);
		} catch (error) {
			setErrorMessage("There was an error checking username");
			setErrorCount((count) => count + 1);
			console.error(error);
		}
	};

	return (
		<>
			{errorMessage && (
				<Message
					message={errorMessage}
					duration={4000}
					type={"error"}
					key={errorCount}
					zIndex={errorCount}
				/>
			)}
			<Header title={"Change Username"} page={"/settings/your_account"} />
			<div className="flex-col items-center gap-4 my-4 pt-12">
				<div className="flex-col px-4 items-start gap-4 self-stretch">
					<h5 className="font-ubuntu not-italic font-medium my-2 text-sm">
						Current
					</h5>
					<h4 className="font-inter not-italic font-light text-greyscale-150 my-2 text-base">
						@{currentUser?.username}
					</h4>
				</div>

				<div className="flex-col items-start gap-4 self-stretch ">
					<h5 className="px-4 font-ubuntu not-italic font-medium my-2 text-sm ">
						New
					</h5>

					<form
						onSubmit={handleUpdateUsername}
						className="inputForm mx-4"
					>
						<div className="inputDiv">
							<AtSign className="w-5 h-5 fieldIcon" />
							<input
								pattern="[a-z_.]{3,}"
								title="Username must be at least 3 characters and contain only lowercase letters, underscores, or dots."
								id="username"
								name="username"
								type="text"
								required
								className="inputTextArea"
								placeholder="New Username"
								onChange={(e) => {
									onChangeUsername(e);
									handleUsernameChange(e);
								}}
							/>
							<div className="flex relative w-full mt-1 h-4 ">
								{usernameValid === false &&
									formValues.username && (
										<span className="text-red-500">
											Username is already taken
										</span>
									)}
								{usernameValid === true &&
									formValues.username && (
										<span className="text-green-500">
											Username is available
										</span>
									)}
							</div>
						</div>

						<div className="flex items-center my-4">
							<Button
								type="submit"
								text="Confirm New Username"
								size="small"
								disabled={!isFormValid()}
								loading={usernameUploading}
								color="primary"
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
