import {
	Artwork,
	Artwork_Dimensions,
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import Button from "components/Buttons/Button";
import PillButton from "components/Buttons/PillButton";
import Header from "components/Headers/Header";
import Message from "components/Messages/Message";
import { artworkMediums } from "enums/artworks";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiService } from "services/api";
import { storageService } from "services/storage";
import { RootState } from "store";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as HeightIcon } from "../../../assets/icons/height.svg";
import { ReactComponent as MarkerIcon } from "../../../assets/icons/marker-pin-04.svg";
import { ReactComponent as PalleteIcon } from "../../../assets/icons/palette.svg";
import { ReactComponent as WidthIcon } from "../../../assets/icons/width.svg";
import { useAuth } from "../../../hooks/useAuth";
import { useUpload } from "../UploadContext/UploadContext";
import { useDispatch } from "react-redux";
import { prependArtworks } from "store/feed/feedSlice";

const ArtworkSummary = () => {
	// artwork details and price passed through context.
	const { artworkDetails, price, artworkImage } = useUpload();

	// state to show artwork is uploading
	const [artworkUploading, setArtworkUploading] = useState(false);

	// Custom auth hook to easily get logged in user
	const { user } = useAuth();

	//access store 
	const dispatch = useDispatch(); 

	// states for error messages
	//for error and success messages
	const [errorMessage, setErrorMessage] = useState("");
	const [errorCount, setErrorCount] = useState(0);

	const [successMessage, setSuccessMessage] = useState("");
	const [successCount, setSuccessCount] = useState(0);

	// currentUser contains the addresses and bank details for a user
	const currentUser = useSelector((state: RootState) => state.user.user);

	// state for rendering the description
	const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
	const descriptionRef = useRef(null);
	const [shouldShowMoreLess, setShouldShowMoreLess] = useState(false);

	// state for address
	const hasAddress = currentUser?.addresses?.length > 0;

	// displaying the medium name instead of the enum value
	const mediumEnumValue = artworkDetails.medium; // or wherever you get the enum value from
	const mediumDisplayName = artworkMediums.find(
		(medium) => medium.enum === mediumEnumValue
	)?.displayName;

	// const for navigation
	const navigate = useNavigate();

	// Gets image dimensions
	const getImageDimensions = (
		file: File
	): Promise<{ width: number; height: number }> => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				resolve({ width: img.width, height: img.height });
			};
			img.onerror = reject;
			img.src = URL.createObjectURL(file);
		});
	};

	// creates artwork in backend and uploads to home feed
	const handleCreateArtwork = async (e) => {
		e.preventDefault();
		console.log("Creating...");
		if (!user) {
			console.log("Issue 1");
			setErrorMessage("Please sign in");
			setErrorCount((count) => count + 1);
			return;
		}

		if (!artworkImage) {
			console.log("Issue 2");
			setErrorMessage("No image selected");
			setErrorCount((count) => count + 1);
			return;
		}

		try {
			console.log("Uploading");
			setArtworkUploading(true);

			// Random unique identifier for the artwork
			const artworkId = uuidv4();

			// Get image dimensions
			const dimensions = await getImageDimensions(artworkImage);
			const aspectRatio = dimensions.width / dimensions.height;
			console.log("aspectRatio:", aspectRatio);
			const formattedAspectRatio = aspectRatio.toFixed(4);
			console.log("aspectRatio:", formattedAspectRatio);
			// Convert file to uint8 array
			const buffer = await artworkImage.arrayBuffer();
			const uint8Array = new Uint8Array(buffer);
			// Upload file to firebase storage
			const uploadResult = await storageService.uploadFile(
				uint8Array,
				`artworks/${artworkId}/1`,
				{
					customMetadata: {
						aspectRatio: `${aspectRatio}`,
					},
				}
			);

			console.log(uploadResult.ref.fullPath);
			// Get the url of the uploaded file
			const artworkImageUrl = await storageService.getURL(
				uploadResult.ref.fullPath
			);
			console.log(artworkImageUrl);

			// Create the artwork
			const artwork = new Artwork();
			artwork.artist = currentUser.name; // Make sure to fetch this from your Redux state
			artwork.title = artworkDetails.title;
			artwork.description = artworkDetails.description;
			artwork.imageUrls = [artworkImageUrl]; // Populate this with appropriate image URLs
			artwork.currentPrice = price;
			if (artworkDetails.medium) {
				artwork.medium = artworkDetails.medium;
			}

			artwork.dimensions = new Artwork_Dimensions();
			artwork.dimensions.height = artworkDetails.height;
			artwork.dimensions.width = artworkDetails.width;

			console.log(artwork);
			const artworkObject = await apiService.createArtwork(artwork, artworkId);

			//add the new artwork to the store
			dispatch(prependArtworks([artworkObject])); 
			
			//set feeds scroll position to 0 
			localStorage.setItem("feedScrollPosition", "0");

			setSuccessMessage("Artwork uploaded successfully");
			setSuccessCount((count) => count + 1);
			// Sleep for 2 second to show success message
			await new Promise((resolve) => setTimeout(resolve, 1000));
		} catch (error) {
			setErrorMessage("Error uploading image");
			setErrorCount((count) => count + 1);
			console.error(error);
		} finally {
			setArtworkUploading(false);
			navigate("/home/search");
		}
	};

	// useEffect to render description according to line number
	useEffect(() => {
		if (descriptionRef.current) {
			const lineHeight = parseFloat(
				window.getComputedStyle(descriptionRef.current).lineHeight
			);
			const scrollHeight = descriptionRef.current.scrollHeight;
			const lineCount = Math.floor(scrollHeight / lineHeight);
			setShouldShowMoreLess(lineCount > 11);
		}
	}, [artworkDetails.description]);

	const toggleDescription = () => {
		setDescriptionExpanded((prev) => !prev);
	};

	return (
		<div className="relative">
			{errorMessage && (
				<Message
					message={errorMessage}
					duration={4000}
					type={"error"}
					key={errorCount}
					zIndex={errorCount}
				/>
			)}
			{successMessage && (
				<Message
					message={successMessage}
					duration={4000}
					type={"success"}
					key={successCount}
					zIndex={successCount}
				/>
			)}
			<Header
				title={artworkDetails.title}
				onBack={() => navigate("/home/artworkDetails/setPrice")}
			/>

			<div className="relative flex flex-col items-center border-none pt-14 px-4">
				{artworkImage ? (
					// Display the uploaded image at full width
					<img
						src={URL.createObjectURL(artworkImage)}
						alt="Uploaded Artwork"
						className="w-full h-auto rounded-xl"
					/>
				) : (
					<div></div>
				)}
			</div>

			<div className="p-4">
				<div className="horizontal-scroll gap-3">
					<PillButton label={`R${price}`} />
					<PillButton
						label={mediumDisplayName}
						icon={<PalleteIcon className="mt-1 w-4 h-4" />}
					/>
					<PillButton
						label={`${artworkDetails?.height} cm`}
						icon={<HeightIcon className="mt-1 w-4 h-4" />}
					/>
					<PillButton
						label={`${artworkDetails?.width} cm`}
						icon={<WidthIcon className="mt-1 w-4 h-4" />}
					/>
				</div>
			</div>

			{hasAddress && (
				<div className="flex items-center mx-4 mb-4 text-greyscale-450 font-inter text-body-xs">
					<MarkerIcon className="mr-1 align-middle w-4 h-4" />
					{currentUser?.addresses[0]?.city} ,{" "}
					{currentUser?.addresses[0]?.country}
				</div>
			)}

			<div className="mx-4 relative">
				<p
					className="font-inter whitespace-pre-line relative z-10"
					ref={descriptionRef}
					style={{
						maxHeight: isDescriptionExpanded ? "none" : "11rem",
						overflow: "hidden",
					}}
				>
					{artworkDetails?.description}
				</p>
				{shouldShowMoreLess && (
					<div className="absolute bottom-0 right-0 bg-black pl-2 z-20">
						<span
							className="text-greyscale-450 cursor-pointer"
							onClick={toggleDescription}
						>
							{isDescriptionExpanded ? "Show less" : "Show more"}
						</span>
					</div>
				)}
			</div>

			{
				//Added div at the bottom to create space between nav bar and 'Next' button
			}
			<form onSubmit={handleCreateArtwork}>
				<div className="p-4 w-full flex justify-center">
					<Button
						type="submit"
						text="Upload Artwork"
						size="small"
						color="primary"
						loading={artworkUploading}
					/>
				</div>
				<div className="bg-transparent h-[60px]"></div>
			</form>
		</div>
	);
};

export default ArtworkSummary;
