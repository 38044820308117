import { User } from "@hockney-app/proto/users/v1alpha1/users_pb.js";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Slice name
const USER_SLICE = "user";

// Slice state interface
export interface UserState {
	user?: User | null;
	isLoadingUser: boolean;
}

// Slice initial state
const initialState: UserState = {
	isLoadingUser: false,
};

// Create Slice
export const userSlice = createSlice({
	name: USER_SLICE,
	initialState,
	reducers: {
		setUser: (state) => {
			state.isLoadingUser = true;
		},
		setUserSuccess: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
			state.isLoadingUser = false;
		},
	},
});

// Export actions
export const { setUser, setUserSuccess } = userSlice.actions;

// Export reducer
export default userSlice.reducer;
