import { Button } from "antd";
import { useMessage } from "components/Messages/MessageProvider";
import { logEvent } from "firebase/analytics";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArtBeatWordMark } from "../../assets/icons/Artbeat Wordmark.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye-off.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-icon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google-icon.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock-01.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail-01.svg";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";
import { analytics } from "../../config/firebase";
import { authenticationService } from "../../services/authentication";
import { handleFirebaseError } from "../../utils/firebaseErrors";

export default function SignUpPage() {
	// React router hook to navigate between routes
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingGoogle, setLoadingGoogle] = useState(false);
	const [loadingFacebook, setLoadingFacebook] = useState(false);
	const { errorMessage, successMessage } = useMessage();

	const [form, setForm] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const isFormValid = () => {
		return form.email && form.password && form.confirmPassword && isChecked;
	};

	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleSignUpWithEmailAndPassword = async (e) => {
		e.preventDefault();

		// eslint-disable-next-line prefer-const
		let { email, password, confirmPassword } = form;
		email = email.toLowerCase();
		// Preform validations
		if (password !== confirmPassword) {
			errorMessage("Passwords do not match");
			return;
		}
		// Verify the email matches the stadard email regex
		const emailRegex = /\S+@\S+\.\S+/;
		if (!emailRegex.test(email)) {
			errorMessage("Please enter a valid email address");

			return;
		}

		try {
			setLoading(true);

			const credential =
				await authenticationService.signUpWithEmailAndPassword({
					email,
					password,
				});

			logEvent(analytics, "sign_up", { method: "email" });

			console.log("Logged in user");
			console.log(credential);
			if (!credential?.user?.emailVerified) {
				navigate("/verify-email");
				return;
			}
		} catch (error) {
			errorMessage(handleFirebaseError(error).message);
			// Wait 2 seconds to allow user to read error message
			if (error.code === "auth/internal-error") {
				await new Promise((resolve) => setTimeout(resolve, 2000));
				window.open(
					"https://form.jotform.com/232124002345035",
					"_self"
				);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleSignupWithGoogle = async () => {
		try {
			if (!isChecked) {
				errorMessage(
					"You must agree to Artbeat's Terms and Conditions."
				);
				return;
			}

			setLoadingGoogle(true);

			// Authenticate user with Google and then create a user in our database
			const result = await authenticationService.signInWithGoogle();

			if (
				!result ||
				!result.user ||
				!result?.user?.uid ||
				!result?.user?.email
			) {
				errorMessage("Unable to sign you up");
				return;
			}
			logEvent(analytics, "sign_up", { method: "google" });
			navigate("/onboarding");
		} catch (error) {
			errorMessage(handleFirebaseError(error).message);
		} finally {
			setLoadingGoogle(false);
		}
	};

	const handleSignupWithFacebook = async () => {
		try {
			if (!isChecked) {
				errorMessage(
					"You must agree to Artbeat's Terms and Conditions."
				);
				return;
			}

			setLoadingFacebook(true);

			// Authenticate user with Google and then create a user in our database
			const result = await authenticationService.signInWithFacebook();

			if (
				!result ||
				!result.user ||
				!result?.user?.uid ||
				!result?.user?.email
			) {
				errorMessage("Unable to sign you up");
				return;
			}
			logEvent(analytics, "sign_up", { method: "facebook" });
			navigate("/verify-email");
		} catch (error) {
			errorMessage(handleFirebaseError(error).message);
			if (error.code === "auth/internal-error") {
				await new Promise((resolve) => setTimeout(resolve, 2000));
				window.open(
					"https://form.jotform.com/232124002345035",
					"_self"
				);
			}
		} finally {
			setLoadingFacebook(false);
		}
	};

	useEffect(() => {
		document.title = "Signup | Artbeat ";
	}, []);

	return (
		<div className="w-full h-full flex flex-col justify-center bg-black overflow-y-hidden overflow-x-hidden ">
			<div className="w-screen flex justify-center items-center mt-30 mb-8">
				<ArtBeatWordMark />
			</div>

			<div className="flex-col">
				<form
					onSubmit={handleSignUpWithEmailAndPassword}
					className="inputForm mx-4"
				>
					<div className="inputDiv">
						<MailIcon className="fieldIcon" />
						<input
							type="email"
							name="email"
							pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$"
							value={form.email}
							onChange={handleChange}
							required
							placeholder="Email Address"
							className="inputTextArea"
						/>
					</div>
					<div className="inputDiv">
						<LockIcon className="fieldIcon" />
						<input
							id="password"
							name="password"
							value={form.password}
							type={showPassword ? "text" : "password"}
							placeholder="Enter Password"
							className="inputTextArea"
							onChange={handleChange}
						/>
						{showPassword ? (
							<EyeOffIcon
								onClick={togglePasswordVisibility}
								className="absolute right-6 top-4 cursor-pointer"
							/>
						) : (
							<EyeOnIcon
								onClick={togglePasswordVisibility}
								className="absolute right-6 top-4 cursor-pointer"
							/>
						)}
					</div>
					<div className="inputDiv">
						<LockIcon className="fieldIcon" />
						<input
							id="confirmPassword"
							name="confirmPassword"
							value={form.confirmPassword}
							type={showPassword ? "text" : "password"}
							placeholder="Confirm Password"
							className="inputTextArea"
							onChange={handleChange}
						/>
						{showPassword ? (
							<EyeOffIcon
								onClick={togglePasswordVisibility}
								className="absolute right-6 top-4 cursor-pointer"
							/>
						) : (
							<EyeOnIcon
								onClick={togglePasswordVisibility}
								className="absolute right-6 top-4 cursor-pointer"
							/>
						)}
					</div>

					<div className="flex justify-start items-center mt-4">
						{/* Hidden default checkbox */}
						<input
							type="checkbox"
							id="customCheckbox"
							checked={isChecked}
							onChange={() => setIsChecked((prev) => !prev)}
							className="hidden"
						/>

						{/* Custom styled checkbox */}
						<label
							htmlFor="customCheckbox"
							className={`w-8 h-8 rounded-md cursor-pointer mr-2  transition-all flex-shrink-0 flex items-center justify-center ${
								isChecked
									? "bg-teal-900"
									: "bg-greyscale-700 border-greyscale-350"
							}`}
						>
							{isChecked && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									width="20"
									height="20"
									className="text-teal-100"
								>
									<path
										d="M20 6L9 17L4 12"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)}
						</label>

						<span className="text-greyscale-450 font-inter text-body-sm font-regular leading-snug items-start">
							By signing up, I agree to Artbeat&apos;s <br />
							<a
								href="/settings/terms_and_conditions"
								className="text-teal-500 font-inter m-0 p-0 text-body-sm hover:text-teal-700 focus:outline-none"
								onClick={(e) => {
									e.preventDefault();
									navigate("/terms_and_conditions");
								}}
							>
								Terms and Conditions and Privacy Policy.
							</a>
						</span>
					</div>

					<div className="flex items-center justify-center">
						<button
							type="submit"
							className={` ${
								isFormValid()
									? "btn-primary active:bg-teal-600"
									: " btn-disabled border-none "
							} py-2.5 px-4 mt-4 w-full rounded-full h-10 text-sm font-inter font-semibold `}
							disabled={!isFormValid()}
						>
							{loading ? LoadingSpinner("white") : "Sign Up"}
						</button>
					</div>
				</form>
			</div>

			<text className="justify-center text-center mt-4 mb-4 font-inter text-greyscale-450">
				{" "}
				OR{" "}
			</text>

			<div className="relative w-full pl-4 pr-4">
				<button
					className="flex items-center justify-center py-2.5 px-4 w-full rounded-full h-10 text-sm font-inter font-semibold border-none bg-white text-gray-700 active:bg-gray-300"
					onClick={handleSignupWithGoogle}
				>
					<GoogleIcon className="w-6 h-6 mr-2" />
					{loadingGoogle
						? LoadingSpinner("black")
						: "Log in With Google"}
				</button>
			</div>

			{/* <div className="relative w-full pl-4 pr-4 mt-2 h-11">
				<button
					className="flex items-center justify-center py-2.5 px-4 w-full rounded-full h-10 text-sm font-inter font-semibold border-none bg-white text-gray-700 active:bg-gray-300"
					onClick={handleSignupWithFacebook}
				>
					<FacebookIcon className=" w-6 h-6 mr-2" />
					{loadingFacebook
						? LoadingSpinner("black")
						: "Log in With Facebook"}
				</button>
			</div> */}

			<div className="relative w-full flex justify-center items-center mt-4">
				<span className="text-greyscale-450 font-inter font-regular">
					Already have an account?
				</span>
				<Button
					className="text-teal-500 font-inter font-semibold active:teal-700"
					type="link"
					onClick={() => {
						navigate("/login");
					}}
				>
					Log In
				</Button>
			</div>
		</div>
	);
}
