import React from "react";

/**
 * InputField Component
 *
 * A reusable input field component that can render as an input or textarea, and takes an SVG icon for both left and right sides, along with other standard attributes.
 *
 * @param {React.FunctionComponent<React.SVGProps<SVGSVGElement>>} [Icon] - The SVG icon component to be displayed on the left side of the field.
 * @param {React.FunctionComponent<React.SVGProps<SVGSVGElement>>} [IconRight] - The SVG icon component to be displayed on the right side of the field.
 * @param {() => void} [onIconRightClick] - Click handler for the right-hand side icon.
 * @param {string} [iconWidth='w-5'] - The width of the SVG icon. Defaults to 'w-5'.
 * @param {string} [iconHeight='h-5'] - The height of the SVG icon. Defaults to 'h-5'.
 * @param {string} [iconRightClass='absolute right-6 top-4 cursor-pointer'] - Classname for the right-hand side icon.
 * @param {boolean} [isTextArea=false] - Whether to render a textarea instead of an input. Defaults to false.
 * @param {React.TextareaHTMLAttributes<HTMLTextAreaElement>} [textAreaProps] - Additional standard HTML textarea attributes when isTextArea is true.
 * @param {React.InputHTMLAttributes<HTMLInputElement>} [inputProps] - Additional standard HTML input attributes when isTextArea is false.
 *
 * Example Usage:
 *
 * <InputField
 *   Icon={Phone}
 *   IconRight={showPassword ? EyeOffIcon : EyeOnIcon}
 *   onIconRightClick={togglePasswordVisibility}
 *   iconWidth="w-6"
 *   iconHeight="h-6"
 *   inputProps={{
 *     id: "mobileNumber",
 *     name: "mobileNumber",
 *     pattern: "0[6-8,1][0-9]{8}",
 *     type: "text",
 *     required: true,
 *     placeholder: "0123456789",
 *     onChange: handlePhoneNumberChange
 *   }}
 * />
 */

interface InputFieldProps {
	Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	IconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	onIconRightClick?: () => void;
	className?: string;
	iconWidth?: string; // Width of the icon
	iconHeight?: string; // Height of the icon
	iconRightClass?: string;
	isTextArea?: boolean; // Whether to render a textarea instead of an input
	textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
	inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
	Rand?: boolean; // Rand value for price inputs
}

const InputField: React.FC<InputFieldProps> = ({
	Icon,
	IconRight,
	onIconRightClick,
	iconWidth = "w-5", // Default width
	iconHeight = "h-5", // Default height
	iconRightClass = "absolute right-6 top-4 cursor-pointer",
	isTextArea = false,
	textAreaProps,
	inputProps,
	Rand,
}) => {
	const className = isTextArea ? "LgInputTextArea" : "inputTextArea"; // Determine the class name based on isTextarea

	return (
		<div className={isTextArea ? "LgInputDiv" : "inputDiv"}>
			{Rand ? (
				<div className="flex absolute left-4 top-3 items-center">
					<section className="font-inter text-base font-normal text-greyscale-500 ">
						R
					</section>
				</div>
			) : (
				<Icon
					className={`${iconWidth} ${iconHeight} ${
						isTextArea === false ? "fieldIcon" : "LgInputFieldIcon"
					}`}
				/>
			)}

			{isTextArea ? (
				<textarea className={className} {...textAreaProps} />
			) : (
				<input className={className} {...inputProps} />
			)}
			{IconRight && (
				<IconRight
					onClick={onIconRightClick}
					className={iconRightClass}
				/>
			)}
		</div>
	);
};

export default InputField;
