import React from "react";

interface PillProps {
	label: string;
	icon?: React.ReactNode;
	className?: string;
	onClick?: () => void;
	active?: boolean;
}

const PillButton: React.FC<PillProps> = ({
	label,
	icon,
	className,
	onClick,
}) => {
	return (
		<button
			className={`${className} flex items-center border-greyscale-600 text-greyscale-100 font-inter font-normal text-sm px-3 rounded-full py-0 bg-greyscale-650`}
			onClick={onClick}
		>
			{icon && <span className="mr-1">{icon}</span>}
			{label}
		</button>
	);
};

export default PillButton;
