import React from "react";
import PillButton from "../../../components/Buttons/PillButton";

interface PillTabsProps {
    tabs: {
        key: string;
        label: string;
        icon?: React.ReactNode;
        onClick: () => void;
    }[];
    activeKey: string;
    className?: string; // Optional className prop for styling
}

export default function PillTabs({ tabs, activeKey, className = "" }: PillTabsProps) {
    // Combine default classes with the optional className prop
    const containerClasses = ` ${className} flex space-x-2`;

    return (
        <div className={containerClasses}>
            {tabs.map((tab) => (
                <div key={tab.label} onClick={tab.onClick}>
                    <PillButton
                        className={`text-sm font-inter font-normal ${
                            tab.key === activeKey ? "active-tab-style" : ""
                        }`}
                        label={tab.label}
                        icon={tab.icon}
                    />
                </div>
            ))}
        </div>
    );
}