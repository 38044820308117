import { Code, ConnectError } from "@bufbuild/connect";
import { User as ArtbeatUser } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { FirebaseApp } from "firebase/app";
import {
	Auth,
	browserLocalPersistence,
	createUserWithEmailAndPassword,
	EmailAuthProvider,
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	reauthenticateWithCredential,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	updatePassword,
	User,
} from "firebase/auth";
import { BehaviorSubject } from "rxjs";

import { app } from "config/firebase";
import { apiService } from "services/api";
import { store } from "store";
import { setUser, setUserSuccess } from "store/user/userSlice";

export interface EmailAndPassword {
	email: string;
	password: string;
}

// AuthenticationService holds any auth related functions
// for logging in and signing up, and logging out
class AuthenticationService {
	// Initialize Firebase Authentication and get a reference to the service
	private auth: Auth;
	private user: BehaviorSubject<User | null>;
	private isLoaded: BehaviorSubject<boolean>;

	constructor(app: FirebaseApp) {
		this.auth = getAuth(app);
		this.auth.setPersistence(browserLocalPersistence);
		this.auth.useDeviceLanguage();
		this.user = new BehaviorSubject<User | null>(null);
		this.isLoaded = new BehaviorSubject<boolean>(false);
	}

	async reloadUser() {
		const user = this.auth.currentUser;

		if (user) {
			await user.reload();
		}
	}

	// signUpWithEmailAndPassword Creates a new user using their email and password
	async signUpWithEmailAndPassword({ email, password }: EmailAndPassword) {
		const credential = await createUserWithEmailAndPassword(
			this.auth,
			email,
			password
		);

		await this.fetchUser(credential.user);

		this.isLoaded.next(true);

		return credential;
	}

	// signInWithEmailAndPassword authenticates a user with their email and password
	async signInWithEmailAndPassword({ email, password }: EmailAndPassword) {
		console.log("Logging in with email and password");
		const credential = await signInWithEmailAndPassword(
			this.auth,
			email,
			password
		);

		console.log(credential);
		await this.fetchUser(credential.user);

		this.isLoaded.next(true);

		return credential;
	}

	async signInAnonymously() {
		const credential = await signInAnonymously(this.auth);

		return credential;
	}

	// signInWithGoogle authenticates a user with their Google account
	async signInWithGoogle() {
		const provider = new GoogleAuthProvider();

		const credential = await signInWithPopup(this.auth, provider);

		await this.fetchUser(credential.user);

		this.isLoaded.next(true);

		return credential;
	}

	// signInWithFacebook authenticates a user with their Facebook account
	async signInWithFacebook() {
		const provider = new FacebookAuthProvider();

		const credential = await signInWithPopup(this.auth, provider);

		await this.fetchUser(credential.user);

		this.isLoaded.next(true);

		return credential;
	}

	// sends a verification email to the desired email address
	async sendVerificationEmail() {
		const user = this.auth?.currentUser;
		if (!user) {
			throw Error("User not logged in");
		}

		return await sendEmailVerification(user, {
			url: `${window.location.origin}/verify-email?email=${user.email}`,
		});
	}

	// signOut signs out the currently authenticated user
	async signOut() {
		await signOut(this.auth);
	}

	// sendPasswordResetEmail sends a password reset email to the user
	async sendPasswordResetEmail(email: string) {
		return await sendPasswordResetEmail(this.auth, email);
	}

	// Reauthenticate the user with their current password
	async reauthenticateWithPassword(password: string) {
		const user = this.auth?.currentUser;
		if (!user || !user.email) {
			throw Error("User not logged in or email not available");
		}

		// Create a credential with the email and current password
		const credential = EmailAuthProvider.credential(user.email, password);

		// Reauthenticate the user with the created credential
		return await reauthenticateWithCredential(user, credential);
	}

	// updatePassword sets/updates a users current password
	async updatePassword(currentPassword: string, newPassword: string) {
		// Reauthenticate the user with their current password
		await this.reauthenticateWithPassword(currentPassword);

		// Then update to the new password
		const user = this.auth?.currentUser;
		if (!user) {
			throw Error("User not logged in");
		}

		return await updatePassword(user, newPassword);
	}

	// Gets user providerID
	public getUserProviderId(): string | null {
		const user = this.auth.currentUser;
		const providerData = user?.providerData[0];
		return providerData?.providerId ?? null;
	}

	/**
	 * Fetches the user if they exist in the database. Otherwise, sets
	 * the user to null in the store.
	 *
	 * @param user Firebase authentication user
	 */
	async fetchUser(user: User) {
		store.dispatch(setUser());
		console.log("Fetching user");
		// Get the user's ID token
		const idToken = await user.getIdToken();

		console.log("Set ID token");
		// Set a default ID token for the api service
		apiService.setIDToken(idToken);
		if (user.isAnonymous) {
			const anonymousUser = new ArtbeatUser();
			anonymousUser.name = "Anonymous";

			store.dispatch(setUserSuccess(anonymousUser));
		} else {
			try {
				console.log("Setting user");
				const hockneyUser = await apiService.getUser(
					`users/${user.uid}`,
					{}
				);
				store.dispatch(setUserSuccess(hockneyUser));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: ConnectError | any) {
				// If the error returned is a not_found, then no user resource exists
				// yet for the authenticated user. Set the user to null in the store.
				console.log("Haibo");
				if (
					error instanceof ConnectError &&
					error.code === Code.NotFound
				) {
					store.dispatch(setUserSuccess(null));
				}
			}
		}
	}

	public get currentUser(): BehaviorSubject<User | null> {
		return this.user ?? null;
	}

	public get userIsLoaded(): BehaviorSubject<boolean> {
		return this.isLoaded;
	}
}

// Export a singleton instance
export const authenticationService = new AuthenticationService(app);
