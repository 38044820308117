import Button from "components/Buttons/Button";
import { SelectorDropdown } from "components/Dropdowns/SelectorDropdown";
import Header from "components/Headers/Header";
import InputField from "components/Inputs/InputField";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddImage } from "../../../assets/icons/AddImage.svg";
import { ReactComponent as ChevronSelectorHorizontal } from "../../../assets/icons/chevron-selector-horizontal.svg";
import { ReactComponent as ChevronSelectorVertical } from "../../../assets/icons/chevron-selector-vertical.svg";
import { ReactComponent as Description } from "../../../assets/icons/description.svg";
import { ReactComponent as Palette } from "../../../assets/icons/palette.svg";
import { ReactComponent as TitleImage } from "../../../assets/icons/titleImage.svg";
import { ReactComponent as Image } from "../../../assets/icons/upload-image.svg";
import { artworkMediums } from "../../../enums/artworks";
import { UnitsDropdown } from "../Components/UnitsDropdown";
import { useUpload } from "../UploadContext/UploadContext";

export default function ArtworkDetails() {
	// useUpload context to capture artwork details
	const { artworkDetails, setArtworkDetails, artworkImage, setArtworkImage } =
		useUpload();

	// const for navigation
	const navigate = useNavigate();

	// states for toggling between width and height
	const [displayHeight, setDisplayHeight] = useState<number | null>(null);
	const [heightUnit, setHeightUnit] = useState("cm");
	const [displayWidth, setDisplayWidth] = useState<number | null>(null);
	const [widthUnit, setWidthUnit] = useState("cm");

	// Prepare the options for the height unit SelectorDropdown component
	const heightUnitOptions = [{ displayName: "cm" }, { displayName: "m" }];
	const widthUnitOptions = [{ displayName: "cm" }, { displayName: "m" }];

	// function to navigate to setPrice
	const navigateToSetPrice = () => {
		navigate("/home/artworkDetails/setPrice");
		console.log("Next");
	};

	// const to display medium selected after navigated away
	const selectedMediumDisplayName = artworkMediums.find(
		(medium) => medium.enum === artworkDetails.medium
	)?.displayName;

	// Function to handle the file upload
	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files ? e.target.files[0] : null;
		setArtworkImage(selectedFile);
	};

	// Prepare the options for the SelectorDropdown component
	const dropdownOptions = artworkMediums.map((medium) => ({
		displayName: medium.displayName,
	}));

	const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setArtworkDetails({
			...artworkDetails,
			title: e.target.value,
		});
	};

	const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setArtworkDetails({
			...artworkDetails,
			description: e.target.value,
		});
	};

	const handleHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = Number(e.target.value);
		setDisplayHeight(value);

		const heightInCM = heightUnit === "m" ? value * 100 : value;
		setArtworkDetails({
			...artworkDetails,
			height: heightInCM,
		});
	};

	const handleWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = Number(e.target.value);
		setDisplayWidth(value);

		const widthInCM = widthUnit === "m" ? value * 100 : value;
		setArtworkDetails({
			...artworkDetails,
			width: widthInCM,
		});
	};

	const handleSelectedMedium = (displayName: string) => {
		const artworkMedium = artworkMediums.find(
			(medium) => medium.displayName === displayName
		);

		if (artworkMedium) {
			setArtworkDetails({
				...artworkDetails,
				medium: artworkMedium.enum,
			});
		} else {
			setArtworkDetails({
				...artworkDetails,
				medium: null,
			});
		}
	};

	// checks if artworkDetails are complete
	const areArtworkDetailsComplete = (artworkDetails) => {
		// Ensure each field has been filled out. Add or remove conditions as needed.
		if (
			artworkDetails.title &&
			artworkDetails.medium &&
			artworkDetails.description &&
			artworkDetails.height &&
			artworkDetails.width &&
			artworkImage
		) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (heightUnit === "m") {
			setDisplayHeight(artworkDetails.height / 100);
		} else {
			setDisplayHeight(artworkDetails.height);
		}

		if (widthUnit === "m") {
			setDisplayWidth(artworkDetails.width / 100);
		} else {
			setDisplayWidth(artworkDetails.width);
		}
	}, [artworkDetails.width, artworkDetails.height, widthUnit, heightUnit]);

	return (
		<div className="relative items-center justify-center">
			<Header
				title="Upload Your Artwork"
				onBack={() => navigate("/home")}
			/>
			<div className="relative flex flex-col pt-14 p-4 items-center border-none ">
				{artworkImage ? (
					<div className="relative">
						<img
							src={URL.createObjectURL(artworkImage)}
							alt="Uploaded Artwork"
							className="w-full h-auto rounded-xl"
						/>

						<label htmlFor="profile-pic">
							<AddImage className="absolute bottom-0 right-0 z-10 mx-4 mb-4" />
							<input
								id="profile-pic"
								type="file"
								accept="image/*"
								onChange={handleFileUpload}
								className="hidden"
							/>
						</label>
					</div>
				) : (
					// Show the input field when no image is uploaded
					<label htmlFor="profile-pic" className="self-stretch">
						<div className="flex flex-col self-stretch pt-28 pr-4 pb-4 pl-4 items-center justify-end gap-y-14 border rounded-xl border-dashed border-teal-500 bg-transparent">
							<Image className="w-20 h-20" />
							<div className="flex self-stretch ">
								<div className="btn font-inter text-base font-semibold flex-1 btn-secondary btn-lg">
									Upload Artwork
								</div>
							</div>
							<input
								id="profile-pic"
								type="file"
								accept="image/*"
								onChange={handleFileUpload}
								className="hidden"
							/>
						</div>
					</label>
				)}
			</div>

			{/* Form values */}

			<div className="flex-col pr-4 pl-4 pb-4">
				<form className="inputForm">
					<div className=" mb-3">
						<InputField
							inputProps={{
								type: "text",
								placeholder: "Add a Title",
								onChange: handleTitleChange,
								value: artworkDetails.title || "",
							}}
							Icon={TitleImage}
						/>
					</div>

					<div className=" mb-3">
						<SelectorDropdown
							text="Select Artwork Medium"
							iconComponent={<Palette />} // Replace this with your actual icon
							options={dropdownOptions}
							onOptionSelected={handleSelectedMedium}
							selectedValue={selectedMediumDisplayName || ""}
						/>
					</div>

					<div className=" mb-3">
						<InputField
							Icon={Description}
							isTextArea
							textAreaProps={{
								placeholder:
									"Tell us the story behind your artwork",
								onChange: handleDescriptionChange,
								value: artworkDetails.description || "",
							}}
						/>
					</div>

					<div className="flex justify-center gap-x-1 mb-3">
						<div className="flex items-start">
							<div className="flex items-center gap-x-2  bg-greyscale-650 border-greyscale-600 border-[1px] p-3 rounded-tl-lg rounded-bl-lg">
								<ChevronSelectorVertical />
								<input
									type="number"
									className="w-full text-body placeholder-greyscale-450 bg-transparent text-white font-inter border-none focus:outline-none"
									placeholder="Heights"
									onChange={handleHeightChange}
									value={displayHeight || null}
								/>
							</div>
							<div className="flex justify-between items-center rounded-tr-lg rounded-br-lg self-stretch bg-greyscale-600">
								<UnitsDropdown
									text="Select Unit"
									options={heightUnitOptions}
									onOptionSelected={(selectedUnit) =>
										setHeightUnit(selectedUnit)
									}
									selectedValue={heightUnit}
								/>
							</div>
						</div>

						<div className="flex items-start">
							<div className="flex items-center gap-x-2 border bg-greyscale-650 border-greyscale-600 p-3 rounded-tl-lg rounded-bl-lg">
								<ChevronSelectorHorizontal />
								<input
									type="number"
									className="w-full text-body placeholder-greyscale-450 bg-transparent text-white font-inter border-none focus:outline-none"
									placeholder="Width"
									onChange={handleWidthChange}
									value={displayWidth || ""}
								/>
							</div>
							<div className="flex justify-between items-center self-stretch rounded-tr-lg rounded-br-lg border border-greyscale-600 bg-greyscale-600">
								<UnitsDropdown
									text="Select Unit"
									options={widthUnitOptions}
									onOptionSelected={(selectedUnit) =>
										setWidthUnit(selectedUnit)
									}
									selectedValue={widthUnit}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>

			{
				//Added div at the bottom to create space between nav bar and 'Next' button
			}
			<div className="mb-4 w-full pl-4 pr-4 flex justify-center">
				<Button
					type="submit"
					text="Next"
					size="small"
					color="primary"
					onClick={navigateToSetPrice}
					disabled={!areArtworkDetailsComplete(artworkDetails)}
				/>
			</div>
			<div className="bg-transparent h-[60px]"></div>
		</div>
	);
}
