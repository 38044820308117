import AddressSelector from "components/Checkout/AddressSelector";
import Header from "components/Headers/Header";
import InputField from "components/Inputs/InputField";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { ReactComponent as Coins } from "../../../assets/icons/coins-02.svg";
import Button from "../../../components/Buttons/Button";
import AccountDetails from "../AccountDetails/AccountDetails";
import { useUpload } from "../UploadContext/UploadContext";

export default function SetPrice() {
	// const for setPrice context
	const { price, setPrice, artworkImage } = useUpload();

	// const for navigation
	const navigate = useNavigate();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showBankInput, setShowBankInput] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [bankDetailsEntered, setBankDetailsEntered] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState();
	const [hasAddress, setHasAddress] = useState(false);

	// currentUser contains the addresses and bank details for a user
	const currentUser = useSelector((state: RootState) => state.user.user);

	// function to navigate to setPrice
	const navigateToArtworkSummary = () => {
		navigate("/home/artworkDetails/setPrice/artworkSummary");
	};

	// handle address change
	const handleAddressChange = (address) => {
		setSelectedAddress(address);
	};

	// handle bank details
	const handleBankDetailsEntered = () => {
		setBankDetailsEntered(true);
		setShowBankInput(false); // Hide the AccountDetails component
	};

	// handling the price input field
	const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPrice(parseFloat(e.target.value));
	};

	// Function to check if price is set, bank details are entered, and an address is selected
	const isReadyToProceed = (): boolean => {
		// Check if a price is set
		const isPriceSet = price !== null && !isNaN(price);

		// Check if bank details are entered
		const isBankDetailsEntered = currentUser?.bankDetails !== null;

		return isPriceSet && isBankDetailsEntered && hasAddress;
	};

	useEffect(() => {
		console.log("selectedAddress: " + selectedAddress);
	}, [selectedAddress]);

	return (
		<div className="relative">
			<Header
				title="Upload Artwork"
				onBack={() => navigate("/home/artworkDetails")}
			/>
			<div className="relative flex flex-col items-center border-none pt-14 pb-4 px-4">
				{artworkImage ? (
					// Display the uploaded image at full width
					<img
						src={URL.createObjectURL(artworkImage)}
						alt="Uploaded Artwork"
						className="w-full h-auto rounded-xl"
					/>
				) : (
					<div></div>
				)}
			</div>

			<div className="p-4">
				<div className="flex flex-col mb-4">
					<form className="inputForm">
						<div className="">
							<InputField
								inputProps={{
									type: "number",
									placeholder: "Artwork Price",
									onChange: handlePriceChange,
									value: price || "",
								}}
								Rand={true}
							/>
						</div>
					</form>
				</div>

				<div className="mb-4">
					<AccountDetails
						onBankDetailsEntered={handleBankDetailsEntered}
						currentUserBankDetails={
							currentUser?.bankDetails || null
						}
					/>
				</div>

				<div className="">
					<AddressSelector
						onAddressChange={handleAddressChange}
						setHasAddress={setHasAddress}
					/>
				</div>

				{
					//Added div at the bottom to create space between nav bar and 'Next' button
				}
				<div className=" mb-4 mt-4 w-full flex justify-center">
					<Button
						type="submit"
						text="Next"
						size="small"
						color="primary"
						onClick={navigateToArtworkSummary}
						disabled={!isReadyToProceed()}
					/>
				</div>
				<div className="bg-transparent h-[60px]"></div>
			</div>
		</div>
	);
}
