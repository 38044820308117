import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { ReactComponent as ErrorIcon } from "../../assets/icons/x-circle.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/check-circle.svg";

interface MessageProps {
    message: string;
    type: "success" | "error";
    duration?: number;  // Timeout duration in milliseconds
    zIndex: number;
    onClose?: () => void;  // Callback function when the message is closed
}

const Message: React.FC<MessageProps> = ({ message, type, duration = 4000, zIndex, onClose }) => {
    const [show, setShow] = useState(true);
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(false);
            if (onClose) onClose();  // Call the onClose callback when the message is closed
        }, duration);
        return () => clearTimeout(timeout);  // Clear the timeout if the component is unmounted
    }, [message, duration, onClose]);
    
    const bgColor = type === "success" ? "bg-teal-900" : "bg-red-900";
    const Icon = type === "success" ? SuccessIcon : ErrorIcon;
    const textColour = type === "success" ? "text-teal-100" : "text-red-100";

    return (
        <Transition
            show={show}
            enter="transition-opacity duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className={`flex items-center p-4 gap-4 h-auto fixed top-4 left-1/2 transform -translate-x-1/2 text-center rounded-xl ${bgColor}`} style={{width: "calc(100% - 4rem)", zIndex: (zIndex +100) }}>
                <Icon className="flex-shrink-0" />
                <div className={`${textColour}  text-body-sm font-inter justify-start text-left flex-grow`}>
                    {message}
                </div>
            </div>
        </Transition>
    ); 
};

export default Message; 