import {
	Bank,
	BankDetails,
	RegionCode,
} from "@hockney-app/proto/types/v1alpha1/bank_details_pb";
import { Modal } from "antd";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BankSvg } from "../../../assets/icons/bank.svg";
import { ReactComponent as GreyBank } from "../../../assets/icons/GreyBank.svg";
import { ReactComponent as Hash } from "../../../assets/icons/hash-02.svg";
import { ReactComponent as InfoCircle } from "../../../assets/icons/info-circle.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user-02.svg";
import { ReactComponent as Wallet } from "../../../assets/icons/wallet-01.svg";
import Button from "../../../components/Buttons/Button";
import { SelectorDropdown } from "../../../components/Dropdowns/SelectorDropdown";
import LoadingSpinner from "../../../components/LoadingSpinner/loadingSpinner";
import Message from "../../../components/Messages/Message";
import { accountType, banks } from "../../../enums/bankInfo";
import { apiService } from "../../../services/api";
import { RootState } from "../../../store";
import { setUser, setUserSuccess } from "../../../store/user/userSlice";

interface AccountDetailsProps {
	onBankDetailsEntered: () => void;
	currentUserBankDetails: BankDetails | null;
}

export default function AccountDetails({
	onBankDetailsEntered,
	currentUserBankDetails,
}: AccountDetailsProps) {
	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const [accountName, setAccountName] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [selectedBank, setSelectedBank] = useState(null);
	const [selectedAccountType, setSelectedAccountType] = useState(null);

	// States for Banking Detail Modal
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Show/hide bank input state
	const [showBankInput, setShowBankInput] = useState(false);
	const handleBankButton = () => {
		setShowBankInput((prevShowBankInput) => !prevShowBankInput);
	};

	// Const for Error/Success message
	const [errorMessage, setErrorMessage] = useState("");
	const [errorCount, setErrorCount] = useState(0);

	// Const for Error/Success message
	const [successMessage, setSuccessMessage] = useState("");
	const [successCount, setSuccessCount] = useState(0);

	// Selectors and dispatch
	const dispatch = useDispatch();
	const currentUser = useSelector((state: RootState) => state.user.user);

	const handleAddBankAccount = async (e) => {
		e.preventDefault();
		console.log("handleAddBankAccount");

		try {
			setLoading(true);

			// Create the artwork
			let updatedUser = currentUser;
			const bankdetails = new BankDetails();
			const bank = new Bank();
			console.log(selectedBank);
			bank.bankName = selectedBank;
			// get the branch code from the array of banks and set it
			const bankInfo = banks.find(
				(bank) => bank.displayName === selectedBank
			);
			bank.branchCode = bankInfo.branchCode.toString();
			bank.regionCode = RegionCode.ZA;
			bankdetails.bank = bank;
			bankdetails.accountHolder = accountName;
			bankdetails.accountNumber = accountNumber;
			updatedUser.bankDetails = bankdetails;

			console.log(updatedUser);

			// Update the user
			updatedUser = await apiService.updateUser(updatedUser, [
				"bank_details",
			]);

			dispatch(setUserSuccess(updatedUser));
			setShowBankInput(false);

			setSuccessMessage("Succesfully updated banking details");
			setSuccessCount((count) => count + 1);
		} catch (error) {
			setErrorMessage("Error updating bank details");
			setErrorCount((count) => count + 1);
			console.error(error);
		} finally {
			setLoading(false);
			onBankDetailsEntered();
		}
	};

	// Checks if input fields are valid, which renders the primary active button
	const isFormValid = () => {
		return (
			accountName &&
			accountNumber.length >= 8 &&
			accountNumber.length <= 17 &&
			selectedBank &&
			selectedAccountType
		);
	};

	// handle functions for state changes
	const handleAccountNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		const details = {
			accountName,
			accountNumber,
			selectedBank,
			selectedAccountType,
		};
		setAccountName(e.target.value);
	};

	const handleAccountNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setAccountNumber(value);
		if (value.length < 8 || value.length > 17) {
			setErrorMessage("Account number must be between 8 and 17 digits.");
			setErrorCount(errorCount + 1);
		}
	};

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const handleBankSelection = (selectedOption: string) => {
		setSelectedBank(selectedOption);
	};

	const handleAccountTypeSelection = (selectedOption: string) => {
		setSelectedAccountType(selectedOption);
	};

	// Handlers for BankingDetailsModal
	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			{errorMessage && (
				<Message
					message={errorMessage}
					duration={4000}
					type={"error"}
					key={errorCount}
					zIndex={errorCount + 1}
				/>
			)}
			{successMessage && (
				<Message
					message={successMessage}
					duration={4000}
					type={"success"}
					key={successCount}
					zIndex={successCount}
				/>
			)}

			{/* Display bank details or "add/change bank details" based on currentUserBankDetails */}
			{currentUserBankDetails ? (
				<>
					<div className="flex py-2 px-4 items-start gap-2 self-stretch rounded-lg border-greyscale-600 bg-greyscale-650 ">
						<div className="flex gap-2 justify-center">
							<GreyBank />
							<div className="flex-col gap-1 self-stretch">
								<section className="text-white font-inter text-base not-italic font-semibold leading-5 mb-1">
									{currentUserBankDetails.bank.bankName}
								</section>
								<section className="font-inter text-base not-italic font-normal leading-5 text-greyscale-400 my-1">
									Account Ending in ....
									{currentUserBankDetails.accountNumber.slice(
										-4
									)}
								</section>
							</div>
						</div>
						<button
							className="justify-end ml-auto p-0 bg-transparent border-none"
							onClick={showModal}
						>
							<InfoCircle className="text-white" />
						</button>
						<Modal
							title="Where Your Earnings Go"
							visible={isModalOpen}
							onOk={handleOk}
							onCancel={handleCancel}
							className="font-inter text-white text-base not-italic font-medium"
							footer={null}
						>
							<section>
								This is the bank account where we&apos;ll
								deposit your earnings. Rest assured, your
								banking details are kept private and secure.
							</section>
						</Modal>
					</div>
					<div className="flex items-center justify-center mt-4">
						<div
							className="btn btn-secondary btn-sm flex-1"
							onClick={handleBankButton}
						>
							Change Bank Details
						</div>
					</div>
				</>
			) : (
				<div className="flex items-center justify-center mt-4 ">
					<div
						className="btn btn-secondary btn-sm flex-1 gap-2"
						onClick={handleBankButton}
					>
						<BankSvg />
						Add Bank Account
					</div>
				</div>
			)}

			{showBankInput && (
				<div className="flex-col h-[100vdh] pb-4 justify-center overflow-hidden">
					<div className="inputForm my-1 mt-4">
						<SelectorDropdown
							text="Select Bank"
							iconComponent={<GreyBank />}
							options={banks}
							onOptionSelected={(selectedOption) => {
								console.log(selectedOption);
								handleBankSelection(selectedOption);
							}}
						/>
					</div>

					<div className="inputForm">
						<SelectorDropdown
							text="Select Account Type"
							iconComponent={<Wallet />}
							options={accountType}
							onOptionSelected={(selectedOption) => {
								console.log(selectedOption);
								handleAccountTypeSelection(selectedOption);
							}}
						/>
					</div>

					<form
						className="inputForm mb-8"
						onSubmit={handleAddBankAccount}
					>
						<div className="inputDiv">
							<UserIcon className="fieldIcon" />
							<input
								type="text"
								className="inputTextArea"
								placeholder="Account Holder"
								onChange={handleAccountNameChange}
							/>
						</div>

						<div className="inputDiv">
							<Hash className="fieldIcon" />
							<input
								type="number"
								className="inputTextArea"
								placeholder="Account Number"
								title="Account number must be between 8 and 17 digits"
								inputMode="numeric"
								onChange={handleAccountNumberChange}
							/>
						</div>
						<div className="flex items-center justify-center my-8">
							<Button
								type="submit"
								color="primary"
								text="Add Bank Account"
								size="small"
								loading={loading}
								disabled={!isFormValid()}
							/>
						</div>
					</form>
				</div>
			)}
		</>
	);
}
