import {
	Artwork,
	Artwork_State,
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { useLocationAndRates } from "hooks/useLocation";
import React, { useState } from "react";
import { getDineroPrice } from "utils/price/price";
import { ReactComponent as CollectionIcon } from "../../assets/icons/image-collected.svg";

interface ArtworkCardProps {
	artwork: Artwork;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function ArtworkCard({ artwork, onClick }: ArtworkCardProps) {
	const [imageLoaded, setImageLoaded] = useState(false);

	const { rate, currency } = useLocationAndRates();
	const isForSale = artwork?.state === Artwork_State.FOR_SALE;
	const isCollected = artwork?.state === Artwork_State.COLLECTED;

	function getGoogleStorageImageUrl(originalUrl) {
		// Append the desired dimensions to the URL
		let modifiedUrl = originalUrl.replace("%2F1?", "%2F1_1080x1080?");
		modifiedUrl = modifiedUrl.split("&token=")[0];
		return modifiedUrl;
	}

	return (
		<div
			onClick={onClick}
			className="relative w-full aspect-w-1 aspect-h-1"
		>
			<img
				style={{
					opacity: imageLoaded ? 1 : 0,
					transition: "opacity 0.5s ease-out",
					borderRadius: "1rem",
					width: "100%",
					objectFit: "contain",
				}}
				src={getGoogleStorageImageUrl(artwork.imageUrls[0])}
				alt="Artwork"
				onLoad={() => setImageLoaded(true)}
			/>
			{/* Conditionally render price pill or collected button */}
			{imageLoaded && (
				<>
					{isForSale && (
						<div
							className="absolute bottom-2 right-2 bg-greyscale-700 px-2 py-1 rounded-full text-white font-inter font-normal bg-opacity-40 backdrop-blur-md"
							style={{ fontSize: "10px" }}
						>
							{getDineroPrice(
								artwork?.currentPrice,
								currency,
								rate
							)}
						</div>
					)}

					{isCollected && (
						<button
							className="absolute bottom-2 left-2 bg-greyscale-700 px-2 py-1 text-xs rounded-full text-white font-inter bg-opacity-40 backdrop-blur-md border-none"
							onClick={() => {
								/* Handle the button click event here */
							}}
						>
							<CollectionIcon className="mt-1" />
						</button>
					)}
				</>
			)}
		</div>
	);
}
