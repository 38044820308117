import React from "react";
import { Icon } from "@ant-design/compatible";
import { ReactComponent as SignatureVerified } from "../../assets/icons/signatureverified.svg";
import {useNavigate} from "react-router-dom";
import {useSelector } from "react-redux";
import { RootState } from "../../store"; 

interface ArtworkSignatureProps {
    artistUsername: string;
    style?: React.CSSProperties; 
}

  
// This is a pill, placed in the bottom left corner of the Artwork component"s
// image carousel. It contains the artist"s username and a little verified icon
// on the left. The pill is grey, and the text is white. The verified icon is
// an image with name "signatureverified.png" in the src/assets/images folder.
export default function ArtworkSignature({ artistUsername,  style }: ArtworkSignatureProps) {
    const navigate = useNavigate();
      	// Currently logged in user
	const me = useSelector((state: RootState) => state.user.user);
    
    const handleNavigation = () => {
			navigate(`/@/${artistUsername}`);
	}; 

    return (
        <div    
            style={style} 
            className="absolute bottom-3 left-3 px-2 py-1 h-7.5  flex items-center justify-center text-white font-normal cursor-pointer font-inter rounded-full bg-opacity-40 bg-greyscale-700 backdrop-blur-lgc"
            onClick={() => {
                handleNavigation() ;
            }}
        >
            <Icon
                component={SignatureVerified}
                className=" text-xl"
            />
            <span className="ml-1 text-white font-inter text-xs font-normal">@{artistUsername}</span>
        </div>
    );
}

