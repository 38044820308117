import { Loader } from "@googlemaps/js-api-loader";
import { Address } from "@hockney-app/proto/types/v1alpha1/address_pb";
import Button from "components/Buttons/Button";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Building } from "../../assets/icons/building-05.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as Globe } from "../../assets/icons/globe-05.svg";
import { ReactComponent as Hash } from "../../assets/icons/hash-02.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/icons8-trash.svg";
import { ReactComponent as LightMarker } from "../../assets/icons/light-marker-pin-04.svg";
import { ReactComponent as Map } from "../../assets/icons/map-02.svg";
import { ReactComponent as Marker } from "../../assets/icons/marker-pin-04.svg";
import { ReactComponent as ProvinceMap} from "../../assets/icons/map-01.svg";
import { ReactComponent as HouseMarker } from "../../assets/icons/mark.svg";
import { RootState } from "../../store";
import { useMessage } from "components/Messages/MessageProvider"; 

//Users default addresses are always at index 0 of the array, the address object at this index is initially returned as the selected address.

interface AnonAddressSelectorProps {
	onAddressChange: (address: Address) => void;
}

export default function AnonAddressSelector({
	onAddressChange,
}: AnonAddressSelectorProps) {
	const dispatch = useDispatch();
	const formRef = useRef(null);
	const [showForm, setShowForm] = useState(false);
	const currentUser = useSelector((state: RootState) => state.user.user);
	const [selectedAddress, setSelectedAddress] = useState(null); 
	const googleAPIKey = process.env.REACT_APP_FIREBASE_API_KEY;
	const {errorMessage, successMessage} = useMessage(); 

	const loader = new Loader({
		apiKey: googleAPIKey,
		version: "weekly",
		libraries: ["places"],
	});

	const [isItemFromDropdownSelected, setIsItemFromDropdownSelected] =
		useState(false);

	// states for buttons
	const [addressUploading, setAddressUploading] = useState(false);
	const [showAddresses, setShowAddresses] = useState(false);


	//states for Places API
	const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(
		null
	);
	const inputRef = useRef();
	const options = {
		componentRestrictions: { country: "za" },
	};

	// Single state variable to hold all form values
	const [formValues, setFormValues] = useState({
		streetAddress: "",
		localArea: "",
		city: "",
		province:"", 
		zip: "",
		country:"", 

	});
	
		const handlePlaceSelect = () => {
			const place = autoCompleteRef?.current.getPlace();
			if (place) {
				const address = place.address_components;
				const streetNumber =
					address.find((component) =>
						component.types.includes("street_number")
					)?.long_name || "";
				const streetName =
					address.find((component) => component.types.includes("route"))
						?.long_name || "";
				const sublocality =
					address.find((component) =>
						component.types.includes("sublocality")
					)?.long_name || "";
				const city =
					address.find((component) =>
						component.types.includes("locality")
					)?.long_name || "";
				const state =
					address.find((component) =>
						component.types.includes("administrative_area_level_1")
					)?.long_name || "";
				const zip =
					address.find((component) =>
						component.types.includes("postal_code")
					)?.long_name || "";
				const country =
					address.find((component) =>
						component.types.includes("country")
					)?.long_name || "";
		
				setFormValues({
					streetAddress: `${streetNumber} ${streetName}`,
					localArea: sublocality,
					city: city,
					province: state,
					zip: zip,
					country: country, // Add country here
				});
			}
		};



	// Function to check if all fields are populated
		const isFormValid = () => {
			return Object.values(formValues).every(value => value.trim() !== "");
		};

	// function that handles form change. Aids in validating whether form fields have been populated for active button
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};



	const handleSubmit = async (event) => {
		event.preventDefault();
		setShowForm(false); // This will hide the form

		// Clear the form fields
		setFormValues({
			streetAddress: "",
			localArea: "",
			city: "",
			province: "", 
			zip: "",
			country: "", 
		});
		// set uplaoding to true
		setAddressUploading(true);

		// Get form data
		const formData = new FormData(formRef.current);
		//console.log(formRef.current);

		// Validate form data
		if (!isFormValid()) {
			errorMessage("Please fill in all info required");
			return false;
		}

		const streetAddress = formData.get("streetAddress");
		const localArea = formData.get("localArea");
		const city = formData.get("city");
		const province = formData.get("province"); 
		const zip = formData.get("zip");
		const country = formData.get("country"); 

		const addressMessage = new Address();
			addressMessage.type = 0;
			addressMessage.company = "private";
			addressMessage.streetAddress = streetAddress.toString();
			addressMessage.localArea = localArea.toString();
			addressMessage.city = city.toString();
			addressMessage.zone = province.toString();
			addressMessage.country = country.toString();
			addressMessage.code = zip.toString(); 

		try {
			onAddressChange(addressMessage); 
            setSelectedAddress(addressMessage); 
			return true; // return true if successful
		} catch (error) {
			errorMessage("Failed to add address, try again");
			console.error("Failed to add address", error);
			return false; // return false if there was an error
		} finally {
			setAddressUploading(false);
		}
	};



	useEffect(() => {
		if (showForm && inputRef.current) {
			loader.load().then(() => {
				autoCompleteRef.current =
					new window.google.maps.places.Autocomplete(
						inputRef.current,
						options
					);
				autoCompleteRef.current.addListener(
					"place_changed",
					handlePlaceSelect
				);
			});
		}

		return () => {
			if (!showForm && autoCompleteRef.current) {
				// Cleanup when showForm is set to false
				google.maps.event.clearInstanceListeners(
					autoCompleteRef.current
				);
			}
		};
	}, [showForm]);

	return (
		<div className="address-selector">
		  {selectedAddress ? (
			<div className="flex flex-col ">
			  <div className="flex flex-col w-full py-2 px-4 gap-2 rounded-xl border-greyscale-600 bg-greyscale-650 relative">
				<div className="address-content flex items-start justify-between">
				  <div className="flex gap-2">
					<Marker />
	  
					<div className="flex flex-col">
					  <section className="font-inter text-left font-medium text-body leading-5 text-white ">
						{selectedAddress?.streetAddress},{" "}
						{selectedAddress?.localArea}
					  </section>
					  <section className="font-inter text-body font-regular font-normal leading-5 text-greyscale-400 mt-1">
						{selectedAddress?.city},{" "}
						{selectedAddress?.code},{" "}
						{selectedAddress?.zone}
					  </section>
					  <div className="mt-2">
						<button
						  className="font-inter text-sm font-semibold leading-5 text-teal-500 active:text-teal-800 visited:text-teal-800 bg-transparent border-none m-0 p-0"
						  onClick={() => setShowForm(!showForm)}
						>
						  Change
						</button>
					  </div>
					</div>
				  </div>
	  
				  <button
					className="bg-greyscale-650 border-none p-0 ml-2"
					onClick={() => setShowAddresses(!showAddresses)}
				  >
					<ChevronDown />
				  </button>
				</div>
			  </div>
			</div>
		  ) : (
			<div className="flex items-center justify-center">
			  <button
				className="btn btn-secondary btn-sm w-full mt-4 mb-2"
				onClick={() => setShowForm(!showForm)}
			  >
				<LightMarker className="mr-4" />
			    Add Delivery Address
			  </button>
			</div>)}
					{showForm && (
						<form
						ref={formRef}
						className="inputForm"
						onSubmit={handleSubmit}
					>
						<div className="inputDiv">
							<HouseMarker className="fieldIcon stroke-greyscale-500" />
							<input
								ref={inputRef}
								className="inputTextArea"
								type="text"
								name="streetAddress"
								placeholder="Street Address"
								value={formValues.streetAddress}
								onChange={handleChange}
							/>
						</div>
						<div className="inputDiv">
							<Map className="fieldIcon" />
							<input
								className="inputTextArea"
								type="text"
								name="localArea"
								placeholder="Suburb"
								value={formValues.localArea}
								onChange={handleChange}
							/>
						</div>
						<div className="inputDiv">
							<Building className="fieldIcon" />
							<input
								type="text"
								className="inputTextArea"
								name="city"
								placeholder="City"
								value={formValues.city}
								onChange={handleChange}
							/>
						</div>

						<div className="inputDiv">
							<ProvinceMap className="fieldIcon stroke-greyscale-500" />
						<input
								type="text"
								className="inputTextArea"
								name="province"
								placeholder="Province/State"
								value={formValues.province}
								onChange={handleChange}
								autoComplete="address-level1"
							/>
						</div>

						<div className="inputDiv">
							<Hash className="fieldIcon" />
							<input
								className="inputTextArea"
								type="text"
								name="zip"
								placeholder="Postal Code"
								required
								value={formValues.zip}
								onChange={handleChange}
							/>
						</div>
						<div className="inputDiv mb-4">
							<Globe className="fieldIcon" />
							<input
								className="inputTextArea"
								type="text"
								name="country"
								placeholder="Country"
								required
								value={formValues.country}
								onChange={handleChange}
							/>
						</div>

						<div className="flex items-center justify-center mb-4">
							<Button
								type="submit"
								text="Submit"
								size="small"
								disabled={!isFormValid()}
								color="primary"
								loading={addressUploading}
							/>
						</div>
					</form>
					)}
				</div>
	);
}