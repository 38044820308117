import {
	Artwork,
	Artwork_State,
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import Header from "components/Headers/Header";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";
import { logEvent } from "firebase/analytics";
import { useLocationAndRates } from "hooks/useLocation";
import React, { useCallback, useEffect, useState } from "react";
import { use100vh } from "react-div-100vh";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDineroPrice } from "utils/price/price";
import ArtworkPost from "../../components/ArtworkPost/ArtworkPost";
import CommentsModal from "../../components/Modals/CommentsModal/CommentModal";
import { analytics } from "../../config/firebase";
import { apiService } from "../../services/api";
import { RootState, store } from "../../store";

export default function ArtworkPage() {
	const currentUser = useSelector((state: RootState) => state.user.user);
	const cart = useSelector((state: RootState) => state.cart.cart);
	// Dispatch hook to dispatch actions to the store
	const navigate = useNavigate();
	const { rate, currency } = useLocationAndRates();
	const { artworkID } = useParams();
	const [artwork, setArtwork] = useState<Artwork>();
	const [artworkLoading, setArtworkLoading] = useState(true);
	const [sold, setSold] = useState(false);
	const [commentCount, setCommentCount] = useState(0);
	const location = useLocation();
	const [previousRoute, setPreviousRoute] = useState<string | undefined>();
	const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
	const lines = artwork?.description.split("\n");
	const storedArtwork = useSelector((state: RootState) =>
		state.feed.artworks.find(
			(artwork) => artwork.name === `artworks/${artworkID}`
		)
	);
	const vh = use100vh();

	const getArtwork = useCallback(
		async (name: string) => {
			//check if artwork is in the store
			if (storedArtwork) {
				setArtwork(storedArtwork);
				setCommentCount(Number(storedArtwork.totalCommentsNumber));
				setArtworkLoading(false);
				console.log("artwork in store");
				if (storedArtwork.state !== Artwork_State.FOR_SALE) {
					console.log("artwork is not for sale");
					setSold(true);
				}
				if (currentUser?.name === storedArtwork.artist) {
					console.log("artwork is yours");
					setSold(true);
				}
				return;
			}

			try {
				setArtworkLoading(true);
				const result = await apiService.getArtwork(name, {});
				console.log(result);
				setArtwork(result);
				setCommentCount(Number(result.totalCommentsNumber));

				console.log("getting artwork from backend");

				if (result.state !== Artwork_State.FOR_SALE) {
					console.log("artwork is not for sale");
					setSold(true);
				}
				if (currentUser?.name === result.artist) {
					console.log("artwork is yours");
					setSold(true);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setArtworkLoading(false);
			}
		},
		[currentUser?.name, storedArtwork]
	);

	useEffect(() => {
		getArtwork(`artworks/${artworkID}`);
	}, [artworkID, getArtwork]);

	const artworkPrice = () => {
		return getDineroPrice(artwork?.currentPrice, currency, rate);
	};

	const renderDescription = () => {
		if (!lines || lines.length <= 7 || isDescriptionExpanded) {
			return artwork?.description;
		} else {
			return lines.slice(0, 7).join("\n") + "...";
		}
	};

	useEffect(() => {
		setPreviousRoute(location.state?.from);
	}, [artwork]);

	return (
		<div>
			<Helmet>
				<title>{`${artwork?.title} | Artbeat`}</title>

				<meta
					name="description"
					content={`An original ${artwork?.medium} piece`}
				/>
				<meta
					property="og:title"
					content={`${artwork?.title} | Artbeat`}
				/>
				<meta
					property="og:description"
					content={"An original ${artwork?.medium} piece"}
				/>
				<meta property="og:image" content={artwork?.imageUrls[0]} />
				<meta
					property="og:url"
					content={`${process.env.REACT_APP_BASE_URL}/${artworkID}`}
				/>
			</Helmet>

			<Header
				title={artwork?.title}
				onBack={() => navigate(previousRoute || "/home")}
			/>

			<main>
				<div
					style={{
						position: "relative",
						height: `calc(${vh}px - var(--header-height))`,
						overflowY: "scroll",
						paddingTop: "4rem",
					}}
				>
					{artwork && (
						<ArtworkPost
							name={`artworks/${artworkID}`}
							commentCountProp={commentCount}
							artworkIn={artwork}
						/>
					)}

					{artworkLoading ? (
						<div className="flex flex-col justify-center h-20 ">
							{LoadingSpinner("#1EB9A5")}
						</div>
					) : (
						<>
							<div className="py-4 px-6">
								<p className="font-inter whitespace-pre-line mb-0">
									{renderDescription()}
									{lines && lines.length > 7 && (
										<span
											className="text-greyscale-450 cursor-pointer ml-1"
											onClick={() =>
												setDescriptionExpanded(
													!isDescriptionExpanded
												)
											}
										>
											{isDescriptionExpanded
												? " Show less"
												: "Show more"}
										</span>
									)}
								</p>
							</div>
							<div className="mt-2 mb-16">
								<CommentsModal
									artwork={artwork}
									commentCount={commentCount}
									onCommentCountChange={(newCount) =>
										setCommentCount(newCount)
									}
									collapseOnOutsideClick={true}
								/>
							</div>
							<div className="fixed bottom-8 w-full px-4 flex justify-center z-20">
								{currentUser?.name !== artwork?.artist && (
									<button
										className={` font-inter font-medium text-base btn btn-primary btn-md w-full ${
											sold &&
											!cart?.artworks?.includes(
												artwork?.name
											)
												? "btn-disabled"
												: ""
										}`}
										disabled={
											sold &&
											!cart?.artworks?.includes(
												artwork?.name
											)
										}
										onClick={() => {
											navigate(`/checkout/${artworkID}`);
											logEvent(
												analytics,
												"begin_checkout",
												{
													item_id: artwork.name,
													item_name: artwork.title,
													item_category:
														artwork.medium,
													item_variant: artwork.style,
													item_price:
														artwork.currentPrice,
												}
											);
										}}
									>
										{`Collect for ${artworkPrice()}`}
									</button>
								)}
							</div>
						</>
					)}
				</div>
			</main>
		</div>
	);
}
