import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { Comment } from "@hockney-app/proto/artworks/v1alpha1/comments_pb";
import { Button, Input } from "antd";
import { ReactComponent as DeleteIcon } from "assets/icons/icons8-trash.svg";
import ArtworkComment from "components/ArtworkComment/ArtworkComment";
import Avatar from "components/Avatar/Avatar";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";
import { useMessage } from "components/Messages/MessageProvider";
import { useSplashScreen } from "components/SplashScreen/UseSplashScreen";
import { useAuth } from "hooks/useAuth";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	SwipeableList,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { apiService } from "../../../services/api";
import { RootState } from "../../../store";
import CollapsedView from "./Views/CollapsedView";

interface CommentsModalProps {
	artwork: Artwork;
	commentCount: number;
	onCommentCountChange: (count: number) => void; //callback to change comment count
	collapseOnOutsideClick: boolean;
	onVisibilityChange?: (isVisible: boolean) => void;
	initialExpanded?: boolean;
}

const { TextArea } = Input;

export default function CommentsModal({
	artwork,
	commentCount,
	onCommentCountChange,
	collapseOnOutsideClick = true,
	onVisibilityChange,
	initialExpanded,
}: CommentsModalProps) {
	//user in store
	const me = useSelector((state: RootState) => state.user.user);

	const { errorMessage, successMessage } = useMessage();
	const [postingComment, setPostingComment] = useState(false);
	const [comment, setComment] = useState("");
	const [comments, setComments] = useState<Array<Comment>>([]);
	const [nextPageToken, setNextPageToken] = useState<string | undefined>(
		undefined
	);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(true);
	const [isExpanded, setIsExpanded] = useState(initialExpanded ?? false);
	const modalRef = useRef(null); // <-- create a ref for the modal
	const navigate = useNavigate();

	const { user } = useAuth();

	const handleOutsideClick = () => {
		if (collapseOnOutsideClick) {
			setIsExpanded(false); // Collapse to the collapsed view
		} else {
			onVisibilityChange && onVisibilityChange(false); // Inform parent to hide the modal
		}
	};

	//function to retrieve comments
	const fetchComments = useCallback(async () => {
		try {
			const fetchedComments = await apiService.listComments(
				artwork?.name,
				{
					pageSize: 15,
					orderBy: "create_time desc",
				}
			);
			setComments(fetchedComments.comments);
			console.log(
				"Number of fetched comments:",
				fetchedComments.comments.length
			);
			setNextPageToken(fetchedComments.nextPageToken);
			// If fetched comments are fewer than pageSize, then no more comments are left
			if (fetchedComments.comments.length < 15) {
				setHasMore(false);
			}
		} catch (error) {
			console.error(error);
			errorMessage("Issue loading comments, check connection");
		} finally {
			setIsLoading(false);
		}
	}, [artwork?.name, nextPageToken, hasMore]);

	const fetchMoreComments = async () => {
		if (hasMore) {
			try {
				console.log("Fetching More");
				const newComments = await apiService.listComments(
					artwork?.name,
					{
						pageSize: 15,
						orderBy: "create_time desc",
						pageToken: nextPageToken,
					}
				);

				console.log(newComments);
				if (newComments.comments.length === 0) {
					console.log("No more Comments");
					setHasMore(false);
				}
				if (newComments.comments.length < 15) {
					setHasMore(false);
				}
				const allComments = [...comments, ...newComments.comments];
				setComments(allComments);
				setNextPageToken(newComments.nextPageToken);
			} catch (error) {
				console.log(error);
				errorMessage("Issue loading comments, check connection");
			}
		}
	};

	//function to create comment
	const handleComment = async (newCommentValue: string) => {
		// Optimistically increase comment count
		const newCount = commentCount + 1;
		onCommentCountChange(newCount);
		console.log("New Count: ", newCount);

		const tempComment = new Comment();
		tempComment.user = me?.name ?? "";
		tempComment.comment = newCommentValue;
		tempComment.name = "temp";

		try {
			console.log(artwork.name);
			setPostingComment(true);

			const newComment = await apiService.CreateComment(
				artwork.name,
				tempComment
			);
			setComments((prevComments) => [newComment, ...prevComments]);

			console.log("posted comment");
		} catch (error) {
			// Revert the comment count due to failure
			onCommentCountChange(commentCount - 1);

			errorMessage("Failed to post comment");
			console.error(error);
		} finally {
			setPostingComment(false);
		}
	};
	//function to delete comment
	const HandleDeleteComment = async (commentName: string) => {
		// Optimistically decrease comment count
		const newCount = commentCount - 1;
		onCommentCountChange(newCount);
		console.log("New Count: ", newCount);
		try {
			console.log("commentID : ", commentName);

			await apiService.DeleteComment(commentName);
			setComments((prevComments) =>
				prevComments.filter((comment) => comment.name !== commentName)
			);

			console.log("comment deleted");
		} catch (error) {
			// Revert the comment count due to failure
			onCommentCountChange(commentCount + 1);

			errorMessage("Failed to delete comment");
			console.error(error);
		}
	};

	const TrailingComponent = ({ item }) => (
		<TrailingActions>
			<SwipeAction
				destructive={true}
				onClick={() => HandleDeleteComment(item.name)}
			>
				<div className="swipeActionDelete">
					<DeleteIcon className={"h-6 w-6"} />
				</div>
			</SwipeAction>
		</TrailingActions>
	);

	TrailingComponent.displayName = "TrailingComponent";

	TrailingComponent.propTypes = {
		item: PropTypes.shape({
			name: PropTypes.string,
			user: PropTypes.string,
		}).isRequired,
	};

	useEffect(() => {
		fetchComments();
	}, [artwork?.name]);

	const renderModalContent = () => (
		<div ref={modalRef}>
			{/* Invisible overlay div */}
			<div
				className={`fixed top-0 left-0 w-full h-full z-[99] ${
					isExpanded ? "block" : "hidden"
				}`}
				style={{ zIndex: 99 }}
				onClick={handleOutsideClick}
			/>

			{/* Only show the comments count and input when the modal is not expanded */}
			{!isExpanded && (
				<div className="mx-4">
					<CollapsedView
						onExpand={() => setIsExpanded(true)}
						commentCount={commentCount}
					/>
				</div>
			)}

			{/* Slide-up modal content */}
			{isExpanded && (
				<div className="fixed bottom-0 w-full h-3/4 translate-y-0 origin-bottom z-[1000] rounded-t-3xl bg-greyscale-700 flex flex-col">
					<div className="flex items-center justify-center w-full">
						<p className="text-white font-ubuntu text-base font-medium text-center m-4 flex items-center space-x-2">
							<span>Comments</span>
							<span className="relative before:content-['•'] text-greyscale-450"></span>
							<span className="text-greyscale-450 font-inter text-base">
								{commentCount}
							</span>
						</p>
					</div>

					<div
						className="flex-grow overflow-y-scroll pl-4"
						id="target"
					>
						{comments.length === 0 && !isLoading && (
							<div className="flex flex-col justify-center items-center h-full">
								<div className="text-white font-inter text-lg">
									No comments yet
								</div>
								<div className="text-greyscale-450 font-inter text-sm">
									Be the first.
								</div>
							</div>
						)}
						{!isLoading && (
							<InfiniteScroll
								dataLength={comments.length}
								next={fetchMoreComments}
								hasMore={hasMore}
								loader={
									<div
										className="flex justify-center items-center"
										style={{ height: "100px" }}
									>
										{LoadingSpinner("#1EB9A5")}
									</div>
								}
								scrollThreshold={0.7}
								scrollableTarget="target"
							>
								<SwipeableList threshold={0.8}>
									{comments.map((item) => (
										<SwipeableListItem
											maxSwipe={50}
											/* eslint-disable react/prop-types */
											key={item.name}
											trailingActions={
												item.user === me.name ? (
													<TrailingComponent
														item={item}
													/>
												) : null
											}
										>
											<ArtworkComment comment={item} />
										</SwipeableListItem>
									))}
								</SwipeableList>
							</InfiniteScroll>
						)}
					</div>
					<div className="relative flex py-2 px-4 items-center">
						{user?.isAnonymous ? (
							<div className="fixed bottom-0 w-full pr-4 py-4 bg-greyscale-700 z-[1000] flex items-center justify-start">
								{/* Avatar to the left */}
								<div className="flex-shrink-0">
									<Avatar user={me} className="w-8 h-8" />
								</div>
								{/* Sign up or login to comment text */}
								<div className="text-greyscale-450 font-inter text-sm mx-4 flex-grow">
									Sign up or login to comment
								</div>
								{/* Sign Up Button */}
								<Button
									type="text"
									className="font-inter text-sm font-normal leading-5 border-0 focus:ring-0 focus:border-transparent mr-4 btn btn-sm text-black bg-white"
									onClick={() => {
										navigate("/signup");
									}}
								>
									Sign Up
								</Button>
							</div>
						) : (
							<>
								{/* Avatar - Positioned to the left inside the TextArea */}
								<div className="absolute z-10 w-8 h-8 left-7 ">
									<Avatar user={me} className="w-8 h-8" />
								</div>

								<TextArea
									className="w-full rounded-lg flex-grow font-inter bg-greyscale-700 border-greyscale-600 text-sm font-normal py-3 focus:ring-0 focus:border-greyscale-600 pl-14 pr-20"
									id="commentInput"
									value={comment}
									showCount={false}
									maxLength={200}
									style={{ resize: "none" }}
									placeholder="Share your thoughts"
									autoSize={{ minRows: 1, maxRows: 5 }}
									onChange={(e) => setComment(e.target.value)}
								/>

								{/* Post Button - Positioned to the right inside the TextArea */}
								<Button
									type="text"
									className="absolute z-10 right-4 top-1/2 transform -translate-y-1/2 font-inter text-sm font-normal leading-5 border-0 focus:ring-0 focus:border-transparent"
									disabled={!comment || postingComment}
									loading={postingComment}
									onClick={() => {
										setPostingComment(true);
										handleComment(comment);
										setComment("");
									}}
								>
									{postingComment ? "Posting..." : "Post"}
								</Button>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);

	// If collapseOnOutsideClick is true, render as a regular component
	if (collapseOnOutsideClick) {
		return renderModalContent();
	}

	// If collapseOnOutsideClick is false, render inside a portal
	return ReactDOM.createPortal(renderModalContent(), document.body);
}
