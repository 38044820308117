import React from "react";
import { ReactComponent as Plus } from "../../../../assets/icons/search/plus.svg";

interface CollapsedFilterProps {
	onExpand: () => void;
}

export default function CollapsedFilter({ onExpand }: CollapsedFilterProps) {
	return (
		<button
			className={
				"flex px-2.5 py-1.5 justify-center items-center gap-1 rounded-full border border-greyscale-600 border-dashed bg-transparent"
			}
			onClick={onExpand}
		>
			<Plus />
			<section className="font-inter text-sm not-italic font-normal text-greyscale-450 flex items-center">
				Filter
			</section>
		</button>
	);
}
