import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { useMessage } from "components/Messages/MessageProvider";
import { useSplashScreen } from "components/SplashScreen/UseSplashScreen";
import { useAuth } from "hooks/useAuth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiService } from "services/api";
import { ReactComponent as HeartShape } from "../../../assets/icons/heart-rounded.svg";
import { RootState } from "../../../store";

interface LikeButtonProps {
	artwork: Artwork;
}

function LikeButton({ artwork }: LikeButtonProps) {
	const me = useSelector((state: RootState) => state.user.user);
	const [liked, setLiked] = useState(artwork.likes.includes(me?.name));
	const [noLikes, setNoLikes] = useState(artwork.likes.length);
	const { isSplashScreenOpen, toggleSplashScreen, SplashScreenComponent } =
		useSplashScreen();
	const { user } = useAuth();
	const { errorMessage } = useMessage();
	const dispatch = useDispatch();

	const likeUnlikeArtwork = async () => {
		if (user?.isAnonymous) {
			toggleSplashScreen();
		} else {
			setLiked(!liked); // Optimistically update the UI
			try {
				if (!liked) {
					await likeArtwork();
				} else {
					await unlikeArtwork();
				}
			} catch (error) {
				errorMessage("Failed to like/unlike artwork");
				setLiked(liked); // If error occurs, revert the UI change
			}
		}
	};

	const likeArtwork = async () => {
		try {
			setNoLikes(noLikes + 1);
			await apiService.likeArtwork(artwork.name);
			console.log("liked artwork");
		} catch (error) {
			setNoLikes(noLikes);
			console.log(error);
		}
	};

	const unlikeArtwork = async () => {
		try {
			if (noLikes > 0) {
				setNoLikes(noLikes - 1);
			}
			await apiService.unlikeArtwork(artwork.name);
			console.log("Unliked artwork");
		} catch (error) {
			if (noLikes > 0) {
				setNoLikes(noLikes);
			}
			console.log(error);
		}
	};

	return (
		<>
			<SplashScreenComponent />
			<button
				className="px-0 pt-4 flex items-center justify-start text-body-sm font-medium font-inter bg-transparent border-none  w-14 gap-[0.25rem]"
				onClick={likeUnlikeArtwork}
			>
				{liked ? (
					<HeartShape className="h-6 w-6 fill-red-500 stroke-red-500 " />
				) : (
					<HeartShape className="h-6 w-6  stroke-white fill-white" />
				)}
				<span>{` ${noLikes}`}</span>
			</button>
		</>
	);
}

export default LikeButton;
