import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { Cart } from "@hockney-app/proto/carts/v1alpha1/carts_pb";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { apiService } from "../../services/api";

// Slice name
const CART_SLICE = "cart";

// Slice state interface
export interface CartState {
	cart?: Cart | null;
}

// Slice initial state
const initialState: CartState = {
	cart: null,
};

export const fetchCart = createAsyncThunk("cart/fetchCart", async () => {
	const cart = await apiService.getCart();
	return cart;
});

// Create Slice
export const cartSlice = createSlice({
	name: CART_SLICE,
	initialState,
	reducers: {
		updateCart: (state, action?: PayloadAction<Cart | null>) => {
			if (action?.payload) {
				state.cart = action.payload;
				return;
			}

			state.cart = null;
		},
		addArtwork: (state, action: PayloadAction<Artwork>) => {
			state.cart.artworks = [action.payload.name, ...state.cart.artworks];
		},
		removeArtwork: (state, action: PayloadAction<Artwork>) => {
			state.cart.artworks = state.cart.artworks.filter(
				(aw) => aw !== action.payload.name
			);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCart.fulfilled, (state, action) => {
			state.cart = action.payload;
		});
		builder.addCase(fetchCart.rejected, (state) => {
			state.cart = null;
		});
	},
});

// Export actions
export const { updateCart, addArtwork, removeArtwork } = cartSlice.actions;

// Export reducer
export default cartSlice.reducer;
