import React from "react";

// PrimaryButtonProps is an interface that defines the props for the PrimaryButton component
interface PrimaryButtonProps {
	onClick: () => void;
	text: string;
}

// PrimaryButton is a component that renders a button with the primary, active styling
export const PrimaryButton = ({ onClick, text }: PrimaryButtonProps) => {
	return (
		<button
			className="flex w-[22.6875rem] btn btn-primary btn-lg "
			onClick={onClick}
		>
			{text}
		</button>
	);
};
