import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AtSign } from "../../../assets/icons/at-sign.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { ReactComponent as Lock } from "../../../assets/icons/lock-01.svg";
import { ReactComponent as Mail } from "../../../assets/icons/mail-01.svg";
import { ReactComponent as Phone } from "../../../assets/icons/phone-plus.svg";
import Header from "../../../components/Headers/Header";
import Message from "../../../components/Messages/Message";
import { authenticationService } from "../../../services/authentication";
import { RootState } from "../../../store";

export default function YourAccount() {
	// Const for error message
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [errorMessage, setErrorMessage] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [errorCount, setErrorCount] = useState(0);

	// providerID fetched from authenticationService and state set for google or facebook account
	const providerId = authenticationService.getUserProviderId();
	const isSocialLogin =
		providerId === "google.com" || providerId === "facebook.com";

	// States from store
	const currentUser = useSelector((state: RootState) => state.user.user);

	// Const for navigate
	const navigate = useNavigate();

	return (
		<div>
			{errorMessage && (
				<Message
					message={errorMessage}
					duration={4000}
					type={"error"}
					key={errorCount}
					zIndex={errorCount}
				/>
			)}
			<Header page={"/settings"} title={"Your Account"} />

			<div className="flex-col mx-3 my-4 gap-4 pt-12">
				<button
					onClick={() => {
						navigate("/settings/your_account/update_username");
					}}
					className="settings-btn-xxl"
				>
					<div className="flex gap-4 items-center">
						<AtSign className="h-6 w-6 flex-shrink-0" />
						<div className="settings-btn-content">
							<h5 className="settings-btn-title">
								Change Username
							</h5>
							<h5 className="settings-btn-description">
								@{currentUser?.username}
							</h5>
						</div>
					</div>
					<ChevronRight />
				</button>

				{!isSocialLogin && (
					<button
						onClick={() => {
							navigate("/settings/your_account/update_password");
						}}
						className="settings-btn-xxl"
					>
						<div className="flex gap-4 items-center">
							<Lock className="h-6 w-6 flex-shrink-0" />

							<div className="settings-btn-content">
								<h5 className="settings-btn-title">
									Change Password
								</h5>
								<h5 className="settings-btn-description">
									••••••••••••
								</h5>
							</div>
						</div>
						<ChevronRight />
					</button>
				)}

				<button
					className="settings-btn-xxl"
					onClick={() => {
						navigate("/settings/your_account/update_phone_number");
					}}
				>
					<div className="flex gap-4 items-center">
						<Phone className="h-6 w-6 flex-shrink-0" />
						<div className="settings-btn-content">
							<h5 className="settings-btn-title">Phone Number</h5>
							<h5 className="settings-btn-description">
								{currentUser?.contactDetails?.mobileNumber ===
								null
									? "Add"
									: currentUser?.contactDetails?.mobileNumber}
							</h5>
						</div>
					</div>
					<ChevronRight />
				</button>

				<button
					className="settings-btn-xxl"
					onClick={() => {
						navigate("/settings/your_account/update_email");
					}}
				>
					<div className="flex gap-4 items-center">
						<Mail className="h-6 w-6 flex-shrink-0" />
						<div className="settings-btn-content">
							<h5 className="settings-btn-title">Email</h5>
							<h5 className="settings-btn-description">
								{
									// Conditional: "Add" or user's email
								}
								{currentUser?.email}
							</h5>
						</div>
					</div>
					<ChevronRight />
				</button>
			</div>
		</div>
	);
}
