import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";
import Message from "./Message";
import { useLocation } from "react-router-dom"; 
import { useEffect } from "react";

const MessageContext = createContext(null);

interface MessageProviderProps {
    children: React.ReactNode;
}

export const MessageProvider: React.FC<MessageProviderProps> = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const location = useLocation();

    useEffect(() => {
        // Clear the messages whenever the location changes
        setMessages([]);
    }, [location]);

    const addMessage = (message, type) => {
        const id = Date.now();
        setMessages((prev) => [...prev, { id, message, type }]);
    };

    const removeMessage = (id) => {
        setMessages((prev) => prev.filter((m) => m.id !== id));
    };

    return (
        <MessageContext.Provider value={{ addMessage, removeMessage }}>
            {children}
            {ReactDOM.createPortal(
                messages.map((msg) => (
                    <Message
                        key={msg.id}
                        message={msg.message}
                        type={msg.type}
                        zIndex={msg.id}
                        onClose={() => removeMessage(msg.id)}
                    />
                )),
                document.getElementById("message-root")
            )}
        </MessageContext.Provider>
    );
};

export const useMessage = () => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error("useMessage must be used within a MessageProvider"); 
    }

    const { addMessage } = context;

    return {
        errorMessage: (message) => addMessage(message, "error"),
        successMessage: (message) => addMessage(message, "success"),
    };
};