import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { Button, Col, Row, Skeleton, Space } from "antd";
import { ReactComponent as ArtistSignature } from "assets/icons/signatureverified.svg";
import Avatar from "components/Avatar/Avatar";
import LikeButton from "components/Buttons/LikeButton/LikeButton";
import ShareComponent from "components/Buttons/ShareButton/ShareButton";
import { useMessage } from "components/Messages/MessageProvider";
import CommentsModal from "components/Modals/CommentsModal/CommentModal";
import { useSplashScreen } from "components/SplashScreen/UseSplashScreen";
import { useLocationAndRates } from "hooks/useLocation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDineroPrice } from "utils/price/price";
import { timeSince } from "utils/time/time";
import { ReactComponent as WidthIcon } from "../../assets/icons/chevron-selector-horizontal.svg";
import { ReactComponent as HeightIcon } from "../../assets/icons/chevron-selector-vertical.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/message-circle-01.svg";
import { ReactComponent as PalleteIcon } from "../../assets/icons/palette.svg";
import PillButton from "../../components/Buttons/PillButton";
import { artworkMediums, artworkStyles } from "../../enums/artworks";
import { apiService } from "../../services/api";
import { storageService } from "../../services/storage";
import { RootState } from "../../store";
import { updateAspectRatios } from "../../store/feed/feedSlice";
import ArtworkSignature from "../Artwork/ArtworkSignature";
import WishlistButton from "../Buttons/WishlistButton/WishListButton";
import ArtworkPostSkeleton from "./ArtworkPostSkeleton";

interface ArtworkPostProps {
	name: string;
	artworkIn?: Artwork;
	commentCountProp?: number;
}
function ArtworkPost({ name, artworkIn, commentCountProp }: ArtworkPostProps) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const me = useSelector((state: RootState) => state.user.user);
	const aspectRatio = useSelector(
		(state: RootState) => state.feed.aspectRatios[name]
	);
	const [loading, setLoading] = useState(true);
	const { currency, rate } = useLocationAndRates();
	const [artwork, setArtwork] = useState<Artwork | null>();
	const [artist, setArtist] = useState<User | null>();
	const [medium, setMedium] = useState("");
	const [imageLoaded, setImageLoaded] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [commentCount, setCommentCount] = useState(commentCountProp);
	const storedArtwork = useSelector((state: RootState) =>
		state.feed.artworks.find((artwork) => artwork.name === name)
	);
	const { errorMessage } = useMessage();

	const handleModalVisibility = (isVisible: boolean) => {
		setIsModalVisible(isVisible);
	};

	const { toggleSplashScreen, SplashScreenComponent } = useSplashScreen();

	const getArtwork = async (name: string) => {
		let fetchedArtwork: Artwork | undefined;

		// Check if the artwork is in the store
		if (storedArtwork) {
			console.log("Artwork is in store");
			fetchedArtwork = storedArtwork;
		}
		// Check if the artwork is provided as a prop
		else if (artworkIn) {
			console.log("Artwork is provided as a prop");
			fetchedArtwork = artworkIn;
		}
		// If artwork is not in the store or provided as a prop, fetch it from the backend
		else {
			try {
				console.log("Getting artwork from backend");
				setLoading(true);
				const artwork = await apiService.getArtwork(name, {});
				fetchedArtwork = artwork;
			} catch (error) {
				console.error(error);
				errorMessage("Failed to load artwork from backend");
			}
		}

		// Set the artwork and related properties
		if (fetchedArtwork) {
			setArtwork(fetchedArtwork);
		}

		// Fetch the artist details (common for all sources of artwork data)
		try {
			const artist = await apiService.getUser(fetchedArtwork?.artist, {
				readPaths: ["name", "username", "profilePictureUrl", ""],
			});
			setArtist(artist);
			const mediumName = artworkMediums.find(
				(m) => m.enum === fetchedArtwork?.medium
			)?.displayName;
			setMedium(mediumName);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false); // Set loading to false once everything is done
		}
	};

	useEffect(() => {
		if (artwork?.imageUrls?.[0]) {
			const img = new Image();
			img.src = getGoogleStorageImageUrl(artwork.imageUrls[0]);
			img.onload = () => {
				setImageLoaded(true);
			};
			img.onerror = (e) => {
				img.src = artwork.imageUrls[0];
				console.error("Image loading error:", e, img);
			};
		}
	}, [artwork]);

	useEffect(() => {
		// If aspect ratio is not in the Redux store, fetch it
		if (!aspectRatio) {
			(async () => {
				const metadata = await storageService.getImageMetadata(name);
				if (metadata.customMetadata.aspectRatio) {
					const fetchedAspectRatio = parseFloat(
						metadata.customMetadata.aspectRatio
					);
					dispatch(
						updateAspectRatios({ [name]: fetchedAspectRatio })
					);
				}
			})();
		}
	}, [name, aspectRatio, dispatch]);

	useEffect(() => {
		getArtwork(name).then((r) => r);
	}, [name]);

	const handleNavigation = () => {
		navigate(`/@/${artist?.username}`);
	};

	function getGoogleStorageImageUrl(originalUrl) {
		// Append the desired dimensions to the URL
		let modifiedUrl = originalUrl.replace("%2F1?", "%2F1_1080x1080?");
		modifiedUrl = modifiedUrl.split("&token=")[0];
		return modifiedUrl;
	}

	return (
		<>
			<SplashScreenComponent />
			{loading ? (
				<ArtworkPostSkeleton aspectRatio={aspectRatio} />
			) : artwork ? (
				<>
					{isModalVisible && (
						<CommentsModal
							artwork={artwork}
							commentCount={Number(artwork?.totalCommentsNumber)}
							onCommentCountChange={(newCount) =>
								setCommentCount(newCount)
							}
							collapseOnOutsideClick={false}
							onVisibilityChange={handleModalVisibility}
							initialExpanded={true}
						/>
					)}
					<div className="flex flex-col py-0 px-4 bg-black">
						<div className="pb-4">
							<Row align="middle" gutter={8}>
								<Col>
									{artist ? (
										<Avatar
											className={"w-11 h-11"}
											onClick={handleNavigation}
											user={artist}
										/>
									) : (
										<Skeleton.Avatar active={loading} />
									)}
								</Col>
								<Col>
									<div
										className="font-inter text-sm font-normal leading-130 mb-0.5"
										onClick={handleNavigation}
									>
										{artist?.givenName +
											" " +
											artist?.familyName}
										<ArtistSignature className="w-3 h-3 ml-1.5"></ArtistSignature>
									</div>
									<div className="font-ubuntu text-xs font-normal leading-130">
										<span className="text-greyscale-450">
											@{artist?.username ?? ""}
										</span>
										<span className="text-greyscale-450 mx-1">
											•
										</span>
										<span className="text-greyscale-450 cursor-pointer text-body-xs">
											Posted{" "}
											{timeSince(artwork.createTime)}
										</span>
									</div>
								</Col>
							</Row>
						</div>
						<div style={{ width: "100%", position: "relative" }}>
							<div
								className="artwork-post-carousel-image-wrapper"
								style={{
									position: "relative",
									width: "100%",
									paddingTop: `${100 / aspectRatio}%`, // Move this here
								}}
							>
								{!imageLoaded || loading ? (
									<div
										className="relative bg-greyscale-550 animate-pulse"
										style={{
											borderRadius: "0.75rem",
											position: "absolute",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
										}}
									></div>
								) : (
									<>
										<img
											src={getGoogleStorageImageUrl(
												artwork.imageUrls[0]
											)}
											onClick={() =>
												navigate(`/${artwork.name}`, {
													state: {
														from: location.pathname,
													},
												})
											}
											loading="lazy"
											width={"100%"}
											alt={artwork?.title}
											style={{
												borderRadius: "0.75rem",
												objectFit: "contain",
												position: "absolute",
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
											}}
										/>
										<ArtworkSignature
											artistUsername={artist?.username}
										/>
									</>
								)}
							</div>

							<div className="w-full px-1 ">
								<Row
									className="my-0 px-1"
									justify="space-between"
									align="middle"
								>
									<div className="flex items-center">
										{" "}
										{/* Replacing Space with a div */}
										<LikeButton artwork={artwork} />
										<button
											className="ml-1.5 pt-4 px-0 flex items-center justify-start text-body-sm font-medium font-inter bg-transparent border-none w-14 gap-[0.25rem]"
											onClick={() =>
												setIsModalVisible(
													!isModalVisible
												)
											}
										>
											<CommentIcon className="w-6 h-6 p-0 mb-[1px]" />
											{artwork?.totalCommentsNumber.toString()}
										</button>
									</div>
									<div className="flex items-center">
										{" "}
										{/* Replacing Space with a div */}
										<ShareComponent
											path={`${artwork.name}`}
											title={`Check out this artwork on Artbeat by ${artist?.givenName} ${artist?.familyName}`}
										/>
										<WishlistButton
											userIn={me}
											artwork={artwork}
										/>
									</div>
								</Row>

								<div className="horizontal-scroll mt-4 gap-3">
									<PillButton
										label={getDineroPrice(
											artwork?.currentPrice,
											currency,
											rate
										)}
									/>
									<PillButton
										label={medium}
										icon={
											<PalleteIcon className="mt-1 w-4 h-4" />
										}
									/>
									<PillButton
										label={`${artwork?.dimensions?.height?.toString()} cm`}
										icon={
											<HeightIcon className="mt-1 w-4 h-4" />
										}
									/>
									<PillButton
										label={`${artwork?.dimensions?.width?.toString()} cm`}
										icon={
											<WidthIcon className="mt-1 w-4 h-4" />
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
}

export default React.memo(ArtworkPost);
