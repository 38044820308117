import { useEffect } from "react";

const useScrollPosition = (key) => {
  // Save the current scroll position to localStorage
  const saveScrollPosition = () => {
    const windowScrollPosition = window.scrollY;
    localStorage.setItem(key, windowScrollPosition.toString());
  };

  // Restore the scroll position from localStorage
  const restoreScrollPosition = () => {
    const savedScrollPosition = localStorage.getItem(key);
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      console.log("scroll position restored to: " , savedScrollPosition);
      // Optionally clear the saved position after restoring
      localStorage.removeItem(key);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", saveScrollPosition);
    window.addEventListener("beforeunload", saveScrollPosition);

    // Run once when the component mounts
    restoreScrollPosition();

    return () => {
      window.removeEventListener("scroll", saveScrollPosition);
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);
};

export default useScrollPosition;
