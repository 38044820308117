import { LoadingOutlined } from "@ant-design/icons";
import { User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { Button, Spin } from "antd";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { logEvent } from "firebase/analytics";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FirebaseError } from "firebase/app";
import { authenticationService } from "../../services/authentication";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArtBeatWordMark } from "../../assets/icons/Artbeat Wordmark.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/icons/eye-off.svg";
import { ReactComponent as EyeOnIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-icon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google-icon.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock-01.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail-01.svg";
import { analytics } from "../../config/firebase";
import { apiService } from "../../services/api";
import { EmailAndPassword } from "../../services/authentication";
import { handleFirebaseError } from "../../utils/firebaseErrors";
import { useMessage } from "components/Messages/MessageProvider";

export default function LoginPage() {
	// React router hook to navigate between routes
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [loadingGoogle, setLoadingGoogle] = useState(false);
	const [loadingFacebook, setLoadingFacebook] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const { errorMessage, successMessage } = useMessage();

	const [signInForm, setSignInForm] = useState({
		email: "",
		password: "",
	});

	const handleInputChange = (event) => {
		setSignInForm({
			...signInForm,
			[event.target.name]: event.target.value,
		});
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const isFormValid = () => signInForm.email && signInForm.password;

	const handleLoginWithEmailAndPassword = async (
		values: EmailAndPassword
	) => {
		// Ensure email exists and then convert it to lowercase
		if (values.email) {
			values.email = values.email.toLowerCase();
		}

		try {
			setLoading(true);

			const result =
				await authenticationService.signInWithEmailAndPassword?.(
					values
				);

			if (
				!result ||
				!result.user ||
				!result?.user?.uid ||
				!result?.user?.email
			) {
				errorMessage("Unable to log you in, please try again.");
				return;
			}

			// If user's email is not verified, navigate to verify email page
			if (!result.user?.emailVerified) {
				navigate("/verify-email");
				return;
			}

			// If user is logged in, navigate to home page
			navigate("/home");
			logEvent(analytics, "sign_in", {
				method: "email",
			});
		} catch (error: FirebaseError | any) {
			const handledError = handleFirebaseError(error);
			errorMessage(
				handledError?.message ??
					"Unable to log you in, please try again."
			);
		} finally {
			setLoading(false);
		}
	};

	const handleLoginWithGoogle = async () => {
		try {
			setLoadingGoogle(true);

			// Authenticate user with Google and then create a user in our database
			const result = await authenticationService.signInWithGoogle?.();

			if (
				!result ||
				!result.user ||
				!result?.user?.uid ||
				!result?.user?.email
			) {
				errorMessage("Unable to log you in");
				return;
			}

			let onboarded = false;
			try {
				const hockneyUser = await apiService.getUser(
					`users/${result.user.uid}`,
					{
						readPaths: ["role"],
					}
				);
				if (hockneyUser.role !== User_Role.ROLE_UNSPECIFIED) {
					onboarded = true;
				}
			} catch (error) {
				console.error(error);
			}

			if (!onboarded) {
				navigate("/onboarding");
				return;
			}

			// If user is logged in, navigate to home page
			navigate("/home");
			logEvent(analytics, "sign_in", {
				method: "Google",
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: FirebaseError | any) {
			const handledError = handleFirebaseError(error);
			errorMessage(
				handledError?.message ??
					"Unable to log you in, please try again."
			);
			if (error.code === "auth/internal-error") {
				await new Promise((resolve) => setTimeout(resolve, 2000));
				window.open(
					"https://form.jotform.com/232124002345035",
					"_self"
				);
			}
		} finally {
			setLoadingGoogle(false);
		}
	};

	const handleLoginWithFacebook = async () => {
		try {
			setLoadingFacebook(true);

			// Authenticate user with Google and then create a user in our database
			const result = await authenticationService.signInWithFacebook?.();

			if (
				!result ||
				!result.user ||
				!result?.user?.uid ||
				!result?.user?.email
			) {
				errorMessage("Unable to log you in with Facebook, try again");
				return;
			}

			let onboarded = false;
			try {
				const hockneyUser = await apiService.getUser(
					`users/${result.user.uid}`,
					{
						readPaths: ["role"],
					}
				);
				if (hockneyUser.role !== User_Role.ROLE_UNSPECIFIED) {
					onboarded = true;
				}
			} catch (error) {
				console.error(error);
			}

			if (!onboarded) {
				navigate("/onboarding");
				return;
			}

			// If user is logged in, navigate to home page

			navigate("/home");
			logEvent(analytics, "sign_in", {
				method: "Facebook",
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: FirebaseError | any) {
			const handledError = handleFirebaseError(error);
			errorMessage(
				handledError?.message ??
					"Unable to log you in, please try again."
			);
			if (error.code === "auth/internal-error") {
				await new Promise((resolve) => setTimeout(resolve, 2000));
				window.open(
					"https://form.jotform.com/232124002345035",
					"_self"
				);
			}
		} finally {
			setLoadingFacebook(false);
		}
	};

	useEffect(() => {
		document.title = "Login | Artbeat ";
	}, []);

	const handleSubmitForm = (event) => {
		event.preventDefault(); // prevent form's default behavior
		if (isFormValid()) {
			handleLoginWithEmailAndPassword(signInForm);
		}
	};

	const LoadingSpinner = (color: string) => (
		<Spin indicator={<LoadingOutlined spin />} style={{ color: color }} />
	);

	return (
		<div className="w-full h-full flex flex-col justify-center  bg-black overflow-y-hidden overflow-x-hidden ">
			<div className="w-screen flex justify-center items-center mt-30 mb-8">
				<ArtBeatWordMark></ArtBeatWordMark>
			</div>

			<div className="relative w-full">
				<form onSubmit={handleSubmitForm} className="inputForm mx-4">
					<div className="inputDiv">
						<MailIcon className="fieldIcon" />
						<input
							id="email"
							name="email"
							type="email"
							placeholder="Email Address"
							className="inputTextArea"
							onChange={handleInputChange}
							autoComplete="email"
						/>
					</div>

					<div className="inputDiv">
						<LockIcon className="fieldIcon" />
						<input
							id="password"
							name="password"
							type={showPassword ? "text" : "password"}
							placeholder="Enter Password"
							className="inputTextArea"
							onChange={handleInputChange}
							autoComplete="current-password"
						/>
						{showPassword ? (
							<EyeOffIcon
								onClick={togglePasswordVisibility}
								className="absolute right-7 top-3 cursor-pointer"
							/>
						) : (
							<EyeOnIcon
								onClick={togglePasswordVisibility}
								className="absolute right-7 top-3 cursor-pointer"
							/>
						)}
					</div>

					<div className="flex items-center justify-center">
						<button
							type="submit"
							className={` ${
								isFormValid()
									? "btn-primary active:bg-teal-600"
									: " btn-disabled "
							} py-2.5 px-4 mt-4 w-full rounded-full h-10 text-sm font-inter font-semibold `}
							disabled={!isFormValid()}
						>
							{loading ? LoadingSpinner("white") : "Log In"}
						</button>
					</div>
				</form>
			</div>

			<text className="text-center mt-4 mb-4 font-inter text-greyscale-500">
				{" "}
				OR{" "}
			</text>

			<div className="relative w-full pl-4 pr-4">
				<button
					className="flex items-center justify-center py-2.5 px-4 w-full rounded-full h-10 text-sm font-inter font-semibold border-none bg-white text-gray-700 active:bg-gray-300"
					onClick={handleLoginWithGoogle}
				>
					<GoogleIcon className="w-6 h-6 mr-2" />
					{loadingGoogle
						? LoadingSpinner("black")
						: "Log in With Google"}
				</button>
			</div>

			{/* <div className="relative w-full pl-4 pr-4 mt-2 h-11">
				<button
					className="flex items-center justify-center py-2.5 px-4 w-full rounded-full h-10 text-sm font-inter font-semibold border-none bg-white text-gray-700 active:bg-gray-300"
					onClick={handleLoginWithFacebook}
				>
					<FacebookIcon className=" w-6 h-6 mr-2" />
					{loadingFacebook
						? LoadingSpinner("black")
						: "Log in With Facebook"}
				</button>
			</div> */}

			<div className="relative w-full flex justify-center items-center mt-4">
				<span className="text-greyscale-500 font-inter font-regular">
					Don&rsquo;t have an account?
				</span>
				<Button
					className="text-teal-500 font-inter font-semibold active:teal-700"
					type="link"
					onClick={() => {
						navigate("/signup");
					}}
				>
					Sign Up
				</Button>
			</div>

			<div className="relative w-full flex justify-center mt-4">
				<Button
					className="text-greyscale-500 font-inter font-semibold"
					type="link"
					onClick={async () => {
						if (!signInForm.email) {
							errorMessage(
								"Please enter your email address on this page to reset your password"
							);
							return;
						}
						try {
							await authenticationService.sendPasswordResetEmail(
								signInForm.email
							);
							successMessage(
								"Password reset email sent to your inbox"
							);
						} catch (error) {
							errorMessage(handleFirebaseError(error).message);
						}
					}}
				>
					Forgot password?
				</Button>
			</div>
		</div>
	);
}
