import { SettingOutlined } from "@ant-design/icons";
import {ReactComponent as ChevronLeft} from "assets/icons/chevron-left.svg" ;  
import { User, User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { Button} from "antd";
import UserListItem from "pages/Network/Components/UserListItem";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MarkerIcon } from "../../../assets/icons/marker-pin-04.svg";
import { ReactComponent as SignatureVerified } from "../../../assets/icons/signatureverified.svg";
import PillButton from "../../../components/Buttons/PillButton";
import LoadingSpinner from "../../../components/LoadingSpinner/loadingSpinner";
import { apiService } from "../../../services/api";
import { RootState } from "../../../store";
import { useMessage } from "components/Messages/MessageProvider";
import { useSplashScreen } from "components/SplashScreen/UseSplashScreen";
import { useAuth } from "hooks/useAuth";
import ShareComponent from "components/Buttons/ShareButton/ShareButton";


interface ProfileHeaderProps {
	userIn: User;
	followTab: string | null; // Can be null when no tab is active
	setFollowTab: React.Dispatch<React.SetStateAction<string | null>>; // The setter function for activeTab
}

export default function ProfileHeader({
	userIn,
	followTab,
	setFollowTab,
}: ProfileHeaderProps) {
	// Currently logged in user
	const me = useSelector((state: RootState) => state.user.user);
	const { user } = useAuth();  
	const {errorMessage} = useMessage();  
	const navigate = useNavigate();
	const IMAGE_PLACEHOLDER = "https://via.placeholder.com/150";
	const currentUser = useSelector((state: RootState) => state.user.user);
	const isCurrentUser = userIn?.name === currentUser?.name;
	const hasAddress = userIn?.addresses?.length > 0;
	const { toggleSplashScreen, SplashScreenComponent } = useSplashScreen();  
	const [userFollowingUpdating, setUserFollowingUpdating] = useState(false);
	const [isFollowing, setIsFollowing] = useState(false);
	const [isBioExpanded, setIsBioExpanded] = useState(false);
	const [followersCount, setFollowersCount] = useState(
		userIn?.followers?.length || 0
	);
	const MAX_BIO_LENGTH = 160; 

	const followUser = async (name: string) => {
		if (user.isAnonymous){
			toggleSplashScreen();
			return;   
		}  
		try {
			// Optimistically update the followers count.
			setUserFollowingUpdating(true);
			setFollowersCount((prevCount) => prevCount + 1);

			await apiService.followUser(name ?? "");
			setIsFollowing(true);
		} catch (error) {
			// Potentially provide more specific error messages based on the error.
			errorMessage("There was a problem following the user");

			// Revert the followers count on error.
			setFollowersCount((prevCount) => prevCount - 1);
		} finally {
			setUserFollowingUpdating(false);
		}
	};

	const unfollowUser = async (name: string) => {
		try {
			// Optimistically update the followers count.
			setUserFollowingUpdating(true);
			setFollowersCount((prevCount) => prevCount - 1);

			await apiService.unfollowUser(name ?? "");
			setIsFollowing(false);
		} catch (error) {
			// Potentially provide more specific error messages based on the error.
			errorMessage("There was a problem unfollowing the user");

			// Revert the followers count on error.
			setFollowersCount((prevCount) => prevCount + 1);
		} finally {
			setUserFollowingUpdating(false);
		}
	};

	const renderBio = () => {
		const bio = userIn?.bio;
		if (!bio) return null;
	
		
		if (bio.length > MAX_BIO_LENGTH || (bio.match(/\n/g) || []).length > 2) {
			if (isBioExpanded) {
				return bio; // Show full bio
			} else {
				// Show truncated bio
				// Find the position to truncate: either at the MAX_BIO_LENGTH or the end of the third line
				let truncateAt = bio.slice(0, MAX_BIO_LENGTH).lastIndexOf("\n");
				truncateAt = truncateAt > 0 ? truncateAt : MAX_BIO_LENGTH;
				return bio.slice(0, truncateAt) + "...";
			}
		}
		return bio; // Show bio as is if it"s short
	};

	useEffect(() => {
		if (user) {
			setFollowersCount(userIn?.followers.length);
			setIsFollowing(userIn?.followers.includes(me.name));
		}
	}, [user]);

	return (
		<>
		<SplashScreenComponent/> 
			<div
				className="absolute top-0 w-full p-4 z-10 bg-transparent overflow-y-scroll"
				id="target"
			>
				<div className="flex justify-between w-full">
					<Button
						type="text"
						shape="circle"
						icon={<ChevronLeft />}
						onClick={() => history.back()}
						className="text-center rounded-full bg-greyscale-700 bg-opacity-60 backdrop-blur-md"
					/>

					{isCurrentUser && (
						<Button
							type="text"
							shape="circle"
							icon={<SettingOutlined />}
							onClick={() => navigate("/settings")}
							className="text-center rounded-full bg-greyscale-700 bg-opacity-60 backdrop-blur-md"
						/>
					)}
				</div>
			</div>
			<div className="h-80 w-full relative flex-shrink-0">
				<img
					src={userIn?.profilePictureUrl || IMAGE_PLACEHOLDER}
					alt="Profile Picture"
					className="absolute top-0 left-0 w-full h-full object-cover"
				/>
				<div
					className="absolute top-0 left-0 w-full h-full"
					style={{
						backgroundImage:
							"linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70%, #000 100%)",
					}}
				></div>
				<div className="flex align-start absolute bottom-0 left-4 flex-col">
					<div className="flex items-center">
						<span className="text-white text-base font-medium font-inter">
							{userIn?.givenName} {userIn?.familyName}
						</span>
						{userIn?.role === User_Role.ARTIST && (
							<SignatureVerified className="text-white h-3.5 w-3.5 ml-1.5" />
						)}
						<div className="ml-4"> {/* 2 rem margin-left */}
							{/* Replace with your ShareButton component */}
							<ShareComponent
								title={`Check out ${userIn?.givenName} ${userIn?.familyName}'s profile on Artbeat`}
								path={`@/${userIn.username}`}
							/> 
						</div>
					</div>
					<span className="text-greyscale-450 text-h5">
						@{userIn?.username}
					</span>
				</div>

				{isCurrentUser ? (
					<>
						<button
							className="px-10 absolute bottom-0 right-4 btn btn-xs btn-primary"
							onClick={() => {
								navigate("/editProfile");
							}}
						>
							Edit Profile
						</button>
					</>
				) : (
					<>
						{isFollowing ? (
							<button
								className=" text-sm font-medium py-1.5 font-inter absolute bottom-0 right-4 btn btn-sm btn-secondary"
								disabled={userFollowingUpdating || !user}
								onClick={() => {
									unfollowUser(userIn?.name);
								}}
							>
								{userFollowingUpdating
									? LoadingSpinner("white")
									: "following"}
							</button>
						) : (
							<button
								className=" text-sm font-medium  px-3.5 py-1.5 font-inter absolute bottom-0 right-4 btn btn-primary"
								disabled={userFollowingUpdating || !user}
								onClick={() => {
									console.log("clicked button"); 
									
									followUser(userIn?.name);
								}}
							>
								{userFollowingUpdating
									? LoadingSpinner("white")
									: "Follow"}
							</button>
						)}
					</>
				)}
			</div>
			<div className="mt-4 flex flex-col items-start justify-start px-4">
				{hasAddress && (
					<div className="flex items-center mb-2 text-greyscale-450 font-inter text-body-sm">
						<MarkerIcon className="mr-1 align-middle" />
						{userIn?.addresses[0]?.city} ,{" "}
						{userIn?.addresses[0]?.country}
					</div>
				)}
				<span className="text-sm font-inter font-normal whitespace-pre-line">
					{renderBio()}
					{!isBioExpanded && userIn?.bio.split("\n").length > 3 && (
						<span
							className="text-greyscale-450 cursor-pointer ml-1"
							onClick={() => setIsBioExpanded(true)}
						>
							Show more
						</span>
					)}
					{isBioExpanded && (
						<span
							className="text-greyscale-450 cursor-pointer ml-2"
							onClick={() => setIsBioExpanded(false)}
						>
							Show less
						</span>
					)}
				</span>

				<div className="flex mt-4 mb-2 space-x-2">
					<PillButton
						className="text-sm font-inter font-normal py-0.5"
						label={`${followersCount} Followers`}
						onClick={() => {
							setFollowTab("followers");
							navigate(`/@/${userIn.username}/network/followers`);
						}}
					/>
					<PillButton
						className="text-sm font-inter py-0.5 font-normal"
						label={` ${userIn?.following?.length ?? 0} Following`}
						onClick={() => {
							setFollowTab("following");
							navigate(`/@/${userIn.username}/network/following`);
						}}
					/>
				</div>
			</div>
		</>
	);
}
