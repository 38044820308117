import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Mail } from "assets/icons/mail-01.svg";
import Button from "../../../../components/Buttons/Button";
import Header from "../../../../components/Headers/Header";
import { useMessage } from "components/Messages/MessageProvider";
import { apiService } from "../../../../services/api";
import { RootState } from "../../../../store";
import { setUser, setUserSuccess } from "../../../../store/user/userSlice";

interface UpdateEmailFormValues {
	email: string;
}

export default function UpdateEmail() {
	const [formValues, setFormValues] = useState<UpdateEmailFormValues>({
		email: "",
	});
	const [emailValid, setEmailValid] = useState<boolean | null>(null);

	// states for buttons
	const [emailUploading, setEmailUploading] = useState(false);

	const { errorMessage, successMessage } = useMessage();

	// States from store
	const dispatch = useDispatch();
	const currentUser = useSelector((state: RootState) => state.user.user);

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		// Check if the new mobile number is the same as the current one
		if (value === currentUser?.email) {
			errorMessage("The new email is the same as the current one.");
		} else {
			errorMessage(""); // Clear the error message if the numbers are not the same
		}

		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	// Checks if input field is valid, which renders the primary active button
	const isFormValid = () => {
		return (
			formValues.email.trim() !== "" &&
			formValues.email !== currentUser?.email &&
			emailValid
		);
	};

	// handles submit of confirm new username. Makes update user call
	const handleUpdateEmail = async (e) => {
		e.preventDefault();
		try {
			// setEmailUploading to true
			setEmailUploading(true);

			const updateUser = new User();
			updateUser.name = currentUser?.name;
			updateUser.email = formValues.email;

			const updatedUser = await apiService.updateUser(updateUser, [
				"email",
			]);

			// Dispatch update to the store with new email
			dispatch(setUserSuccess(updatedUser));

			console.log("New Email: ", updatedUser.email);
		} catch (error) {
			errorMessage("There was an error checking your email");
			console.error(error);
		} finally {
			setEmailUploading(false);
		}
	};

	// Handles email change, and whether email is available or not
	const onChangeEmail = async (e: ChangeEvent<HTMLInputElement>) => {
		setEmailValid(null);
		try {
			const res = await apiService.listUsers({
				pageSize: 1,
				filter: `email = '${e.target.value}'`,
			});

			if (
				res.users.length === 0 ||
				res.users[0].email === currentUser?.email
			) {
				setEmailValid(true);
				return;
			}

			setEmailValid(false);
			console.log("Listed User: ", res);
		} catch (error) {
			errorMessage("There was an error checking email");
			console.error(error);
		}
	};

	return (
		<>
			<Header title={"Change Email"} page={"/settings/your_account"} />
			<div className="flex-col items-center gap-4 my-4 pt-12">
				<div className="flex-col px-4 items-start gap-4 self-stretch">
					<h5 className="font-ubuntu not-italic font-medium my-2 text-sm">
						Current
					</h5>
					<h4 className="font-inter not-italic font-light text-greyscale-150 text-base my-2">
						{currentUser?.email}
					</h4>
				</div>

				<div className="flex-col items-start gap-4 self-stretch text-sm ">
					<h5 className="px-4 font-ubuntu not-italic text-sm font-medium my-2">
						New
					</h5>

					<form
						onSubmit={handleUpdateEmail}
						className="inputForm mx-4"
					>
						<div className="inputDiv">
							<Mail className="w-5 h-5 fieldIcon" />
							<input
								id="email"
								name="email"
								type="email"
								required
								className="inputTextArea"
								placeholder="New Email"
								onChange={(e) => {
									handleEmailChange(e);
									onChangeEmail(e);
								}}
							/>
							<div className="mt-2">
								{emailValid === false && formValues.email && (
									<span className="text-red-500">
										Email is already taken
									</span>
								)}
								{emailValid === true && formValues.email && (
									<span className="text-green-500">
										Email is available
									</span>
								)}
							</div>
						</div>

						<div className="flex items-center my-8">
							<Button
								type="submit"
								text="Confirm New Email"
								size="small"
								disabled={!isFormValid()}
								loading={emailUploading}
								color="primary"
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
