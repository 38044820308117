import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Slice name
const FEED_SLICE = "feed";

// Slice state interface
export interface FeedState {
	artworks: Artwork[];
	feedNextPageToken: string | null; 
	aspectRatios: { [key: string]: number }; 
	topArtworkName: string | null; 
}

// Slice initial state
const initialState: FeedState = {
	artworks: [],
	feedNextPageToken: null, 
	aspectRatios: {}, 
	topArtworkName: null, 
};

// Create Slice
export const feedSlice = createSlice({
	name: FEED_SLICE,
	initialState,
	reducers: {
		setArtworks: (state, action: PayloadAction<Artwork[]>) => {
			state.artworks = action.payload;
		},
		addArtwork: (state, action: PayloadAction<Artwork>) => {
			state.artworks = [action.payload, ...state.artworks];
		},
		removeArtwork: (state, action: PayloadAction<string>) => {
			state.artworks = state.artworks.filter(
				(artwork) => artwork.name !== action.payload
			);
		},
		clearArtworks: (state) => {
			state.artworks = [];
		},

		//used to prepend newer posted artworks to top of artworks array 
		prependArtworks: (state, action: PayloadAction<Artwork[]>) => {
            state.artworks = [...action.payload, ...state.artworks];
        },
		
		setfeedNextPageToken: (state, action: PayloadAction<string | null>) => {
			state.feedNextPageToken = action.payload;
		},
		clearfeedNextPageToken: (state) => {
			state.feedNextPageToken = null;
		},

		setAspectRatios: (state, action: PayloadAction<{ [key: string]: number }>) => {
			state.aspectRatios = action.payload;
		},
		updateAspectRatios: (state, action: PayloadAction<{ [key: string]: number }>) => {
			state.aspectRatios = { ...state.aspectRatios, ...action.payload };
		}, 

		deleteAspectRatio: (state, action: PayloadAction<string>) => {
			// Delete the aspect ratio for the provided artwork name
			delete state.aspectRatios[action.payload];
		}, 

		setTopArtworkName: (state, action: PayloadAction<string | null>) => {
			state.topArtworkName = action.payload;
		} 

	},
});

// Export actions
export const {  prependArtworks, setArtworks, addArtwork, removeArtwork, clearArtworks, setfeedNextPageToken , clearfeedNextPageToken, setAspectRatios , updateAspectRatios, deleteAspectRatio, setTopArtworkName } =
	feedSlice.actions;

// Export reducer
export default feedSlice.reducer;
