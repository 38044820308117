import { Button } from "antd";
import { useMessage } from "components/Messages/MessageProvider";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MailCircleIcon } from "../../assets/icons/mail-circle.svg";
import { useAuth } from "../../hooks/useAuth";
import { authenticationService } from "../../services/authentication";

export default function EmailVerifiedPage() {
	return (
		<div className="h-[600px] w-full flex flex-col justify-center items-center overflow-y-hidden p-8 text-center">
			<h1>Your email is verified!</h1>
			<p>Please return to the app to continue your onboarding.</p>
		</div>
	);
}
