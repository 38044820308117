import React from "react";

interface ArtworkPostSkeletonProps {
	aspectRatio?: number;
	loading?: boolean;
}

const ArtworkPostSkeleton: React.FC<ArtworkPostSkeletonProps> = ({
	aspectRatio = 1,
}) => {
	return (
		<div className="flex flex-col gap-2 p-4 width-100">
			{/* Artist Info */}
			<div className="flex gap-2 items-center">
				<div className="bg-greyscale-550 rounded-full w-12 h-12 animate-pulse"></div>
				<div className="flex flex-col gap-1">
					<div className="bg-greyscale-550 w-24 h-4 animate-pulse mb-1 rounded-full"></div>
					<div className="bg-greyscale-550 w-40 h-3 animate-pulse rounded-full"></div>
				</div>
			</div>

			{/* Image Carousel */}
			<div
				className="relative bg-greyscale-550 animate-pulse"
				style={{
					paddingTop: `${100 / aspectRatio}%`,
					borderRadius: "0.75rem",
				}}
			></div>

			{/* Bottom Action Buttons and Artwork Details */}
			<div className="mt-3">
				<div className="flex justify-between items-center">
					<div className="flex gap-2">
						<div className="bg-greyscale-550 w-14 h-6 rounded-full animate-pulse"></div>
						<div className="bg-greyscale-550 w-14 h-6 rounded-full animate-pulse"></div>
					</div>
					<div className="flex gap-2">
					<div className="bg-greyscale-550 w-10 h-6 rounded-full animate-pulse"></div>
					<div className="bg-greyscale-550 w-10 h-6 rounded-full animate-pulse"></div>
					</div>  
				</div>

				<div className="flex gap-3 mt-3">
					<div className="bg-greyscale-550 w-24 h-6 rounded-full animate-pulse"></div>
					<div className="bg-greyscale-550 w-24 h-6 rounded-full animate-pulse"></div>
					<div className="bg-greyscale-550 w-16 h-6 rounded-full animate-pulse"></div>
					<div className="bg-greyscale-550 w-16 h-6 rounded-full animate-pulse"></div>
				</div>
			</div>
		</div>
	);
};

export default ArtworkPostSkeleton;
