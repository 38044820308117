import React from "react";

// Button displayed once an option is selected from a dropdown menue.

interface InfoSelectButtonProps {
	selectedOption: string;
	iconComponent: React.ReactNode;
}

export const InfoSelectButton: React.FC<InfoSelectButtonProps> = ({
	selectedOption,
	iconComponent,
}) => {
	return (
		<div className="InputState">
			{iconComponent}
			<section className="flex font-inter text-base font-normal not-italic leading-5 text-teal-100 flex-1 flex-shrink-0">
				{selectedOption}
			</section>
		</div>
	);
};
