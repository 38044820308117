import { Contact } from "@hockney-app/proto/types/v1alpha1/contact_details_pb";
import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Phone } from "../../../../assets/icons/phone-plus.svg";
import Button from "../../../../components/Buttons/Button";
import Header from "../../../../components/Headers/Header";
import InputField from "../../../../components/Inputs/InputField";
import Message from "../../../../components/Messages/Message";
import { apiService } from "../../../../services/api";
import { RootState } from "../../../../store";
import { setUser, setUserSuccess } from "../../../../store/user/userSlice";

interface UpdatePhoneNumberFormValues {
	mobileNumber: string;
}

export default function UpdatePhoneNumber() {
	const [formValues, setFormValues] = useState<UpdatePhoneNumberFormValues>({
		mobileNumber: "",
	});

	// Const for error message
	const [errorMessage, setErrorMessage] = useState("");
	const [errorCount, setErrorCount] = useState(0);

	// states for button
	const [numberUploading, setNumberUploading] = useState(false);

	// States from store
	const dispatch = useDispatch();
	const currentUser = useSelector((state: RootState) => state.user.user);

	const isValidSAPhoneNumber = (phoneNumber: string) => {
		// Matches either +27 followed by 9 digits OR 0 followed by 9 digits
		const regex =
			/^(\+27|27|0)[0-9]{2}( |-)?[0-9]{3}( |-)?[0-9]{4}( |-)?(x[0-9]+)?(ext[0-9]+)?$/;
		return regex.test(phoneNumber);
	};

	const handlePhoneNumberChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const { name, value } = e.target;

		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	// Checks if input field is valid, which renders the primary active button
	const isFormValid = () => {
		const length = formValues.mobileNumber.trim().length;
		const regex = /^(\+27|27|0)[0-9]{2}( |-)?[0-9]{3}( |-)?[0-9]{4}$/;

		return (
			formValues.mobileNumber.trim() !== "" &&
			regex.test(formValues.mobileNumber) && // Add this line
			formValues.mobileNumber !==
				currentUser?.contactDetails?.mobileNumber
		);
	};

	const handleUpdatePhoneNumber = async (e) => {
		e.preventDefault();
		const validNumber = isValidSAPhoneNumber(formValues.mobileNumber);
		if (
			formValues.mobileNumber ===
			currentUser?.contactDetails?.mobileNumber
		) {
			setErrorMessage(
				"The new mobile number is the same as the current one."
			);
		} else if (!validNumber) {
			setErrorMessage(
				"Please enter a valid South African mobile number."
			);
		} else {
			try {
				setNumberUploading(true);
				const newContactDetails = new Contact();
				newContactDetails.name =
					currentUser.givenName + " " + currentUser.familyName;
				newContactDetails.email = currentUser.email;
				newContactDetails.mobileNumber = formValues.mobileNumber;

				let updatedUser = new User();
				updatedUser.name = currentUser?.name;
				updatedUser.contactDetails = newContactDetails;

				updatedUser = await apiService.updateUser(updatedUser, [
					"contact_details",
				]);

				// //tells store to update the cached user
				dispatch(setUserSuccess(updatedUser));
			} catch (error) {
				setErrorMessage(
					"There was an error updating your mobile number, please try again"
				);
				setErrorCount((count) => count + 1);
				console.error(error);
			} finally {
				setNumberUploading(false);
			}
		}
	};

	return (
		<>
			{errorMessage && (
				<Message
					message={errorMessage}
					duration={4000}
					type={"error"}
					key={errorCount}
					zIndex={errorCount}
				/>
			)}
			<Header
				title={"Add Phone Number"}
				page={"/settings/your_account"}
			/>
			<div className="flex-col items-center gap-4 my-4 pt-12">
				<div className="flex-col px-4 items-start gap-4 self-stretch">
					<h5 className="font-ubuntu not-italic font-medium my-2 text-sm">
						Current
					</h5>
					<h4 className="font-inter not-italic font-light text-greyscale-150 my-2 text-base ">
						{currentUser?.contactDetails?.mobileNumber}
					</h4>
				</div>

				<div className="flex-col items-start gap-4 self-stretch ">
					<h5 className="px-4 font-ubuntu not-italic font-medium text-sm my-2">
						New
					</h5>

					<form
						onSubmit={handleUpdatePhoneNumber}
						className="inputForm mx-4"
					>
						<InputField
							Icon={Phone}
							inputProps={{
								id: "mobileNumber",
								name: "mobileNumber",
								title: "Please enter a valid 10-digit South African mobile number.",
								type: "text",
								placeholder: "0123456789",
								onChange: (e) => {
									handlePhoneNumberChange(e);
								},
							}}
						/>

						<div className="flex items-center my-8">
							<Button
								type="submit"
								color="primary"
								text="Confirm New Phone Number"
								size="small"
								loading={numberUploading}
								disabled={!isFormValid()}
							></Button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
