import { FirebaseApp } from "firebase/app";
import {
	FirebaseStorage,
	getDownloadURL,
	getStorage,
	ref,
	getMetadata,
	uploadBytesResumable,
} from "firebase/storage";

import { app } from "../../config/firebase";

class StorageService {
	private storage: FirebaseStorage;

	constructor(app: FirebaseApp) {
		this.storage = getStorage(app);
	}

	uploadFile(file: Uint8Array, path: string, metadata = {}) {
		const storageRef = ref(this.storage, path);
		return uploadBytesResumable(storageRef, file, {
		  contentType: "image/jpeg",
		  ...metadata
		});
	}

	async getURL(path: string) {
		const storageRef = ref(
			this.storage,
			`gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${path}`
		);
		
		const metadata = await getMetadata(storageRef);
		console.log(metadata);
		
		return await getDownloadURL(storageRef);
	}
	async getImageMetadata(name: string) {
		// Construct the path using the name. 
		// This assumes a certain structure, so you might need to adjust it based on your actual storage structure.
		const storagePath = `${name}/1`;
	
		const storageRef = ref(this.storage, storagePath);
		return await getMetadata(storageRef);
	}
}

// Export a singleton instance
export const storageService = new StorageService(app); 