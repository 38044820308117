import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { User, User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { ReactComponent as ArtworkIcon } from "assets/icons/Artworks Icon.svg";
import { ReactComponent as BookMarkOutline } from "assets/icons/bookmark-outline.svg";
import { ReactComponent as CollectionIcon } from "assets/icons/image-03.svg";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StackGrid, { transitions } from "react-stack-grid";
import ArtworkCard from "../../components/ArtworkCard/ArtworkCard";
import { useMessage } from "components/Messages/MessageProvider";
import { apiService } from "../../services/api";
import { RootState } from "../../store";
import PillTabs from "./Components/PillTabs";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import { Helmet } from "react-helmet"; 

export default function ProfilePage() {
	// Currently logged in user
	const currentUser = useSelector((state: RootState) => state.user.user);

	const navigate = useNavigate();

	const { username } = useParams();
	const [activeTab, setActiveTab] = useState<string>("artwork");
	const [userLoading, setUserLoading] = useState(true);
	const [artworksLoading, setArtworksLoading] = useState(false);
	const [user, setUser] = useState<User>();
	const [artworks, setArtworks] = useState<Artwork[]>([]);
	const [collection, setCollection] = useState<Artwork[]>([]);
	const [wishlist, setWishlist] = useState<Artwork[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [nextPageToken, setNextPageToken] = useState<string | undefined>(
		undefined
	);
	const {errorMessage, successMessage} = useMessage();  

	// states to render "following" and "followers"
	const [followTab, setFollowTab] = useState<
		"followers" | "following" | null
	>(null); // New state for active tab
	const [userResults, setUserResults] = useState<any[]>([]); // New state for user list

	const getUser = useCallback(async () => {
		try {
			//console.log("Getting user");
			const users = await apiService.listUsers({
				pageSize: 1,
				filter: `username = "${username}"`,
			});
			if (users.users.length === 0) {
				errorMessage("User not found");
				return;
			}

			const user = users.users[0];
			if (!user) {
				errorMessage("User not found");
				return;
			}

			setUser(user);
			//console.log(user);
			fetchUserArtworks(user?.name ?? "");
		} catch (error) {
			errorMessage("There was a problem loading user");
		} finally {
			setUserLoading(false);
		}
	}, [username]);

	//used to fetch the artworks displayed on profile if artist
	const fetchUserArtworks = useCallback(async (name: string) => {
		try {
			setArtworksLoading(true);
			const fetchedArtworks = await apiService.listArtworks({
				pageSize: 15,
				orderBy: "create_time desc",
				filter: `artist = "${name}"`,
			});

			//console.log(fetchedArtworks);

			setNextPageToken(fetchedArtworks.nextPageToken);
			const artworks = fetchedArtworks.artworks;
			setArtworks(artworks);

			if (fetchedArtworks.artworks.length < 15) {
				setHasMore(false);
			}
		} catch (error) {
			errorMessage("There was a problem loading user artwork");
		} finally {
			setArtworksLoading(false);
		}
	}, []);

	const fetchMoreUserArtworks = async (name: string) => {
		//console.log("fetching more");
		if (hasMore) {
			try {
				setArtworksLoading(true);
				const newArtworks = await apiService.listArtworks({
					readPaths: ["name"],
					pageSize: 15,
					orderBy: "create_time desc",
					pageToken: nextPageToken,
					filter: `artist = "${name}"`,
				});

				//console.log(newArtworks);

				if (newArtworks.artworks.length === 0) {
					console.log("No more artworks");
					setHasMore(false);
				}

				const allArtworks = [...artworks, ...newArtworks.artworks];
				setArtworks(allArtworks);
				setNextPageToken(newArtworks.nextPageToken);
			} catch (error) {
				errorMessage("There was a problem loading artworks");
			} finally {
				setArtworksLoading(false);
			}
		}
	};

	//used for collection and for wishlist
	const fetchArtworks = useCallback(
		async (type: "collection" | "wishlist") => {
			try {
				let names: string[] | undefined;
				let setterFunction: React.Dispatch<
					React.SetStateAction<Artwork[]>
				>;
	
				if (type === "collection") {
					names = user?.collection;
					setterFunction = setCollection;
				} else if (type === "wishlist") {
					names = user?.wishlist;
					setterFunction = setWishlist;
				} else {
					throw new Error("Invalid type provided");
				}
	
				if (!names || names.length === 0) {
					console.log(
						`${type.charAt(0).toUpperCase() + type.slice(1)} is empty or undefined`
					);
					setterFunction([]);
					return;
				}
	
				const artworks = await apiService.batchGetArtworks({
					names: names,
				});
				setterFunction(artworks.artworks);
			} catch (error) {
				errorMessage(`There was a problem loading your ${type}`);
				console.error(error);
			}
		},
		[user?.collection, user?.wishlist]
	);

	interface ArtworkGridProps {
		userName: string;
		type: "wishlist" | "collection" | "artworks";
	}

	//returned for collection and wishlist tabs
	const ArtworkGrid = ({ userName, type }: ArtworkGridProps) => {
		return (
			<div className="mb-10 scroll-none">
				<InfiniteScroll
					dataLength={
						type === "artworks"
							? artworks.length
							: type === "collection"
							? collection.length
							: wishlist.length
					} // Adjust based on type
					next={() => {
						if (type === "artworks") {
							fetchMoreUserArtworks(userName);
						} // If there's a "fetchMore" version for other types, you can add here
					}}
					hasMore={hasMore}
					scrollThreshold={0.4}
					hasChildren={false}
					loader={
						artworksLoading && (
							<div
								className="flex justify-center items-center"
								style={{ height: "100px" }}
							>
								{LoadingSpinner("#1EB9A5")}
							</div>
						)
					}
				>
					<StackGrid
						className="mb-12"
						columnWidth={"50%"}
						gutterWidth={8}
						gutterHeight={8}
						monitorImagesLoaded
						duration={0}
					>
						{(type === "artworks"
							? artworks
							: type === "collection"
							? collection
							: wishlist
						).map((artwork) => (
							<div key={artwork.name} style={{ marginTop: 0 }}>
								<ArtworkCard
									artwork={artwork}
									onClick={() => {
										navigate(`/${artwork.name}`, {
											state: { from: location.pathname },
										});
									}}
								/>
							</div>
						))}
					</StackGrid>
				</InfiniteScroll>
			</div>
		);
	};

	//returned during loading screen
	const Loading = () => {
		return (
			<div className="flex items-center justify-center h-screen w-screen flex-col">
				{LoadingSpinner("#1EB9A5")}
			</div>
		);
	};

	//returned if user doesnt exist
	const UserDoesNotExist = () => {
		return (
			<div className="flex justify-center items-center h-screen">
				<div className="flex flex-col justify-center items-center">
					<p>User does not exist</p>
					<button
						className="btn btn-secondary btn-sm mt-4"
						onClick={() => navigate(-1)}
					>
						Back
					</button>
				</div>
			</div>
		);
	};

	useEffect(() => {
		getUser();
	}, [getUser]);

	useEffect(() => {
		if (user) {
			fetchArtworks("collection");
			fetchArtworks("wishlist");
		}
	}, [user, fetchArtworks]);

	return (
		<>
			<div>
			<Helmet>
				<title>{`${user?.username} | Artbeat` }</title>
 
                <meta 
                    name="description" 
                    content={`${user?.givenName} ${user?.familyName}'s profile on Artbeat.`} 
                />
                <meta 
                    property="og:title" 
                    content={`${user?.username} | Artbeat` }
                />
                <meta 
                    property="og:description" 
					content={`${user?.givenName} ${user?.familyName}'s profile on Artbeat.`} 
                />
            
                <meta 
                    property="og:url" 
                    content={`${process.env.REACT_APP_BASE_URL}@/${user?.username}`} 
                />
            </Helmet>

				{userLoading ? (
					<Loading />
				) : user ? (
					<div className="flex flex-col w-full bg-black overflow-x-hidden">
						<ProfileHeader
							userIn={user}
							followTab={followTab}
							setFollowTab={setFollowTab}
						/>
						<div className="mx-4">
							<PillTabs
								tabs={[
									...(user?.role === User_Role.ARTIST
										? [
												{
													key: "artwork",
													label: "Artworks",
													icon: (
														<ArtworkIcon className="mt-1 w-4 h-4" />
													),
													onClick: () => {
														setActiveTab("artwork");
													},
												},
										  ]
										: []),
									{
										key: "collection",
										label: "Collection",
										icon: (
											<CollectionIcon className="mt-1 w-4 h-4" />
										),
										onClick: () => {
											setActiveTab("collection");
										},
									},
									{
										key: "wishlist",
										label: "Wishlist",
										icon: (
											<BookMarkOutline className="mt-1 w-4 h-4" />
										),
										onClick: () => {
											setActiveTab("wishlist");
										},
									},
								]}
								activeKey={activeTab}
							/>
							<div className="mt-4">
								{activeTab === "artwork" && (
									<div className="mb-10">
										<InfiniteScroll
											dataLength={artworks.length}
											next={() =>
												fetchMoreUserArtworks(
													user?.name
												)
											}
											scrollThreshold={0.4}
											hasMore={hasMore}
											loader={
												artworksLoading && (
													<div
														className="flex justify-center items-center"
														style={{
															height: "100px",
														}}
													>
														{LoadingSpinner(
															"#1EB9A5"
														)}
													</div>
												)
											}
										>
											<StackGrid
												className="mb-12"
												columnWidth={"50%"}
												gutterWidth={8}
												gutterHeight={8}
												monitorImagesLoaded
												duration={0}
											>
												{artworks.map((artwork) => (
													<div key={artwork.name}>
														<ArtworkCard
															artwork={artwork}
															onClick={() => {
																navigate(
																	`/${artwork.name}`,
																	{
																		state: {
																			from: location.pathname,
																		},
																	}
																);
															}}
														/>
													</div>
												))}
											</StackGrid>
										</InfiniteScroll>
									</div>
								)}
								{activeTab === "collection" && (
									<ArtworkGrid
										userName={user?.name}
										type="collection"
									/>
								)}
								{activeTab === "wishlist" && (
									<ArtworkGrid
										userName={user?.name}
										type="wishlist"
									/>
								)}
							</div>
						</div>
					</div>
				) : (
					<UserDoesNotExist />
				)}
			</div>
		</>
	);
}
