import { adventurer } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { Layout, theme } from "antd";
import { useAuth } from "hooks/useAuth";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "store";
import Header from "./Header";
import NavBar from "./NavBar";

const { useToken } = theme;
const { Content } = Layout;

HomeLayout.propTypes = {
	showHeader: PropTypes.bool,
};

HomeLayout.defaultProps = {
	showHeader: true,
};

export default function HomeLayout({ showHeader = true }) {
	// default to true to show the header by default
	const currentUser = useSelector((state: RootState) => state.user.user);
	const { user } = useAuth();
	const { token } = useToken();
	const [profilePicture, setProfilePicture] = useState<string>("");

	// React router hook to navigate between routes
	const navigate = useNavigate();

	// getProfilePicture is a function that gets the profile picture URL of the user
	// It first checks if the user has a profile picture URL in the database (hockneyUser)
	// If not, it checks if the user has a profile picture URL in the auth object (user)
	// If not, it generates a profile picture URL using the user's uid
	const getProfilePicture = useCallback(async () => {
		if (
			currentUser?.profilePictureUrl &&
			currentUser?.profilePictureUrl != ""
		) {
			setProfilePicture(currentUser?.profilePictureUrl);
			return;
		}

		if (user?.photoURL && user?.photoURL != "") {
			setProfilePicture(user?.photoURL);
			return;
		}

		const avatar = createAvatar(adventurer, {
			seed: user?.uid,
		});

		const avatarURI = await avatar.toDataUri();
		setProfilePicture(avatarURI);
	}, [currentUser, user]);

	useEffect(() => {
		function updateAppHeight() {
			const appHeight =
				window.innerHeight ||
				document.documentElement.clientHeight ||
				document.body.clientHeight;
			document.documentElement.style.setProperty(
				"--app-height",
				`${appHeight}px`
			);
		}

		// Run the function on initial load
		updateAppHeight();

		// Update the height when the window is resized
		window.addEventListener("resize", updateAppHeight);
		getProfilePicture();
	}, [currentUser, user, getProfilePicture]);

	return (
		<Layout
			style={{
				height: "100%",
				backgroundColor: token.colorBgBase,
			}}
			id="app-container"
		>
			{showHeader && <Header />}{" "}
			{/* Conditionally render based on prop */}
			<Content>
				<div className="h-full">
					<Outlet />
				</div>
			</Content>
			<NavBar />
		</Layout>
	);
}
