import { Comment } from "@hockney-app/proto/artworks/v1alpha1/comments_pb";
import { User, User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb"; 
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArtworkCommentSkeleton } from "./ArtworkCommentSkeleton";
import { apiService } from "../../services/api";
import { RootState } from "../../store";
import { timeSince } from "utils/time/time";
import { useMessage } from "components/Messages/MessageProvider";
import Avatar from "components/Avatar/Avatar";
import {ReactComponent as ArtistSignature} from "assets/icons/signatureverified.svg" ; 


interface ArtworkCommentProps {
	comment: Comment;
}

export default function ArtworkComment({ comment }: ArtworkCommentProps) {
    const navigate = useNavigate();
    const me = useSelector((state: RootState) => state.user.user);
    const [author, setAuthor] = useState<User | null>();
    const [loading, setLoading] = useState<boolean>(true); 
    const {errorMessage, successMessage} = useMessage();  



    const getUser = useCallback(async (name: string) => {
        try {
            const user = await apiService.getUser(name, {
                readPaths: ["name", "username", "profilePictureUrl"],
            });
            setAuthor(user);
        } catch (error) {
            console.error(error);
            errorMessage("Could not load comment author"); 
        }
        finally {
            setLoading(false);  
        }
    }, []);

   
    

    useEffect(() => {
        getUser(comment.user);
    }, [getUser, comment.user]);

    return (
        <>

            {loading ? (
                <ArtworkCommentSkeleton isLoading={loading}/> 
            ) : (
                <div className="bg-greyscale-700 flex items-center rounded-xl pr-4">
                    {/* Avatar */}
                    <div className="relative mr-2 flex-shrink-0 self-center">
                        <Avatar
                            user={author}
                            className="absolute top-0 left-0 w-11 h-11 "
                             onClick={() => {
                                    navigate({
                                        pathname: `/@/${author?.username}`,
                                    });}
                                 } 
                        />
                    </div>
            
                    {/* Content */}
                    <div className="flex flex-col justify-center ml-2 max-w-[calc(100%-1rem)]">
                        <div className="flex items-center mt-3 mb-1">
                            <span 
                                onClick={() => {
                                    navigate({
                                        pathname: `/@/${author?.username}`,
                                    });
                                }}
                                className="text-greyscale-450 cursor-pointer text-body-xs font-ubuntu font-normal"
                            >
                                @{author?.username}
                            </span>

                             {/* Artist Signature */}
                                {author?.role === User_Role.ARTIST && (
                                    <ArtistSignature className="ml-1 w-3 h-3" />
                                )}

                            <span className="text-greyscale-450 mx-1 cursor-pointer text-body-xs font-ubuntu font-normal">•</span>
                            <span className="text-greyscale-450 cursor-pointer text-body-xs font-ubuntu font-normal">{timeSince(comment.createTime)}</span>
                        </div>
                        <p style={{ hyphens: "auto", minWidth: "0" }} className="font-inter text-body-sm font-normal break-words overflow-wrap break-word ">{comment?.comment}</p>
                    </div>
                </div>
            )}
        </>
    ); 

}