import { LoadingOutlined } from "@ant-design/icons";
import { Contact } from "@hockney-app/proto/types/v1alpha1/contact_details_pb";
import { User, User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { Modal, Space, Spin } from "antd";
import { ReactComponent as InfoCircle } from "assets/icons/info-circle.svg";
import imageCompression from "browser-image-compression";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";
import { useMessage } from "components/Messages/MessageProvider";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AtIcon } from "../../assets/icons/at-sign.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone-plus.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/user-02.svg";
import { useAuth } from "../../hooks/useAuth";
import { apiService } from "../../services/api";
import { storageService } from "../../services/storage";
import { RootState } from "../../store";
import { setUser, setUserSuccess } from "../../store/user/userSlice";

interface UsernameFormValues {
	firstname: string;
	lastname: string;
	username: string;
	contactNumber: string;
}

export default function OnboardingPage() {
	// React router hook to navigate between routes
	const [usernameValid, setUsernameValid] = useState<boolean | null>(null);
	const [profilePicture, setProfilePicture] = useState<File | Blob>(null);
	const [selectedUserType, setSelectedUserType] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	// Loading state
	const [loading, setLoading] = useState(false);
	const { errorMessage, successMessage } = useMessage();

	// Currently logged in user
	const currentUser = useSelector((state: RootState) => state.user.user);

	// Dispatch hook to dispatch actions to the store
	const dispatch = useDispatch();

	// Custom auth hook to easily get logged in user
	const { user } = useAuth();

	const navigate = useNavigate();

	const [formValues, setFormValues] = useState<UsernameFormValues>({
		firstname: "",
		lastname: "",
		username: "",
		contactNumber: "",
	});

	useEffect(() => {
		console.log("Onboarding page, currentUser = ", currentUser);
		if (currentUser?.username != "" && currentUser != null) {
			console.log("Onboarding page, currentUser not null");
			navigate("/home");
		}
	}, [currentUser]);

	const isFormValid = () => {
		return (
			formValues.firstname &&
			formValues.lastname &&
			formValues.username &&
			usernameValid &&
			selectedUserType != ""
		);
	};

	const isValidSAPhoneNumber = (phoneNumber: string) => {
		// Matches either +27 followed by 9 digits OR 0 followed by 9 digits
		const regex = /^(\+27|27|0)[0-9]{2}( |-)?[0-9]{3}( |-)?[0-9]{4}$/;
		return regex.test(phoneNumber);
	};

	const handleOptionClick = (option) => (event) => {
		event.preventDefault();
		setSelectedUserType(option);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		let regex;
		let errMsg;

		switch (name) {
			case "firstname":
			case "lastname":
				// Allow lowercase and uppercase letters, and spaces
				regex = /^[a-zA-Z\s]+$/;
				errMsg = "Only letters and spaces are allowed.";
				break;
			default:
				// Allow lowercase letters, numbers, dots, and underscores
				regex = /^[a-z0-9._]+$/;
				errMsg =
					"Only lowercase letters, numbers, fullstops, and underscores are allowed.";
		}

		// Test value against regex
		if (regex.test(value) || value === "") {
			setFormValues((prevValues) => ({
				...prevValues,
				[name]: value,
			}));
		} else {
			errorMessage(`Invalid input in ${name}: ${errMsg}`);
		}
	};
	const handleProfilePicChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.item(0);

		if (!file) {
			errorMessage("No image selected");
			return;
		}

		const options = {
			maxSizeMB: 2,
			maxWidthOrHeight: 1080,
			useWebWorker: true,
		};

		const compressedFile = await imageCompression(file, options);
		setProfilePicture(compressedFile);
	};

	const onFinish = async (event) => {
		event.preventDefault();
		console.log("Validating phone number:", formValues.contactNumber);
		if (!isValidSAPhoneNumber(formValues.contactNumber)) {
			errorMessage("Invalid South African phone number");
			return;
		}
		// Upload the profile picture
		try {
			setLoading(true);

			// Upload the profile picture to firebase storage
			if (!user) {
				errorMessage("Please sign in");
				return;
			}
			if (!profilePicture) {
				errorMessage("No image selected");
				return;
			}

			// Convert file to uint8 array
			const buffer = await profilePicture.arrayBuffer();
			const uint8Array = new Uint8Array(buffer);
			// Upload file to firebase storage
			const uploadResult = await storageService.uploadFile(
				uint8Array,
				`profilePics/${user?.uid}`
			);
			// Get the url of the uploaded file
			const profilePictureUrl = await storageService.getURL(
				uploadResult.ref.fullPath
			);

			// Create the user
			let newUser = new User();
			newUser.givenName = formValues.firstname;
			newUser.familyName = formValues.lastname;
			newUser.username = formValues.username;
			newUser.profilePictureUrl = profilePictureUrl;
			newUser.email = user.email;
			if (selectedUserType === "artist") {
				newUser.role = User_Role.ARTIST;
			} else {
				newUser.role = User_Role.COLLECTOR;
			}
			//create contact details object
			const newContactDetails = new Contact();
			newContactDetails.name =
				formValues.firstname + " " + formValues.lastname;
			newContactDetails.mobileNumber = formValues.contactNumber;
			newContactDetails.email = user.email;

			newUser.contactDetails = newContactDetails;
			console.log("NewContactDetails", newContactDetails);

			newUser = await apiService.createUser(newUser);

			dispatch(setUserSuccess(newUser));
			navigate("/home");
		} catch (error) {
			errorMessage(
				"There was a problem. Please try again or contact support at support@artbeat.ink"
			);
		} finally {
			setLoading(false);
		}
	};

	const onChangeUsername = async (e: ChangeEvent<HTMLInputElement>) => {
		setUsernameValid(null);
		try {
			const res = await apiService.listUsers({
				pageSize: 1,
				filter: `username = "${e.target.value}"`,
			});

			if (
				res.users.length === 0 ||
				res.users[0].username === currentUser?.username
			) {
				setUsernameValid(true);
				successMessage("Username is available");
				return;
			}

			setUsernameValid(false);
			errorMessage("Username is already taken");
		} catch (error) {
			errorMessage("There was an error checking username");
			console.error(error);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<div className="flex flex-col items-center justify-center p-4 h-screen w-full">
			<div className="h-full w-full flex items-center  justify-center">
				<div className="h-full w-full !overflow-y-auto">
					<Space
						direction="vertical"
						className="w-full h-full justify-between"
					>
						<div className="image-uploader">
							<div className="relative w-40 h-40 mx-auto">
								{/* Large circle */}
								<div className="rounded-full w-full h-full border-4 border-greyscale-500 overflow-hidden mb-5 mt-20 flex items-center justify-center bg-greyscale-650  ">
									{!profilePicture ? (
										<PersonIcon className="w-20 h-20" />
									) : (
										<img
											src={URL.createObjectURL(
												profilePicture
											)}
											alt="User profile"
											className="object-cover w-full h-full"
										/>
									)}
								</div>
								{/* Upload button */}
								<label
									htmlFor="profile-pic"
									className="absolute -bottom-4 -right-4 w-16 h-16 bg-teal-900 border-teal-100 border-2 border-dashed text-white flex items-center justify-center rounded-full cursor-pointer "
								>
									<PlusIcon></PlusIcon>
									<input
										id="profile-pic"
										type="file"
										accept="image/*"
										onChange={handleProfilePicChange}
										className="hidden"
									/>
								</label>
							</div>

							<form
								onSubmit={onFinish}
								className=" mt-10 ml-2 mr-2"
							>
								<div className="flex flex-row space-x-2">
									<div className="flex flex-col flex-1 relative">
										<PersonIcon className="absolute left-3 top-3.5" />
										<input
											placeholder="First Name"
											autoComplete="given-name"
											type="text"
											name="firstname"
											onChange={handleInputChange}
											className="inputTextArea"
										/>
									</div>

									<div className="flex flex-col flex-1 relative">
										<PersonIcon className="absolute left-3 top-3.5" />
										<input
											placeholder="Last Name"
											autoComplete="family-name"
											type="text"
											name="lastname"
											onChange={handleInputChange}
											className="inputTextArea"
										/>
									</div>
								</div>

								<div className="inputDiv">
									<AtIcon className="fieldIcon" />
									<input
										placeholder="Create Your Username"
										type="text"
										name="username"
										onChange={(e) => {
											e.target.value =
												e.target.value.toLowerCase();
											onChangeUsername(e);
											handleInputChange(e);
										}}
										className="inputTextArea"
									/>
								</div>
								<div className="relative inputDiv">
									<Phone className="absolute left-3 top-3" />
									<input
										className="inputTextArea"
										type="tel"
										name="contactNumber"
										onChange={handleInputChange}
										placeholder="Phone Number"
										required
										autoComplete="tel"
									/>
									<button
										className="absolute right-1 top-7 transform -translate-y-1/2 bg-transparent border-none"
										onClick={showModal}
									>
										<InfoCircle className="text-white" />
									</button>
									<Modal
										title="Why We Ask For Your Phone Number"
										open={isModalOpen}
										onOk={handleOk}
										onCancel={handleCancel}
										className="font-inter text-white text-base not-italic font-normal mt-80"
										footer={null}
									>
										<section>
											Do not fear, this isn&apos;t for any
											pesky marketing! We collect your
											number to speed up your checkout
											process. Please enter a valid South
											African number, starting with 0 or
											+27.
										</section>
									</Modal>
								</div>

								<div className="flex relative w-full mt-1 h-4">
									{usernameValid === false &&
										formValues.username && (
											<span className="text-red-500">
												Username is already taken
											</span>
										)}
									{usernameValid === true &&
										formValues.username && (
											<span className="text-teal-500">
												Username is available
											</span>
										)}
								</div>

								<div className="mt-2 items-center justify-center flex">
									<p className=" font-body text-body font-semibold font-inter text-greyscale-500">
										Are you an artist or a collector?
									</p>
								</div>

								<div className="flex justify-between gap-4">
									<button
										onClick={handleOptionClick("artist")}
										className={`w-full font-bold py-2 px-4  ${
											selectedUserType === "artist"
												? "btn btn-secondary "
												: "btn text-greyscale-550 bg-greyscale-700  border-greyscale-700 cursor-not-allowed"
										}`}
									>
										Artist
									</button>
									<button
										onClick={handleOptionClick("collector")}
										className={`w-full font-bold py-2 px-4  ${
											selectedUserType === "collector"
												? "btn btn-secondary"
												: "btn text-greyscale-550 bg-greyscale-700  border-greyscale-700 cursor-not-allowed"
										}`}
									>
										Collector
									</button>
								</div>

								<div className="mt-4 w-full flex flex-col justify-center">
									<button
										type="submit"
										className={` ${
											isFormValid()
												? "btn-primary active:bg-teal-600"
												: " btn-disabled border-none"
										} py-2.5 px-4 w-full mt-4 rounded-full h-10 text-sm font-inter font-semibold `}
										disabled={!isFormValid()}
									>
										{loading
											? LoadingSpinner("white")
											: "Create Account"}
									</button>
								</div>
							</form>
						</div>
					</Space>
				</div>
			</div>
		</div>
	);
}
