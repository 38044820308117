import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { message, Spin } from "antd";
import imageCompression from "browser-image-compression";
import { useMessage } from "components/Messages/MessageProvider";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CameraPlusIcon } from "../../assets/icons/camera-plus.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/user-02.svg";
import Header from "../../components/Headers/Header";
import LoadingSpinner from "../../components/LoadingSpinner/loadingSpinner";
import { useAuth } from "../../hooks/useAuth";
import { apiService } from "../../services/api";
import { storageService } from "../../services/storage";
import { RootState } from "../../store";
import { setUser, setUserSuccess } from "../../store/user/userSlice";

export default function EditProfilePage() {
	// Currently logged in user
	const currentUser = useSelector((state: RootState) => state.user.user);
	const navigate = useNavigate();
	const [profilePicture, setProfilePicture] = useState<File | Blob>(null);
	const [formData, setFormData] = useState({
		firstName: currentUser ? currentUser.givenName : "",
		lastName: currentUser ? currentUser.familyName : "",
		bio: currentUser ? currentUser.bio : "",
		profilePictureURL: currentUser ? currentUser.profilePictureUrl : "",
	});
	const { user } = useAuth();
	const [userLoading, setUserLoading] = useState(true);
	//const for when user uploads new image
	const [imageLoading, setImageLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const { errorMessage, successMessage } = useMessage();
	const [redirectTimeout, setRedirectTimeout] = useState(null);

	// Dispatch hook to dispatch actions to the store
	const dispatch = useDispatch();

	//clears timeout on component unmount
	useEffect(() => {
		return () => {
			if (redirectTimeout) {
				clearTimeout(redirectTimeout);
			}
		};
	}, [redirectTimeout]);

	const handleFormSubmit = async (e: FormEvent) => {
		e.preventDefault();

		setButtonLoading(true);

		try {
			// If a new profile picture has been selected
			if (profilePicture) {
				// Convert file to uint8 array
				const buffer = await profilePicture.arrayBuffer();
				const uint8Array = new Uint8Array(buffer);

				// Upload file to Firebase storage
				const uploadResult = await storageService.uploadFile(
					uint8Array,
					`profilePics/${user?.uid}`
				);

				// Get the URL of the uploaded file
				const profilePictureUrl = await storageService.getURL(
					uploadResult.ref.fullPath
				);

				// Update formData
				setFormData((prevState) => ({
					...prevState,
					profilePictureURL: profilePictureUrl,
				}));

				bustCache(profilePictureUrl);
			}

			let updatedUser = new User();
			updatedUser.name = currentUser?.name;
			updatedUser.givenName = formData.firstName;
			updatedUser.familyName = formData.lastName;
			updatedUser.bio = formData.bio;
			updatedUser.profilePictureUrl = formData.profilePictureURL;

			updatedUser = await apiService.updateUser(updatedUser, [
				"given_name",
				"family_name",
				"bio",
				"profile_picture_url",
			]);

			// Dispatch the updated user
			dispatch(setUserSuccess(updatedUser));
			successMessage("Profile updated successfully. Redirecting... ");
			const timeoutId = setTimeout(() => {
				navigate(`/@/${currentUser?.username}`);
			}, 2000);
			setRedirectTimeout(timeoutId);
		} catch (error) {
			errorMessage("Error updating profile");
			console.error(error);
		} finally {
			setButtonLoading(false);
		}
	};

	//force image rerender as url stays the same as old one
	const bustCache = (imageUrl) => {
		const img = new Image();
		img.src = imageUrl;
		img.onload = () => {
			URL.revokeObjectURL(img.src);
		};
	};

	const handleProfilePicChange = async (e: ChangeEvent<HTMLInputElement>) => {
		setImageLoading(true);
		const file = e.target.files?.item(0);

		if (!file) {
			message.error("No image selected");
			return;
		}

		const options = {
			maxSizeMB: 2,
			maxWidthOrHeight: 1048,
			useWebWorker: true,
		};

		const compressedFile = await imageCompression(file, options);
		setProfilePicture(compressedFile);
		setImageLoading(false); // End the upload process
	};
	// Handle input changes in a generic way
	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const Loading = () => {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<Spin />
			</div>
		);
	};

	useEffect(() => {
		if (currentUser) {
			setFormData({
				firstName: currentUser.givenName,
				lastName: currentUser.familyName,
				bio: currentUser.bio,
				profilePictureURL: currentUser.profilePictureUrl,
			});
			setUserLoading(false);
		}
	}, [currentUser]);

	if (userLoading) return <Loading />;

	return (
		<div>
			<div className="h-full w-full flex flex-col justify-center pt-12">
				<Header
					title="Edit Profile"
					onBack={() => navigate(`/@/${currentUser?.username}`)}
				/>

				<div>
					<div className="relative w-40 h-40 mx-auto">
						{/* Large circle */}
						<div className="rounded-full w-full h-full border-4 border-greyscale-500 overflow-hidden mb-5 mt-4 flex items-center justify-center bg-greyscale-650  ">
							{imageLoading ? (
								LoadingSpinner("white") // Show spinner while uploading
							) : !profilePicture ? (
								<img
									src={formData.profilePictureURL}
									alt="User profile"
									className="object-cover w-full h-full"
								/>
							) : (
								<img
									src={URL.createObjectURL(profilePicture)}
									alt="User profile"
									className="object-cover w-full h-full"
								/>
							)}
						</div>
						{/* Upload button */}
						<label
							htmlFor="profile-pic"
							className="absolute bottom-1 right-1 w-10 h-10 bg-teal-900 border-teal-100 border-2 border-solid text-white flex items-center justify-center rounded-full cursor-pointer "
						>
							<CameraPlusIcon></CameraPlusIcon>
							<input
								id="profile-pic"
								type="file"
								accept="image/*"
								onChange={handleProfilePicChange}
								className="hidden"
							/>
						</label>
					</div>

					<form
						onSubmit={handleFormSubmit}
						className=" mt-10 ml-2 mr-2"
					>
						<div className="flex flex-row space-x-2">
							<div className="flex flex-col flex-1 relative">
								<PersonIcon className="absolute left-3 top-3.5" />
								<input
									placeholder="First Name"
									autoComplete="first name"
									type="text"
									pattern="^[A-Za-z\s]{2,}$"
									title="Name should be at least two letters and alphabets only"
									name="firstName"
									value={formData.firstName}
									onChange={handleInputChange}
									className="px-3 py-2 w-full p-2 pl-10 h-10 mt-1 rounded-md text-body placeholder-greyscale-500 bg-greyscale-650 text-white font-inter border-none"
								/>
							</div>

							<div className="flex flex-col flex-1 relative">
								<PersonIcon className="absolute left-3 top-3.5" />
								<input
									placeholder="Last Name"
									autoComplete="last name"
									type="text"
									name="lastName"
									pattern="^[A-Za-z\s]{2,}$"
									title="Last name should be at least two letters and alphabets only"
									value={formData.lastName}
									onChange={handleInputChange}
									className="px-3 py-2 w-full p-2 pl-10 h-10 mt-1 rounded-md text-body placeholder-greyscale-500 bg-greyscale-650 text-white font-inter border-none"
								/>
							</div>
						</div>
						<div className="mt-2 flex flex-col relative">
							<EditIcon className="absolute left-3 top-3" />
							<textarea
								rows={5}
								placeholder="Edit Bio"
								name="bio"
								value={formData.bio}
								onChange={handleInputChange}
								className="px-3 py-2 w-full p-2 pl-10 mt-1 rounded-md text-body placeholder-greyscale-500 bg-greyscale-650 text-white font-inter border-none"
							></textarea>

							<div className="mt-8 mb-40 w-full flex flex-col justify-center">
								<button
									type="submit"
									className={"btn btn-primary btn-sm "}
								>
									{buttonLoading
										? LoadingSpinner("white")
										: "Save Changes"}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
