import { DownOutlined } from "@ant-design/icons";
import { Button, Carousel, Dropdown, Menu } from "antd";
import PillTabs from "pages/Profile/Components/PillTabs";
import React, { useEffect, useRef, useState } from "react";

interface PrintPostProps {
	title: string;
	permalink?: string;
	images: string[];
	sizePricing: { [key: string]: { Framed: number; Unframed: number } };
}

export default function PrintPost({
	title,
	permalink,
	images,
	sizePricing,
}: PrintPostProps) {

	const getSmallestSize = () => {
		const sizes = Object.keys(sizePricing);
		return sizes.length > 0 ? sizes.sort()[0] : null;
	};

	const smallestSize = getSmallestSize();

	// Set initial states dynamically based on available sizes
	const [activeSize, setActiveSize] = useState(smallestSize || "A5");
	const [selectedFrame, setSelectedFrame] = useState("Unframed");
	const [price, setPrice] = useState(
		sizePricing[activeSize]
			? sizePricing[activeSize]["Unframed"]
			: 0
	);
	const [imageLoaded, setImageLoaded] = useState(false);
	const containerRef = useRef(null);
	const [placeholderHeight, setPlaceholderHeight] = useState(0);

	const sizeOptions = Object.keys(sizePricing).map(key => ({
        key: key,
        label: key,
        onClick: () => handleSizeClick(key),
    }));


	const handleSizeClick = (sizeKey: string) => {
		setActiveSize(sizeKey);
		setPrice(sizePricing[sizeKey][selectedFrame]);
	};

	const handleMenuClick = (e: any) => {
		const newFrameOption = e.key === "1" ? "Framed" : "Unframed";
		setSelectedFrame(newFrameOption);
		setPrice(sizePricing[activeSize][newFrameOption]);
	};

	const sizeOptionsWithClick = sizeOptions.map((size) => ({
		...size,
		onClick: () => handleSizeClick(size.key),
	}));

	useEffect(() => {
		if (containerRef.current) {
			const width = containerRef.current.offsetWidth;
			setPlaceholderHeight(width); // For an aspect ratio of 1:1
		}
	}, []);

	const frameMenu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="1">Framed</Menu.Item>
			<Menu.Item key="2">Unframed</Menu.Item>
		</Menu>
	);

	const handlePurchase = () => {
		let normalizedTitle: string;
		let normalizedFrame: string;
		if (!permalink) {
			normalizedTitle = title.toLowerCase().replace(/\s+/g, "-");
		} else {
			normalizedTitle = permalink;
		}
		if (title === "Torrent 7" && activeSize === "A5") {
			normalizedFrame = selectedFrame.toLowerCase() + "-a";
		} else {
			normalizedFrame = selectedFrame.toLowerCase();
		}

		const purchaseUrl = `https://paystack.com/buy/${normalizedTitle}---${activeSize.toLocaleLowerCase()}-${normalizedFrame}`;
		console.log(purchaseUrl);
		window.location.href = purchaseUrl;
	};

	useEffect(() => {
		// Assume this effect does something related to component setup
	}, []);

	return (
		<>
			<div ref={containerRef} className="text-center mb-8">
				<h2 className="text-2xl font-bold mb-2">{title}</h2>
				{!imageLoaded && (
					<div
						style={{ height: `${placeholderHeight}px` }}
						className="w-full rounded-xl bg-greyscale-550 animate-pulse mb-2"
					></div>
				)}
				<Carousel autoplay autoplaySpeed={5000}>
					{images.map((image) => (
						<img
							src={image}
							key={image}
							className={`h-full object-fill mb-2 rounded-xl ${
								!imageLoaded && "hidden"
							}`}
							onLoad={() => setImageLoaded(true)}
						/>
					))}
				</Carousel>
				<p className="font-inter text-lg mb-2">Price: R{price} </p>

				<PillTabs
					tabs={sizeOptionsWithClick}
					activeKey={activeSize}
					className="items-center justify-center space-x-4"
				/>

				<Dropdown
					overlay={frameMenu}
					placement="bottomCenter"
					className="mt-2"
				>
					<Button>
						{selectedFrame} <DownOutlined />
					</Button>
				</Dropdown>

				<button
					onClick={handlePurchase}
					className="w-full btn btn-secondary btn-md mt-2"
				>
					Purchase Print
				</button>
			</div>
		</>
	);
}
