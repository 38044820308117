import { User, User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { message } from "antd";
import Avatar from "components/Avatar/Avatar";
import React, {
	Dispatch,
	MouseEventHandler,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/loadingSpinner";
import { apiService } from "../../../services/api";
import { RootState } from "../../../store";
import UserListItemSkeleton from "./UserListItemskeleton";
import { ReactComponent as ArtistSignature } from "../../../assets/icons/signatureverified.svg"; 



interface UserListItemProps {
	name: string;
	onClick?: MouseEventHandler<HTMLDivElement> | undefined;
	followTab?: string | null; // Can be null when no tab is active
	setFollowTab?: Dispatch<SetStateAction<"followers" | "following" | null>>;
}

export default function UserListItem({
	name,
	setFollowTab,
	followTab,
}: UserListItemProps) {
	// Currently logged in user
	const currentUser = useSelector((state: RootState) => state.user.user);

	// Dispatch hook to dispatch actions to the store
	const navigate = useNavigate();

	const [user, setUser] = useState<User>();
	const [loading, setLoading] = useState(true);

	const [userFollowingUpdating, setUserFollowingUpdating] = useState(false);
	const [messageApi] = message.useMessage();
	const isCurrentUser = user?.name === currentUser?.name;

	const [isFollowing, setIsFollowing] = useState(false);

	const getUser = useCallback(async (name: string) => {
		try {
			const userRes = await apiService.getUser(name, {
				readPaths: [
					"name",
					"username",
					"profilePictureUrl",
					"following",
					"followers",
				],
			});

			setUser(null);
			setUser(userRes);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getUser(name);
	}, [getUser, currentUser, name]);

	const followUser = async (name: string) => {
		try {
			console.log("User followed");
			await apiService.followUser(name ?? "");
			setIsFollowing(true);
		} catch (error) {
			// Potentially provide more specific error messages based on the error.
			messageApi.error("There was a problem following the user");
		} finally {
			setUserFollowingUpdating(false);
		}
	};

	const unfollowUser = async (name: string) => {
		try {
			// Optimistically update the followers count.
			setUserFollowingUpdating(true);

			await apiService.unfollowUser(name ?? "");
			setIsFollowing(false);
		} catch (error) {
			// Potentially provide more specific error messages based on the error.
			messageApi.error("There was a problem unfollowing the user");
		} finally {
			setUserFollowingUpdating(false);
		}
	};

	useEffect(() => {
		if (user) {
			setIsFollowing(user?.followers.includes(currentUser.name));
		}
	}, [user]);


	return (
		<>
			{loading ? (
				<UserListItemSkeleton />
			) : (
				<div
					className="flex w-full justify-between items-center px-4 pb-2 pt-2  cursor-pointer relative"
					onClick={() => navigate(`/@/${user?.username}`)}
				>
					<Avatar user={user} className="w-10 h-10 mr-3" />
					<div className="flex-grow relative">
						<div className="flex justify-between items-center w-full">
							<div className="flex flex-col ">
								<span className="font-inter text-sm leading-4 mb-1  font-medium text-white">
									{user?.givenName} {user?.familyName}
									{user?.role === User_Role.ARTIST && (
                                    <ArtistSignature className="ml-1 w-3 h-3" />
                                )} 
								</span>
								<span className="font-ubuntu text-xs font-normal text-greyscale-450">
									@{user?.username}
								</span>
							</div>

							{!isCurrentUser &&
								(isFollowing ? (
									<button
										className="text-body-sm font-inter font-normal px-3.5 py-1 btn btn-sm btn-secondary w-[92px]"
										disabled={
											userFollowingUpdating || !user
										}
										onClick={(event) => {
											event.stopPropagation(); // Stop event propagation
											unfollowUser(user?.name);
											setIsFollowing(false);
										}}
									>
										{userFollowingUpdating
											? LoadingSpinner("white")
											: "Following"}
									</button>
								) : (
									<button
										className="text-body-sm font-inter font-normal px-3.5 py-1 btn btn-sm btn-primary w-[92px]"
										disabled={
											userFollowingUpdating || !user
										}
										onClick={(event) => {
											event.stopPropagation(); // Stop event propagation
											followUser(user?.name);
											setIsFollowing(true);
										}}
									>
										{userFollowingUpdating
											? LoadingSpinner("white")
											: "Follow"}
									</button>
								))}
						</div>
						<div className="absolute left-0 right-0 bottom-[-8px] h-[1px] bg-greyscale-700 mt-4" />
					</div>
				</div>
			)}
		</>
	);
}
