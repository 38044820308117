import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddressSelector from "../../../components/Checkout/AddressSelector";
import Header from "../../../components/Headers/Header";
import { RootState } from "../../../store";
import AccountDetails from "../../UploadArtworkOld/AccountDetails";

interface BankAndLocationProps {
	onRadioButtonClick?: (clicked: boolean) => void;
}

export default function BankAndLocation({
	onRadioButtonClick,
}: BankAndLocationProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedAddress, setSelectedAddress] = useState();
	const me = useSelector((state: RootState) => state.user.user);
	const handleAddressChange = (address) => {
		setSelectedAddress(address);
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showBankInput, setShowBankInput] = useState(false);

	// To remove component on button click
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [bankDetailsEntered, setBankDetailsEntered] = useState(false);

	const handleBankDetailsEntered = () => {
		setBankDetailsEntered(true);
		setShowBankInput(false); // Hide the AccountDetails component
	};

	return (
		<div>
			<Header title={"Bank Account and Location"} page={"/settings"} />
			<div className="flex flex-col justify center justify-items mx-4 pt-12">
				{me?.role === 3 && (
					<AccountDetails
						onBankDetailsEntered={handleBankDetailsEntered}
						currentUserBankDetails={me?.bankDetails || null}
					/>
				)}
				<div className="mt-8">
					<AddressSelector onAddressChange={handleAddressChange} />
				</div>
			</div>
		</div>
	);
}
