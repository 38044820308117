import React, { useState } from "react";
import { Modal } from "antd";
import {ReactComponent as AnnouncementIcon} from "assets/icons/announcement-01.svg" ; 

const FeedbackButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    setOpen(false);
  };
  const url = "https://form.jotform.com/232113307190039";

  return (
    <>
      <button className="bg-[#000000] bg-opacity-0 bg-blur-sm border-none items-center justify-center " onClick={showModal}>
        <AnnouncementIcon className="bg-opacity-0"/>
      </button>
      <Modal open={open} onCancel={handleCancel} footer={null}>
        <h1 className="text-white text-center font-ubuntu text-xl font-bold">We&apos;d love to hear from you!</h1>
        <p className="text-greyscale-450 text-center font-inter text-sm">
          Your feedback helps us improve our platform. If you have any bug reports, feature suggestions, or any other
          feedback let us know by filling out the form below
        </p>
        {open && (
          <iframe
            id="jotform"
            title="jotform"
            src={url}
            style={{ width: "100%", height: "400px", border: "none" }}
          />
        )}
      </Modal>
    </>
  );
};

export default FeedbackButton ; 