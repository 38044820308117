import React, { useState } from "react";
import { ReactComponent as SignatureVerified } from "assets/icons/signatureverified.svg";
import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import facePortrait from "assets/images/FacePortraitCropped.jpg" ; 

interface AvatarProps {
	className: string;
	user: User;
	onClick?: () => void;
    profilePicture?: string; 
}

function Avatar({ className, user, onClick }: AvatarProps) { 
    const [imageLoaded, setImageLoaded] = useState(false);
    
    const avatarSrc = user && user.profilePictureUrl ? user.profilePictureUrl : facePortrait;


    return (
        <div
            className={`relative ${imageLoaded ? "" : "animate-pulse"} bg-greyscale-550 rounded-full cursor-pointer ${className}`}
            onClick={onClick}
        >
            <img
                src={avatarSrc}
                alt={user?.username || "User Avatar"}
                className={`object-cover rounded-full ${className}`}
                onLoad={() => setImageLoaded(true)}
                style={{ display: imageLoaded ? "block" : "none" }}
            />
        </div>
    );
}

export default Avatar;