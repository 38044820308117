import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

const UserCarouselItem = ({ name, imageUrl, userRef }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
  
    const scrollToRef = (ref) => {
      if (ref.current) {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: "smooth",
        });
      }
    };

    useEffect(() => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImageLoaded(true);
      };
    }, [imageUrl]); // Add imageUrl as a dependency for the effect
  
    return (
      <div className="text-center" onClick={() => scrollToRef(userRef)}>
        {imageLoaded ? (
          <div className="w-[148px] h-[148px] mx-auto mb-2 rounded-full overflow-hidden">
            <img src={imageUrl} alt={name} className="w-full h-full object-cover" />
          </div>
        ) : (
          <div className="w-[148px] h-[148px] mx-auto mb-2 rounded-full animate-pulse bg-greyscale-550"></div>
        )}
        <p className="font-inter text-body-sm font-medium">{name}</p>
      </div>
    );
};

UserCarouselItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  userRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func, 
    // Or the instance of a DOM native element (e.g., HTMLDivElement)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

// UserCarousel component
const UserCarousel = ({ users }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1, 
    autoplay: true,      // Enable autoplay
    autoplaySpeed: 3000, // Slide will change every 3 seconds
    arrows: false 
    // Add more settings as needed for your design
  };

  return (
    <div>
      <div className="w-full"> {/* Ensure full width */}
      <h2 className="flex flex-grow font-ubuntu text-h2-medium justify-start mb-3"> Featured Artists</h2>
      <Slider {...settings}>
        {users.map((user, index) => (
          <UserCarouselItem key={index} name={user.name} imageUrl={user.imageUrl} userRef={user.ref} />
        ))}
      </Slider>
    </div>
    </div>
  );
};

UserCarousel.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      ref: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ]).isRequired
    })
  ).isRequired
};

export default UserCarousel;