import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EyeOffIcon } from "../../../../assets/icons/eye-off.svg";
import { ReactComponent as EyeOnIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as Lock } from "../../../../assets/icons/lock-01.svg";
import { ReactComponent as Unlock } from "../../../../assets/icons/lock-unlocked-01.svg";
import Button from "../../../../components/Buttons/Button";
import Header from "../../../../components/Headers/Header";
import InputField from "../../../../components/Inputs/InputField";
import Message from "../../../../components/Messages/Message";
import { authenticationService } from "../../../../services/authentication";
import { RootState } from "../../../../store";

export default function UpdatePassword() {
	// state to track whether the user is in the "confirm current password" step or the "update password" step
	const [step, setStep] = useState(0); // 0 for confirm current password, 1 for update password

	// states for buttons
	const [currentPasswordUploading, setCurrentPasswordUploading] =
		useState(false);
	const [newPasswordUploading, setNewPasswordUploading] = useState(false);

	// React router hook to navigate between routes
	const navigate = useNavigate();

	// State to show password
	const [showPassword, setShowPassword] = useState(false);

	// States for new password
	const [newPasswordForm, setNewPasswordForm] = useState({
		newPassword: "",
		confirmPassword: "",
	});

	// Error message hooks
	const [errorMessage, setErrorMessage] = useState("");
	const [errorCount, setErrorCount] = useState(0);

	// Toggles password visibility
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	// States from store
	const currentUser = useSelector((state: RootState) => state.user.user);

	// Handles current password and user validation
	const [signInForm, setSignInForm] = useState({
		password: "",
	});

	// Handles the changes to the current password
	const handleSignInFormChange = (event) => {
		setSignInForm({
			...signInForm,
			[event.target.name]: event.target.value,
		});
	};

	// Handles the new password change
	const handleNewPasswordChange = (event) => {
		setNewPasswordForm({
			...newPasswordForm,
			[event.target.name]: event.target.value,
		});
	};

	const handleConfirmCurrentPassword = async (e) => {
		e.preventDefault();

		// Start uploading (show loading spinner)
		try {
			setCurrentPasswordUploading(true);
			// Confirm the current password
			await authenticationService.reauthenticateWithPassword(
				signInForm.password
			);

			// If confirmed, move to the next step
			setStep(1);
		} catch (error) {
			console.log("Value of singInForm.password: ", signInForm.password);
			setErrorMessage("Current password is incorrect.");
			setErrorCount(errorCount + 1);
		} finally {
			setCurrentPasswordUploading(false);
		}
	};

	const handleUpdatePassword = async (e) => {
		e.preventDefault();

		if (newPasswordForm.newPassword !== newPasswordForm.confirmPassword) {
			setErrorMessage("New passwords do not match!");
			return;
		}

		try {
			setNewPasswordUploading(true);

			// Reauthenticate the user
			await authenticationService.reauthenticateWithPassword(
				signInForm.password
			);

			// Update the password
			await authenticationService.updatePassword(
				signInForm.password,
				newPasswordForm.newPassword
			);
			navigate("/settings/your_account");

			// Redirect or show success message
		} catch (error) {
			setErrorMessage("An error occurred while updating the password.");
			setErrorCount(errorCount + 1);
		} finally {
			setNewPasswordUploading(false);
		}
	};

	return (
		<>
			{errorMessage && (
				<Message
					message={errorMessage}
					duration={4000}
					type={"error"}
					key={errorCount}
					zIndex={errorCount}
				/>
			)}
			<Header
				title={"Change Your Password"}
				page={"/settings/your_account"}
			/>
			<div className="flex-col items-center gap-4 flex-grow flex-shrink-0 basis-0 self-stretch pt-12">
				<section className="mx-4 my-4 font-inter not-italic text-greyscale-450 text-left">
					Enter and confirm your current and new password.{" "}
				</section>
				{step === 0 && (
					<form
						className="inputForm mx-4"
						onSubmit={handleConfirmCurrentPassword}
					>
						<InputField
							Icon={Lock}
							inputProps={{
								id: "password",
								name: "password",
								type: showPassword ? "text" : "password",
								placeholder: "Enter Current Password",
								onChange: handleSignInFormChange,
								autoComplete: "current-password",
							}}
							IconRight={showPassword ? EyeOffIcon : EyeOnIcon}
							onIconRightClick={togglePasswordVisibility}
						/>

						<div className="flex items-center mx-4 my-4">
							<Button
								type="submit"
								color="primary"
								text="Confirm Password"
								size="small"
								loading={currentPasswordUploading}
								disabled={!signInForm.password}
							></Button>
						</div>
					</form>
				)}
				{step === 1 && (
					<form onSubmit={handleUpdatePassword}>
						<InputField
							Icon={Unlock}
							IconRight={showPassword ? EyeOffIcon : EyeOnIcon}
							onIconRightClick={togglePasswordVisibility}
							inputProps={{
								id: "newPassword",
								name: "newPassword",
								type: showPassword ? "text" : "password",
								placeholder: "New Password",
								onChange: handleNewPasswordChange,
							}}
							iconRightClass="absolute right-6 top-4 cursor-pointer"
						/>

						<InputField
							Icon={Unlock}
							IconRight={showPassword ? EyeOffIcon : EyeOnIcon}
							inputProps={{
								id: "confirmPassword",
								name: "confirmPassword",
								type: showPassword ? "text" : "password",
								placeholder: "Confirm Password",
								onChange: handleNewPasswordChange,
							}}
							onIconRightClick={togglePasswordVisibility}
							iconRightClass="absolute right-6 top-4 cursor-pointer"
						/>

						<div className="flex items-center mx-4 my-4">
							<Button
								type="submit"
								color="primary"
								text="Update Password"
								size="small"
								loading={newPasswordUploading}
								disabled={
									!(
										newPasswordForm.confirmPassword &&
										newPasswordForm.newPassword
									)
								}
							></Button>
						</div>
					</form>
				)}
				<div className="flex items-center justify-center my-4">
					<button
						className="text-center font-inter text-sm not-italic font-semibold text-greyscale-500 bg-transparent border-none"
						onClick={() => {
							authenticationService.sendPasswordResetEmail(
								currentUser.email
							);
							alert(
								"A password reset email has been sent to your email address."
							);
						}}
					>
						Forgot password?
					</button>
				</div>
			</div>
		</>
	);
}
