import { shapes } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { User_Role } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { ReactComponent as BrushPlus } from "assets/icons/brush-plus.svg";
import { ReactComponent as HomeSelectedIcon } from "assets/icons/navbar/home-selected.svg";
import { ReactComponent as HomeIcon } from "assets/icons/navbar/home.svg";
import { ReactComponent as SearchSelectedIcon } from "assets/icons/navbar/search-selected.svg";
import { ReactComponent as SearchIcon } from "assets/icons/navbar/search.svg";
import Avatar from "components/Avatar/Avatar";
import { useSplashScreen } from "components/SplashScreen/UseSplashScreen";
import { useAuth } from "hooks/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";

export default function NavBar() {
	const navigate = useNavigate();
	const { user } = useAuth();
	const [profilePicture, setProfilePicture] = useState<string>("");
	const location = useLocation();
	const { isSplashScreenOpen, toggleSplashScreen, SplashScreenComponent } =
		useSplashScreen();

	const handleHomeIconClick = (currentPath) => {
		console.log("Home icon clicked. Current path:", currentPath);
		if (currentPath === "/home") {
			window.scrollTo({ top: 0, behavior: "smooth" });
		} else {
			navigate("/home");
		}
	};

	// Depending on the route the user is on, the color of the icon will be set
	// to be transparent or the primary color
	const homeIcon =
		useLocation().pathname === "/home" ? (
			<HomeSelectedIcon
				onClick={() => {
					handleHomeIconClick(location.pathname);
				}}
			/>
		) : (
			<HomeIcon
				onClick={() => {
					handleHomeIconClick(location.pathname);
				}}
			/>
		);
	const searchIcon =
		useLocation().pathname === "/home/search" ? (
			<SearchSelectedIcon />
		) : (
			<SearchIcon
				onClick={() => {
					navigate("/home/search");
				}}
			/>
		);

	const currentUser = useSelector((state: RootState) => state.user.user);

	// getProfilePicture is a function that gets the profile picture URL of the user
	// It first checks if the user has a profile picture URL in the database (hockneyUser)
	// If not, it checks if the user has a profile picture URL in the auth object (user)
	// If not, it generates a profile picture URL using the user" s uid
	const getProfilePicture = useCallback(async () => {
		if (
			currentUser?.profilePictureUrl &&
			currentUser?.profilePictureUrl != ""
		) {
			setProfilePicture(currentUser?.profilePictureUrl);
			return;
		}

		if (user?.photoURL && user?.photoURL != "") {
			setProfilePicture(user?.photoURL);
			return;
		}

		const avatar = createAvatar(shapes, {
			seed: user?.uid,
		});

		const avatarURI = await avatar.toDataUri();
		setProfilePicture(avatarURI);
	}, [currentUser, user]);

	useEffect(() => {
		getProfilePicture();
	}, [currentUser, user, getProfilePicture]);

	return (
		<>
			<SplashScreenComponent />
			<div
				// Ensure the bottom navbar is always at the bottom of the page
				id="bottom-navbar"
				className="flex flex-row items-center justify-between fixed bottom-0 w-full h-[60px] z-[3] bg-[#000000] bg-opacity-75 backdrop-blur-sm px-6"
			>
				{homeIcon}

				{searchIcon}

				{currentUser?.role === User_Role.ARTIST ? (
					<BrushPlus
						onClick={() => {
							navigate("/home/artworkDetails");
						}}
						width={26}
						height={26}
					/>
				) : (
					<></>
				)}

				<Avatar
					className="w-9 h-9"
					onClick={() => {
						if (!user?.isAnonymous) {
							navigate(`/@/${currentUser?.username}`);
						} else {
							toggleSplashScreen();
						}
					}}
					user={currentUser}
				/>
			</div>
		</>
	);
}
