import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { useSplashScreen } from "components/SplashScreen/UseSplashScreen";
import { logEvent } from "firebase/analytics";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark.svg";
import { analytics } from "../../../config/firebase";
import { useAuth } from "../../../hooks/useAuth";
import { apiService } from "../../../services/api";
import { RootState } from "../../../store";
import { setUser, setUserSuccess } from "../../../store/user/userSlice";

interface WishlistButtonProps {
	userIn: User | null;
	artwork: Artwork | null;
}

const WishlistButton = ({ userIn, artwork }: WishlistButtonProps) => {
	const [isWishlisted, setIsWishlisted] = useState(false);
	const currentUser = useSelector((state: RootState) => state.user.user);
	const dispatch = useDispatch();
	const { isSplashScreenOpen, toggleSplashScreen, SplashScreenComponent } =
		useSplashScreen();

	const { user } = useAuth();

	const handleClick = () => {
		if (user.isAnonymous) {
			toggleSplashScreen();
		} else {
			if (isWishlisted) {
				// Assume removal will be successful
				setIsWishlisted(false);
				RemoveFromWishList();
			} else {
				// Assume addition will be successful
				setIsWishlisted(true);
				AddtoWishList();
			}
		}
	};

	const AddtoWishList = async () => {
		try {
			const userNamePath = userIn.name;
			const artworkNamePath = artwork.name;
			await apiService.addToWishlist(userNamePath, artworkNamePath);

			//update store
			const updatedUser = currentUser.clone();
			updatedUser.wishlist.push(artwork.name);
			dispatch(setUserSuccess(updatedUser));

			console.log("Item wishlisted");
			logEvent(analytics, "add_to_wishlist", {
				item_id: artwork.name, // Unique ID for the product
				item_name: artwork.title, // Name of the product
				item_price: artwork.currentPrice, // Price of the product
				item_category: artwork.medium, // Category the product belongs to
				item_variant: artwork.style, // Product variant, e.g., "red" or "XL"
			});
		} catch (error) {
			console.log(error);
			// Revert state on error
			setIsWishlisted(false);
		}
	};

	const RemoveFromWishList = async () => {
		try {
			const userNamePath = userIn.name;
			const artworkNamePath = artwork.name;
			await apiService.removeFromWishlist(userNamePath, artworkNamePath);

			//update store
			const updatedUser = currentUser.clone();
			updatedUser.wishlist = updatedUser.wishlist.filter(
				(artworkItem) => artworkItem !== artwork.name
			);
			dispatch(setUserSuccess(updatedUser));

			console.log("Item unwishlisted");
		} catch (error) {
			console.log(error);
			// Revert state on error
			setIsWishlisted(true);
		}
	};

	//sets wishlisted state by checking array of wishlist
	useEffect(() => {
		setIsWishlisted(userIn?.wishlist.includes(artwork.name));
	}, [user, artwork]);

	return (
		<>
			<SplashScreenComponent />
			<button
				onClick={handleClick}
				className={`
      cursor-pointer 
      border-none 
      bg-transparent
      flex
      pt-4 
      px-0
      justify-center 
      items-center 
    `}
			>
				<BookmarkIcon
					className={`
        w-6
        h-6
        ${
			isWishlisted
				? "fill-teal-500 stroke-teal-500"
				: "fill-white stroke-current"
		} 
        stroke-2 
      `}
				/>
			</button>
		</>
	);
};

export default WishlistButton;
