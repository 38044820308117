import {
	Artwork_Medium,
	Artwork_Style,
} from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";

export const mediumItems = [
	{
		displayName: "Unspecified",
	},
	{
		displayName: "Charcoal",
	},
	{
		displayName: "Markers",
	},
	{
		displayName: "Pastels",
	},
	{
		displayName: "Pencil",
	},
	{
		displayName: "Pen & Ink",
	},
	{
		displayName: "Acrylic",
	},
	{
		displayName: "Gouache",
	},
	{
		displayName: "Oil",
	},
	{
		displayName: "Tempera",
	},
	{
		displayName: "Water Color",
	},
	{
		displayName: "Etching",
	},
	{
		displayName: "Lithography",
	},
	{
		displayName: "Screen Printing",
	},
	{
		displayName: "Wood Cut",
	},
	{
		displayName: "Graffiti",
	},
	{
		displayName: "Mixed Media",
	},
	{
		displayName: "Other",
	},
	{
		displayName: "Photography",
	},
	{
		displayName: "Sculpture",
	},
	{
		displayName: "Spray Paint",
	},
	{
		displayName: "Lino Print",
	},
];

export const artworkMediums = [
	{
		enum: Artwork_Medium.DRAWING_CHARCOAL,
		displayName: "Charcoal",
		type: "Drawing",
	},
	{
		enum: Artwork_Medium.DRAWING_MARKERS,
		displayName: "Markers",
		type: "Drawing",
	},
	{
		enum: Artwork_Medium.DRAWING_PASTELS,
		displayName: "Pastels",
		type: "Drawing",
	},
	{
		enum: Artwork_Medium.DRAWING_PENCIL,
		displayName: "Pencil",
		type: "Drawing",
	},
	{
		enum: Artwork_Medium.DRAWING_PEN_AND_INK,
		displayName: "Pen & Ink",
		type: "Drawing",
	},
	{
		enum: Artwork_Medium.PAINTING_ACRYLIC,
		displayName: "Acrylic",
		type: "Painting",
	},
	{
		enum: Artwork_Medium.PAINTING_GOUACHE,
		displayName: "Gouache",
		type: "Painting",
	},
	{
		enum: Artwork_Medium.PAINTING_OIL,
		displayName: "Oil",
		type: "Painting",
	},
	{
		enum: Artwork_Medium.PAINTING_TEMPERA,
		displayName: "Tempera",
		type: "Painting",
	},
	{
		enum: Artwork_Medium.PAINTING_WATERCOLOR,
		displayName: "Water Color",
		type: "Painting",
	},
	{
		enum: Artwork_Medium.PRINT_ETCHING,
		displayName: "Etching",
		type: "Print",
	},
	{
		enum: Artwork_Medium.PRINT_LITHOGRAPHY,
		displayName: "Lithography",
		type: "Print",
	},
	{
		enum: Artwork_Medium.PRINT_SCREEN_PRINTING,
		displayName: "Screen Printing",
		type: "Print",
	},
	{
		enum: Artwork_Medium.PRINT_WOOD_CUT,
		displayName: "Wood Cut",
		type: "Print",
	},
	{
		enum: Artwork_Medium.OTHER_GRAFFITI,
		displayName: "Graffiti",
		type: "Other",
	},
	{
		enum: Artwork_Medium.OTHER_MIXED_MEDIA,
		displayName: "Mixed Media",
		type: "Other",
	},
	{
		enum: Artwork_Medium.OTHER_OTHER,
		displayName: "Other",
		type: "Other",
	},
	{
		enum: Artwork_Medium.OTHER_PHOTOGRAPHY,
		displayName: "Photography",
		type: "Other",
	},
	{
		enum: Artwork_Medium.OTHER_SCULPTURE,
		displayName: "Sculpture",
		type: "Other",
	},
	{
		enum: Artwork_Medium.OTHER_SPRAY_PAINT,
		displayName: "Spray Paint",
		type: "Other",
	},
	{
		enum: Artwork_Medium.OTHER_LINO_PRINT,
		displayName: "Lino Print",
		type: "Other",
	},
];

export const styleItems = [
	{
		displayName: "Unspecified",
	},
	{
		displayName: "Abstract",
	},
	{
		displayName: "Art Deco",
	},
	{
		displayName: "Nouveau",
	},
	{
		displayName: "Baroque",
	},
	{
		displayName: "Baroque",
	},
	{
		displayName: "Conceptual",
	},
	{
		displayName: "Cubism",
	},
	{
		displayName: "Dadaism",
	},
	{
		displayName: "Expressionism",
	},
	{
		displayName: "Fauvism",
	},
	{
		displayName: "Futurism",
	},
	{
		displayName: "Gothic",
	},
	{
		displayName: "Impressionism",
	},
	{
		displayName: "Landscape",
	},
	{
		displayName: "Minimalism",
	},
	{
		displayName: "Pop Art",
	},
	{
		displayName: "Portrait",
	},
	{
		displayName: "Post Modernism",
	},
	{
		displayName: "Realism",
	},
	{
		displayName: "Renaissance",
	},
	{
		displayName: "Rococo",
	},
	{
		displayName: "Romanticism",
	},
	{
		displayName: "Surrealism",
	},
];

export const artworkStyles = [
	{
		enum: Artwork_Style.STYLE_UNSPECIFIED,
		displayName: "Unspecified",
	},
	{
		enum: Artwork_Style.ABSTRACT,
		displayName: "Abstract",
	},
	{
		enum: Artwork_Style.ART_DECO,
		displayName: "Art Deco",
	},
	{
		enum: Artwork_Style.ART_NOUVEAU,
		displayName: "Nouveau",
	},
	{
		enum: Artwork_Style.BAROQUE,
		displayName: "Baroque",
	},
	{
		enum: Artwork_Style.CONCEPTUAL,
		displayName: "Conceptual",
	},
	{
		enum: Artwork_Style.CUBISM,
		displayName: "Cubism",
	},
	{
		enum: Artwork_Style.DADAISM,
		displayName: "Dadaism",
	},
	{
		enum: Artwork_Style.EXPRESSIONISM,
		displayName: "Expressionism",
	},
	{
		enum: Artwork_Style.FAUVISM,
		displayName: "Fauvism",
	},
	{
		enum: Artwork_Style.FUTURISM,
		displayName: "Futurism",
	},
	{
		enum: Artwork_Style.GOTHIC,
		displayName: "Gothic",
	},
	{
		enum: Artwork_Style.IMPRESSIONISM,
		displayName: "Impressionism",
	},
	{
		enum: Artwork_Style.LANDSCAPE,
		displayName: "Landscape",
	},
	{
		enum: Artwork_Style.MINIMALISM,
		displayName: "Minimalism",
	},
	{
		enum: Artwork_Style.POP_ART,
		displayName: "Pop Art",
	},
	{
		enum: Artwork_Style.PORTRAIT,
		displayName: "Portrait",
	},
	{
		enum: Artwork_Style.POST_MODERNISM,
		displayName: "Post Modernism",
	},
	{
		enum: Artwork_Style.REALISM,
		displayName: "Realism",
	},
	{
		enum: Artwork_Style.RENAISSANCE,
		displayName: "Renaissance",
	},
	{
		enum: Artwork_Style.ROCOCO,
		displayName: "Rococo",
	},
	{
		enum: Artwork_Style.ROMANTICISM,
		displayName: "Romanticism",
	},
	{
		enum: Artwork_Style.SURREALISM,
		displayName: "Surrealism",
	},
];
