import React from "react";

interface ArtworkCommentSkeletonProps {
    isLoading: boolean;
}

export const ArtworkCommentSkeleton: React.FC<ArtworkCommentSkeletonProps> = ({ isLoading }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className="bg-greyscale-700 flex pt-2 pb-2 items-center rounded-xl">
            {/* Avatar */}
            <div className="relative mr-4 w-12 h-12 flex-shrink-0">
                <div className="absolute top-0 left-0 h-full w-full rounded-full bg-greyscale-550 animate-pulse"></div>
            </div>

            {/* Content */}
            <div className="flex flex-col justify-center max-w-[calc(100%-1rem)]">
                <span className="bg-greyscale-550 rounded-full w-24 h-4 animate-pulse mb-1.5"></span>
                <div className="bg-greyscale-550 rounded-full w-[calc(100%+4rem)] h-4 animate-pulse"></div>
            </div>
        </div>
    );
};