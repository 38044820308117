import { Modal } from "antd";
import { useMessage } from "components/Messages/MessageProvider";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticationService } from "services/authentication";
import { ReactComponent as AlignCenter } from "../../assets/icons/align-center.svg";
import { ReactComponent as AtSign } from "../../assets/icons/at-sign.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as GreyBank } from "../../assets/icons/GreyBank.svg";
import { ReactComponent as InfoCircle } from "../../assets/icons/info-circle.svg";
import Header from "../../components/Headers/Header";
import { useAuth } from "../../hooks/useAuth";
import { RootState } from "../../store";

export default function Settings() {
	const navigate = useNavigate();
	const { errorMessage, successMessage } = useMessage();
	const [isModalOpen, setIsModalOpen] = useState(false);

	//currently logged in user
	const currentUser = useSelector((state: RootState) => state.user.user);
	const logoutFunc = async () => {
		try {
			await authenticationService.signOut?.();
			navigate("/landing");
		} catch (error) {
			errorMessage("There was a problem logging out");
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	//prints the top statement if artist, and the bottom if collector
	const userRoleTitle =
		currentUser?.role === 3 ? "Bank Account and Location" : "Locations ";

	const userRoleDescription =
		currentUser?.role === 3
			? "Make collection of your artwork and payment to you easy. Add or change your bank and location."
			: "Make checkout easy. Add or change your delivery addresses.";

	useEffect(() => {
		document.title = "Settings | Artbeat ";
	}, []);

	return (
		<div>

			<Header title={"Settings"} onBack={()=> navigate(`/@/${currentUser?.username}`)} />
			<div className="flex-col mx-3 my-4 gap-4 pt-12">
				<button
					onClick={() => {
						navigate("/settings/your_account");
						console.log(currentUser.role);
					}}
					className="settings-btn-xxl"
				>
					<AtSign className="h-8 w-8" />
					<div className="settings-btn-content">
						<h5 className="settings-btn-title">Your Account</h5>
						<h5 className="settings-btn-description">
							Change or add your username, email or phone number.
						</h5>
					</div>
					<ChevronRight className="w-6.5 h-6.5" />
				</button>

				<button
					onClick={() => {
						navigate("/settings/bank_and_location");
					}}
					className="settings-btn-xxl"
				>
					<GreyBank className="h-14 w-14" />

					<div className="settings-btn-content">
						<h5 className="settings-btn-title">{userRoleTitle}</h5>
						<h5 className="settings-btn-description">
							{userRoleDescription}
						</h5>
					</div>
					<ChevronRight className="w-11 h-11" />
				</button>

				<button
					className="settings-btn-xxl"
					onClick={() => {
						navigate("/terms_and_conditions");
					}}
				>
					<AlignCenter className="h-10 w-10" />
					<div className="settings-btn-content">
						<h5 className="settings-btn-title">
							Artbeat Terms and Conditions
						</h5>
						<h5 className="settings-btn-description">
							All you need to know about our terms and conditions
							and your privacy.
						</h5>
					</div>
					<ChevronRight className="w-8 h-8" />
				</button>

				<div className="flex items-center gap-5 flex-1 flex-grow ml-2">
					<button
						className="bg-transparent border-none"
						onClick={showModal}
					>
						<InfoCircle className="text-white" />
					</button>

					<Modal
						title="Please reach out"
						open={isModalOpen}
						onOk={handleOk}
						onCancel={handleCancel}
						className="font-inter text-white text-base not-italic font-normal mt-80"
						footer={null}
					>
						<section>
							Got queries, quips, or quirks? Send us an email. Our
							charming support team is on standby, eager to win
							your heart (or at least help out)
						</section>
					</Modal>
					<div className="settings-btn-content">
						<h5 className="settings-btn-title">Contact Support</h5>
						<h5 className="settings-btn-description">
							support@artbeat.ink
						</h5>
					</div>
				</div>
				<a
					onClick={logoutFunc}
					className="fixed bottom-[4rem] font-inter font-semibold left-1/2 transform -translate-x-1/2 z-10 text-red-500"
				>
					Log Out
				</a>
			</div>
		</div>
	);
}
