import ArtworkPage from "pages/Artwork/Artwork";
import CheckoutPage from "pages/Checkout/Checkout";
import EditProfilePage from "pages/EditProfile/EditProfile";
import HomePage from "pages/Home/Home";
import HomeLayout from "pages/Home/HomeLayout/HomeLayout";
import LandingPage from "pages/Landing/Landing";
import LoginPage from "pages/Login/Login";
import NetworkPage from "pages/Network/Network";
import OnboardingPage from "pages/Onboarding/Onboarding";
import PaymentResultPage from "pages/PaymentResultPage/PaymentResultPage";
import ProfilePage from "pages/Profile/Profile";
import SearchPage from "pages/Search/Search";
import BankAndLocation from "pages/Settings/Components/Bank_and_location";
import TermsAndConditions from "pages/Terms and Conditions/Terms_and_conditions";
import Settings from "pages/Settings/Settings";
import UpdateEmail from "pages/Settings/YourAccount/UpdateEmail/UpdateEmail";
import UpdatePassword from "pages/Settings/YourAccount/UpdatePassword/UpdatePassword";
import UpdatePhoneNumber from "pages/Settings/YourAccount/UpdatePhoneNumber/UpdatePhoneNumber";
import UpdateUsername from "pages/Settings/YourAccount/UpdateUsername/UpdateUsername";
import YourAccount from "pages/Settings/YourAccount/YourAccount";
import SignUpPage from "pages/SignUp/SignUp";
import ArtworkDetails from "pages/Upload/ArtworkDetails/ArtworkDetails";
import ArtworkSummary from "pages/Upload/ArtworkSummary/ArtworkSummary";
import SetPrice from "pages/Upload/SetPrice/SetPrice";
import UploadProvider from "pages/Upload/UploadContext/UploadContext";
import VerifyEmailPage from "pages/VerifyEmail/VerifyEmail";
import EmailVerifiedPage from "pages/EmailVerified/EmailVerified";
import Prints from "pages/Prints/prints";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export default function UserNavigation() {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/home/search" replace />} />
			<Route path="/landing" element={<LandingPage />}></Route>
			<Route path="/login" element={<LoginPage />}></Route>
			<Route path="/signup" element={<SignUpPage />}></Route>
			<Route path="/home" element={<HomeLayout showHeader={true} />}>
				<Route path="" element={<HomePage />} />
			</Route>
			<Route path="/home" element={<HomeLayout showHeader={false} />}>
				<Route path="/home/search" element={<SearchPage />} />
			</Route>
			<Route path="/@" element={<HomeLayout showHeader={false} />}>
				<Route path=":username" element={<ProfilePage />} />
				<Route
					path=":username/network/:followTab"
					element={<NetworkPage />}
				/>
			</Route>
			<Route path="/artworks/:artworkID" element={<ArtworkPage />} />
			<Route
				path="/terms_and_conditions"
				element={<TermsAndConditions />}
			/>
			<Route path="/prints" element={<Prints />}></Route>
			<Route path="/checkout/:artworkID" element={<CheckoutPage />} />
			<Route
				path="/payments/:paymentId"
				element={<PaymentResultPage />}
			/>
			<Route path="*" element={<Navigate to="/home" replace />} />
			<Route path="/verify-email" element={<VerifyEmailPage />}></Route>
			<Route path="/emailverified" element={<EmailVerifiedPage />} />
			<Route path="/onboarding" element={<OnboardingPage />}></Route>
			<Route path="/home" element={<HomeLayout showHeader={false} />}>
				<Route
					path="/home/artworkDetails"
					element={
						<UploadProvider>
							<ArtworkDetails />
						</UploadProvider>
					}
				/>
				<Route
					path="/home/artworkDetails/setPrice"
					element={
						<UploadProvider>
							<SetPrice />
						</UploadProvider>
					}
				/>
				<Route
					path="/home/artworkDetails/setPrice/artworkSummary"
					element={
						<UploadProvider>
							<ArtworkSummary />
						</UploadProvider>
					}
				/>
			</Route>
			<Route path="/settings">
				<Route path="" element={<Settings />} />
				<Route
					path="/settings/your_account"
					element={<YourAccount />}
				/>
				<Route
					path="/settings/your_account/update_username"
					element={<UpdateUsername />}
				/>
				<Route
					path="/settings/your_account/update_password"
					element={<UpdatePassword />}
				/>
				<Route
					path="/settings/your_account/update_phone_number"
					element={<UpdatePhoneNumber />}
				/>
				<Route
					path="/settings/your_account/update_email"
					element={<UpdateEmail />}
				/>
				<Route
					path="/settings/bank_and_location"
					element={<BankAndLocation />}
				/>
			</Route>
			<Route path="/editProfile" element={<EditProfilePage />} />
		</Routes>
	);
}
