import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { MessageProvider } from "components/Messages/MessageProvider";
import "config/firebase";
import { AuthProvider } from "hooks/useAuth";
import { LocationAndRatesProvider } from "hooks/useLocation";
import RootNavigation from "navigation";
import MobileFirst from "pages/MobileFirst/MobileFirst";
import TermsAndConditions from "pages/Terms and Conditions/Terms_and_conditions";
import React, { useEffect, useState } from "react";
import "./App.scss";
import RootLayout from "./components/RootLayout/RootLayout";
import "./index.css";
import "./services/logging/logging";

function App() {
	// used for deciding whether to render mobile or desktop version
	const [width, setWidth] = useState(window.innerWidth);
	const breakpoint = 450;

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		// subscribe to window resize event "onComponentDidMount"
		window.addEventListener("resize", handleResizeWindow);
		return () => {
			// unsubscribe "onComponentDestroy"
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	// Function to determine which component to render
	const renderComponent = () => {
		if (window.location.pathname === "/terms_and_conditions") {
			return <TermsAndConditions />;
		}
		if (width > breakpoint) {
			return <MobileFirst />;
		} else {
			return (
				<AuthProvider>
					<LocationAndRatesProvider>
						<MessageProvider>
							<RootLayout>
								<RootNavigation />
							</RootLayout>
						</MessageProvider>
					</LocationAndRatesProvider>
				</AuthProvider>
			);
		}
	};

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#FFFFFF",
					fontFamily: "'Ubuntu', inter",
					borderRadius: 5,
					colorTextBase: "#FFFFFF",
					colorBgBase: "#000000",
				},
			}}
		>
			{renderComponent()}
		</ConfigProvider>
	);
}

export default App;
