import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { authenticationService } from "../../services/authentication";
import { RootState } from "../../store";
import { LoadingPage } from "../Loading/Loading";

import BackgroundImage from "assets/images/Hero Background.jpg";

export default function LandingPage() {
	// Custom auth hook to easily get logged in user
	const { user } = useAuth();

	const [loaded, setLoaded] = useState(false);
	const [isImageLoaded, setImageLoaded] = useState(false);

	// The currently logged in user
	const currentUser = useSelector((state: RootState) => state.user.user);

	// React router hook to navigate between routes
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Welcome | Artbeat";
		authenticationService.userIsLoaded.subscribe((loaded) => {
			setLoaded(loaded);
		});
	}, []);

	useEffect(() => {
		// Pre-load the image as soon as the component mounts
		const img = new Image();
		img.src = BackgroundImage;
		img.onload = () => {
			setImageLoaded(true);
		};
	}, []);

	return (
		<div className="h-full w-full relative">
			<img
				src={BackgroundImage}
				alt="Hero Background"
				className="absolute top-0 left-0 w-full h-full object-cover z-0"
			/>

			<p className="font-ubuntu font-regular text-5xl z-10 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
				Artbeat
			</p>

			<div className="pb-14 px-4 h-full w-full z-10 relative flex flex-col justify-end">
				<div>
					<button
						className="btn btn-sm btn-primary w-full"
						onClick={() => navigate("/home/search")}
					>
						Get Started
					</button>
				</div>
			</div>
		</div>
	);
}
