export function timeSince(timestamp) {
    // Check if timestamp is undefined or doesn"t have a seconds property
    if (!timestamp || timestamp.seconds === undefined) {
      console.error("Invalid timestamp:", timestamp);
      return "just now";
    }
  
    const now = new Date();
    const commentDate = new Date(Number(timestamp.seconds) * 1000);
    const secondsPast = (now.getTime() - commentDate.getTime()) / 1000;
  
    if (secondsPast < 60) {
      return "just now";
    }
  
    if (secondsPast < 3600) {
      return `${Math.floor(secondsPast / 60)}m ago`;
    }
  
    if (secondsPast <= 86400) {
      return `${Math.floor(secondsPast / 3600)}h ago`;
    }
  
    if (secondsPast > 86400) {
      const day = Math.floor(secondsPast / 86400);
      return `${day}${day === 1 ? "d" : "d"} ago`;
    }
  }