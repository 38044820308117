import axios from "axios";
import { functions } from "config/firebase";
import { httpsCallable } from "firebase/functions";
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

interface ILocationAndRatesContext {
	location?: string;
	rate?: number;
	currency?: string;
}

const LocationAndRatesContext = createContext<ILocationAndRatesContext>({});

export const LocationAndRatesProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [location, setLocation] = useState(null);
	const [rate, setRate] = useState(1);
	const [currency, setCurrency] = useState(null);

	useEffect(() => {
		axios
			.get("https://ipapi.co/json/")
			.then((response) => {
				const data = response.data;
				setLocation(data.country_code_iso3);
			})
			.catch((error) => {
				console.log(error);
			});

		if (location && location != "ZAF") {
			console.log("Not SA");
			// Get the exhcnage rate for USD
			setCurrency("USD");

			const getRandToDollarRate = httpsCallable(
				functions,
				"getRandToDollarRate"
			);
			getRandToDollarRate().then(
				(result: { data: { exchangeRate: number } }) => {
					// Read result of the Cloud Function.
					/** @type {any} */
					const data = result.data;
					setRate(data.exchangeRate);
				}
			);
		} else {
			setCurrency("ZAR");
			setRate(1);
		}
	}, [location]);

	const value = useMemo(() => {
		return {
			location,
			rate,
			currency,
		};
	}, [location, rate, currency]);

	return (
		<LocationAndRatesContext.Provider value={value}>
			{children}
		</LocationAndRatesContext.Provider>
	);
};

export const useLocationAndRates = () => {
	return useContext(LocationAndRatesContext);
};
