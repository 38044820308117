import React, { useEffect, useState } from "react";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as XClose } from "../../../assets/icons/x-close.svg";
import { InfoSelectButton } from "../../../components/Buttons/InfoSelectButton";

/**
 * SelectorDropdown Component
 *
 * A reusable dropdown component designed to display a list of options, an icon, and a selected option. It's customizable and can be used throughout the application.
 * This component also includes an "X" close button to deselect the selected option.
 *
 * @param {string} text - The placeholder text to display when no option is selected.
 * @param {React.ReactNode} iconComponent - The icon component to display next to the placeholder text.
 * @param {DropdownOption[]} options - An array of options for the dropdown list. Each option should contain a 'displayName' property.
 * @param {(selectedOption: string) => void} onOptionSelected - A callback function to be executed when an option is selected.
 * @param {string} [name] - Optional name attribute for the hidden input element that stores the selected option value.
 * @param {string} [selectedValue] - Optional Variable to be able to updateUI from parent component by passsing in value
 *
 * To use an enum for the options, define the enum and then map it to the 'options' prop:
 *
 * Example Enum:
 * enum MyOptions {
 *   Option1 = 'Option1',
 *   Option2 = 'Option2',
 * }
 *
 * Example Usage:
 * <SelectorDropdown
 *   text="Select an Option"
 *   iconComponent={<BankIcon />}
 *   options={Object.values(MyOptions).map(option => ({ displayName: option }))}
 *   onOptionSelected={handleOptionSelected}
 * />
 */

// Interface for props
interface UnitsDropdownProps
	extends React.SelectHTMLAttributes<HTMLSelectElement> {
	text: string;
	iconComponent?: React.ReactNode;
	options: DropdownOption[];
	onOptionSelected: (selectedOption: string) => void;
	name?: string;
	selectedValue?: string;
}

interface DropdownOption {
	displayName: string;
}

// SelectorDropdown component made to be reusable
export const UnitsDropdown = ({
	text,
	iconComponent,
	options,
	onOptionSelected,
	name,
	selectedValue,
}: UnitsDropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleDropdown = () => setIsOpen(!isOpen);
	const [selectedOption, setSelectedOption] = useState<string | null>(
		selectedValue || null
	);
	// Handles each line item of dropdown
	const handleOptionClick = (option: string) => {
		onOptionSelected(option);
		setSelectedOption(option);
		toggleDropdown();
	};

	useEffect(() => {
		if (selectedValue) {
			setSelectedOption(selectedValue);
		}
	}, [selectedValue]);

	return (
		<div className="py-2 px-2 w-15 rounded-tr-lg rounded-br-lg border border-greyscale-600 relative">
			<input name={name} type="hidden" value={selectedOption || ""} />
			<button
				className={`flex px-1 items-center gap-2 self-stretch rounded-lg border-none bg-greyscale-600 w-full ${
					selectedOption ? "your-selected-class" : ""
				}`}
				onClick={toggleDropdown}
				type="button"
			>
				{iconComponent}
				<section className=" flex font-inter text-base font-normal not-italic leading-5 text-greyscale-450 ">
					{selectedOption || text}
				</section>
				<div className="">
					<ChevronDown />
				</div>
			</button>

			{isOpen && (
				<div className="py-2 -ml-2 absolute w-full">
					<ul className="px-4 items-center flex-col rounded-md bg-greyscale-600 font-inter text-base not-italic font-medium leading-6 list-none mt-2">
						{options.map((option) => (
							<li	className="py-2"
								key={option.displayName}
								onClick={() =>
									handleOptionClick(option.displayName)
								}
							>
								{option.displayName}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
