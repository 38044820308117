import { Layout, theme } from "antd";
import React from "react";

const { Content } = Layout;

export default function RootLayout({
	children,
}: {
	children?: React.ReactNode;
}) {
	const { token } = theme.useToken();

	return (
		<Layout>
				<Content
					className="w-screen flex justify-center items-center"
					style={{
						height: "100dvh",
					}}
				>
					<div
						className="container h-full max-w-md "
						style={{
							// height: "100dvh",
							backgroundColor: token.colorBgBase,
						}}
					>
						{children}
					</div>
				</Content>
		</Layout>
	);
}
