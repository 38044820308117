import { Hit } from "@algolia/client-search";

import { User } from "@hockney-app/proto/users/v1alpha1/users_pb";
import { List} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "services/api";
import { databaseService } from "services/database";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-md.svg";
import Header from "../../components/Headers/Header";
import UserListItem from "./Components/UserListItem";
import { useMessage } from "components/Messages/MessageProvider";

export default function NetworkPage() {
	// This will fetch the `followTab` value from the URL
	const { followTab } = useParams();

	// for navigation
	const navigate = useNavigate();

	// state of currently looked at user
	const [user, setUser] = useState<User>();
	const { username } = useParams();

	const {errorMessage, successMessage} = useMessage();  


	// State to hold either followers or following based on the active tab
	const [filteredUsers, setFilteredUsers] = useState<string[]>([]);

	// query states for user search
	const [query, setQuery] = useState("");
	const [userResults, setUserResults] = useState<Hit<any>[]>(
		[] as Hit<any>[]
	);

	// states to manage pagination in users search
	const [currentPage, setCurrentPage] = useState(0);
	const [upToDate, setUpToDate] = useState(false);

	// Function to increment currentPage
	const goToNextPage = () => {
		setCurrentPage((prevPage) => prevPage + 1);
	};

	// function to search users
	const searchUsers = useCallback(
		async (query: string, page?: number) => {
			try {
				const results = await databaseService.searchUsers(query, page);
				const filteredResults = results.hits.filter(
					(
						hit: any // casting to any
					) => filteredUsers.includes(hit.Name)
				);

				// Append new users to existing list
				setUserResults((prevResults) => [
					...prevResults,
					...filteredResults,
				]);

				// Check if all users have been fetched
				if (filteredResults.length < 15) {
					setUpToDate(true);
				}
			} catch (error) {
				errorMessage("There was a problem searching users");
			}
		},
		[filteredUsers]
	);

	// function to get the user object of the user being pointed to
	const getUser = useCallback(async () => {
		try {
			const users = await apiService.listUsers({
				pageSize: 1,
				filter: `username = "${username}"`,
			});
			if (users.users.length === 0) {
				errorMessage("User not found");
				return;
			}

			const user = users.users[0];
			if (!user) {
				errorMessage("User not found");
				return;
			}

			setUser(user);
		} catch (error) {
			errorMessage("There was a problem loading user");
		}
	}, [username]);

	useEffect(() => {
		console.log("search query");
		searchUsers(query, currentPage);
	}, [query, currentPage]);

	useEffect(() => {
		setCurrentPage(0);
		setUpToDate(false);
		setUserResults([]);
	}, [query]);

	useEffect(() => {
		getUser();
	}, [getUser]);

	useEffect(() => {
		if (followTab === "followers" && user) {
			setFilteredUsers(user.followers);
		} else if (followTab === "following" && user) {
			setFilteredUsers(user.following);
		}
	}, [followTab, user]);

	return (
		<div className="pb-20">
			<Header
				title={followTab === "followers" ? "Followers" : "Following"}
				onBack={() => navigate(-1)}
			/>

			{followTab === "followers" ? (
				<div>
					<div className="flex flex-row p-4 w-full gap-3">
						<div className="SearchBarBorder flex-grow">
							<div className="InnerSearchDiv">
								<SearchIcon />
								<div className="SearchInput">
									<input
										className="bg-transparent border-none w-full focus:outline-none"
										placeholder="Artists, Collectors & Community"
										onChange={(e) =>
											setQuery(e.target.value)
										}
										value={query}
									/>
								</div>
							</div>
						</div>
					</div>
					<InfiniteScroll
						dataLength={userResults.length}
						next={goToNextPage}
						hasMore={!upToDate}
						loader={<h4></h4>}
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b></b>
							</p>
						}
					>
						<List
							itemLayout="horizontal"
							dataSource={
								query === "" ? user?.followers : userResults
							} // Check if query is empty
							renderItem={(item) => {
								const name = query === "" ? item : item.Name;
								return (
									<UserListItem name={name}></UserListItem>
								);
							}}
						/>
					</InfiniteScroll>
				</div>
			) : (
				<div className="relative">
					<div className="flex flex-row p-4 w-full gap-3 sticky">
						<div className="SearchBarBorder flex-grow">
							<div className="InnerSearchDiv">
								<SearchIcon />
								<div className="SearchInput">
									<input
										className="bg-transparent border-none w-full focus:outline-none"
										placeholder="Artists, Collectors & Community"
										onChange={(e) =>
											setQuery(e.target.value)
										}
										value={query}
									/>
								</div>
							</div>
						</div>
					</div>
					<InfiniteScroll
						dataLength={userResults.length}
						next={goToNextPage}
						hasMore={!upToDate}
						loader={<h4></h4>}
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b></b>
							</p>
						}
					>
						<List
							locale={{ emptyText: "" }}
							style={{ height: "100%" }}
							itemLayout="horizontal"
							dataSource={
								query === "" ? user?.following : userResults
							} // Check if query is empty
							renderItem={(item) => {
								const name = query === "" ? item : item.Name;
								return (
									<UserListItem name={name}></UserListItem>
								);
							}}
						/>
					</InfiniteScroll>
				</div>
			)}
		</div>
	);
}
