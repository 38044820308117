import {ReactComponent as ChevronLeft} from "assets/icons/chevron-left.svg" ; 
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import FeedbackButton from "components/Buttons/FeedbackButton/FeedbackButton";

interface HeaderProps {
	title?: string;
	page?: string;
	onBack?: () => void;
}

const Header: React.FC<HeaderProps> = ({ title, page, onBack }) => {
	const navigate = useNavigate();

	const handleBack = () => {
		if (onBack) {
			onBack();
		} else if (page) {
			navigate(page);
		}
	};

	return (
		<div className="flex fixed w-full items-center justify-between bg-opacity-75 backdrop-blur-sm text-white h-14 pl-2 pr-2 py-2 top-0  z-10">
			<Button
				size="large"
				type="text"
				shape="circle"
				icon={<ChevronLeft className="text-white" />}
				className="text-white items-center justify-center"
				onClick={handleBack}
			/>
			<h1 className="text-center text-base font-medium font-ubuntu mx-auto mb-0">
				{title}
			</h1>
			<FeedbackButton />
		</div>
	);
};

export default Header;
