import React, { useEffect, useState } from "react";
import QRcode from "assets/images/frame.svg";
import coverPic from "assets/images/coverpic.jpg";
import { Typography } from "antd";

const { Text } = Typography;

export default function MobileFirst() {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
	  const handleResizeWindow = () => setWidth(window.innerWidth);
	  window.addEventListener("resize", handleResizeWindow);
	  return () => {
		window.removeEventListener("resize", handleResizeWindow);
	  };
	}, []);
  
	const navigateToExternalUrl = (url) => {
	  window.location.href = url;
	};
  

  return (
    <div className="bg-white min-h-screen flex flex-col relative">
      <div className="bg-black p-4 flex justify-between items-center">
        <Text className="text-white text-2xl sm:text-xl md:text-3xl">
          Artbeat
        </Text>
        <button
          className="text-white btn btn-primary btn-sm font-semibold py-2 px-4 text-sm sm:text-xs md:text-base"
          onClick={() => navigateToExternalUrl("https://artbeat.ink/")}
        >
          Visit our info site
        </button>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center px-4 py-8">
        <Text className="text-center text-black text-3xl sm:text-4xl md:text-[48px] font-bold">
          Explore Artbeat on mobile
        </Text>
        <div className="flex justify-center items-center w-full h-64 sm:h-1/2 lg:h-1/2">
          <img src={QRcode} alt="QR code to mobile site" className="h-full sm:w-1/2 md:w-1/2 lg:w-1/3 " />
        </div>
        <Text className="text-center text-black text-xl sm:text-4xl md:text-[48px] font-bold">
          Scan the QR Code
        </Text>
        <Text className="text-center text-black text-base sm:text-sm md:text-base mt-2">
          Open your camera, scan the code and tap the link
        </Text>
      </div>
      <img
        src={coverPic}
        alt="Splash of dark liquid"
        className="absolute bottom-0 right-0 w-1/4 sm:w-1/5 md:w-1/5 lg:w-1/4 xl:w-1/4"
      />
    </div>
  );
}