import React from "react";
import LoadingSpinner from "../LoadingSpinner/loadingSpinner";

/**
 * CustomButton Component
 *
 * A reusable button component that can be customized via props.
 *
 * @param {string} text - The text displayed on the button. Defaults to "Click Me".
 * @param {"button" | "submit" | "reset"} [type] - The type of button. Defaults to "button".
 * @param {"primary" | "secondary"} color - The color theme for the button.
 * @param {string} [enabledClasses] - Classes applied when the button is enabled.
 * @param {boolean} [disabled] - Whether the button is disabled. Defaults to false.
 * @param {"small" | "medium" | "large"} size - The size of the button.
 * @param {boolean} [loading] - Whether the button is in a loading state. Defaults to false.
 * @param {React.HTMLAttributes<HTMLButtonElement>} [style] - Optional inline styles for the button.
 *
 * Example Usage:
 *
 * <CustomButton
 *   text="Update Password"
 *   type="submit"
 *   color="primary"
 *   size="medium"
 *   enabledClasses="extra-class"
 * />
 *
 */
interface ButtonProps {
	text?: string;
	type?: "button" | "submit" | "reset";
	color: "primary" | "secondary";
	enabledClasses?: string;
	disabled?: boolean;
	size: "small" | "medium" | "large";
	loading?: boolean;
	style?: React.HTMLAttributes<HTMLButtonElement>;
	onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
	text = "Click Me",
	type = "button",
	color = "primary",
	disabled = false,
	size = "small",
	loading = false,
	style,
	onClick,
}) => {
	const classes = ["btn", "flex-1"];
	disabled
		? classes.push("btn-disabled")
		: color == "primary"
		? classes.push("btn-primary")
		: classes.push("btn-secondary");

	switch (size) {
		case "small":
			classes.push("btn-sm");
			break;
		case "medium":
			classes.push("btn-md");
			break;
		case "large":
			classes.push("btn-lg");
			break;
	}

	// Loop through classes and create the className
	let className = "";
	classes.forEach((c) => {
		className += c + " ";
	});

	return (
		<button
			style={style}
			type={type}
			className={className}
			onClick={onClick}
		>
			{loading ? LoadingSpinner("white") : text}
		</button>
	);
};

export default Button;
