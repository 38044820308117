import React from "react";
import FeedbackButton from "../../../components/Buttons/FeedbackButton/FeedbackButton";

export default function Header() {
	return (
		<div
			// This should be fixed at the top but disappear when the user scrolls down
			// and reappear when the user scrolls up
			className="sticky top-0 w-full z-[50] bg-[#000000] bg-opacity-75 backdrop-blur-sm h-14 pl-4 pr-2 py-4"
		>
			<div className="flex flex-row items-center content-center justify-between h-full">
				<div className="font-medium font-ubuntu text-lg text-white py-2">Artbeat</div>
				<FeedbackButton />
			</div>
		</div>
	);
}
