import React from "react";
import { ReactComponent as SignatureVerified } from "assets/icons/signatureverified.svg"; 
import { ReactComponent as Shield } from "assets/icons/shield-tick.svg"; 
import { ReactComponent as Truck } from "assets/icons/truck-02.svg"; 
export default function FeatureBar() {
    return (
        <div className="flex justify-between items-start self-stretch">
            <div className="flex items-center">
                {/* Replace with your actual icon */}
               <SignatureVerified></SignatureVerified>
                <span className=" ml-1 text-body-xs text-greyscale-400">Verified Artists</span>
            </div>

            <div className="flex items-center">
                {/* Replace with your actual icon */}
                <Shield/> 
                <span className=" ml-1 text-body-xs text-greyscale-400">Quality Assured</span>
            </div>

            <div className="flex items-center">
                {/* Replace with your actual icon */}
                <Truck/> 
                <span className=" ml-1 text-body-xs text-greyscale-400">Free Delivery</span>
            </div>
        </div>
    );
}