import React, {useEffect} from "react";
import Header from "components/Headers/Header";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();


	useEffect(() => {
		document.title = "Ts and Cs | Artbeat ";
	}, []); 

  return (
    <div>
      <Header title="Terms and Conditions " onBack={() => navigate(-1)} />
      <div className="flex w-full flex-col justify-center items-center text-white px-4 md:px-20 lg:px-50 xl:px-80 pt-16 bg-black">
        <h1><strong>Artbeat Terms and Conditions and Privacy Policies </strong></h1>
        <p className="text-white">The terms and conditions for both artists and collectors are defined separately below, followed by a non-disclosure agreement that is applicable to all users on the platform.</p>

        <h2 className="text-white">Artbeat Terms and Conditions for Collectors </h2>

        <h3 className="text-white"> <strong> 1. Introduction </strong></h3>
        <p className="text-white">
        Welcome to Artbeat, a social media and e-commerce platform for the buying and selling of physical art. By using our platform, you agree to these Terms and Conditions. Please read them carefully.
        </p>
        <br />

        <h3 className="text-white"> <strong> 2. Definitions </strong></h3>
        {/* Definitions */}
        <p className="text-white">
          <strong>Artbeat:</strong> A social media and e-commerce platform that serves as an intermediary for the buying and selling of physical art.
        </p>
        <p className="text-white">
          <strong>Artwork(s):</strong> Collectively means Physical Arts and Prints or either of them as the context may indicate.
        </p>
        <p className="text-white">
          <strong>Artist:</strong> An artist who has registered on this platform for the purpose of selling their Artwork.
        </p>
        <p className="text-white">
          <strong>Collector:</strong> A user who has registered on the Artbeat platform with the intention to follow artists and other collectors, and to purchase Artworks.
        </p>
        <p className="text-white">
          <strong>Physical Art(s):</strong> Original, tangible works of art that are created by the artists and offered for sale on the Artbeat platform.
        </p>
        <p className="text-white">
          <strong>Print(s):</strong> A high-quality digital reproduction of a Physical Art that is then printed onto a new material like paper or canvas.
        </p>
        <p className="text-white">
          <strong>Commission:</strong> The 10% fee that Artbeat shall earn in respect of the sale of each Artwork.
        </p>
        <p className="text-white">
          <strong>Delivery Costs:</strong> The costs associated with delivering the purchased Artwork to the Collector, which are added to the final price and charged to the Collector.
        </p>
        <p className="text-white">
          <strong>Third Party Payment Gateway:</strong> A service that processes credit and debit card transactions for online business. This service is used to facilitate transactions on the Artbeat platform.
        </p>
        <p className="text-white">
          <strong>Cooling-off Period:</strong> The five business day period after a contract is concluded during which a consumer has the right to cancel the contract and receive a refund, as per the Consumer Protection Act to the extent applicable.
        </p>
        <p className="text-white">
          <strong>Dispute:</strong> Any disagreement or conflict that arises between the Collector and the seller regarding a transaction on the Artbeat platform.
        </p>
        <p className="text-white">
          <strong>Non-Disclosure Agreement (NDA):</strong> An agreement that restricts both users and Artbeat from disclosing certain information.
        </p>

        {/* 3. Artbeat's Role */}
<h3 className="text-white"> <strong> 3. Artbeat&apos;s Role </strong></h3>
<p className="text-white">
  Artbeat acts as an intermediary between the artist (seller) and the Collector. Artbeat is responsible for posting and selling Artworks on behalf of the Artists. In respect of its services as provided for herein, Artbeat shall earn an agreed commission equal to 10% of the selling price of each Artwork (exclusive of VAT), which amount shall be added to the purchase price and shall be paid by the Collector, in addition to the cost of delivery and the transaction fee of the Third Party Payment Gateway, which sum shall also be paid by the Collector.
</p>
<p className="text-white">
  The Collector hereby agrees that any transaction concluded between them pursuant to the services contemplated in these Terms and Conditions shall constitute an independent sale agreement between the Artist and the Collector. Accordingly, the Collector hereby indemnifies and holds Artbeat in respect of any claim, loss, damage or harm which may be suffered by the Collector as a result of sale transaction concluded between the Artist and the Collector pursuant to these Terms and Conditions.
</p>
<br />

{/* 4. Artwork Purchase and Removal */}
<h3 className="text-white"> <strong> 4. Artwork Purchase and Removal </strong></h3>
<p className="text-white">
  The first Collector to respond to the sale of an Artwork has the right to purchase that Artwork. Artbeat reserves the right, in its sole and absolute discretion, to remove any Artwork from the platform at any time, including Artworks that have been purchased and/or are in the possession of a Collector or another Artist.
</p>
<br />

{/* 5. Disputes and Refunds */}
<h3 className="text-white"> <strong> 5. Disputes and Refunds </strong></h3>
<p className="text-white">
  Any disputes must be handled by the parties involved in the sale and purchase of the Artwork (i.e., the artist and the collector), and shall not involve Artbeat, except during the 5-day cooling-off period. During this period, a refund may be offered to the Collector, and Artbeat will ensure this occurs in terms of the provisions of the Consumer Protection Act.
</p>
<br />

{/* 6. Chargeback Policy */}
<h3 className="text-white"> <strong> 6. Chargeback Policy </strong></h3>
<ul className="text-white pl-4">
  <li>A chargeback is a reversal of a transaction and occurs when a Collector who has purchased an Artwork approaches its issuing bank and disputes a transaction made through a payment system for various reasons including fraudulent, unlawful or suspicious transactions, goods and/or services not having been delivered, actual or suspected lack of authorisation or processing errors.</li> <br/>
  <li>Artbeat administers payments in respect of art sold and bought on the platform through an online payment system.</li> <br/>
  <li>A Collector who wishes to dispute a transaction must do so within 30 days of the transaction date.</li> <br/>
  <li>A Collector who requests a chargeback must submit all the relevant and necessary documents in relation to such transaction before the chargeback can be processed.</li> <br/>
  <li>Artbeat reserves the right to hold the chargeback amount until the investigation has been completed.</li> <br/>
  <li>If at the end of the chargeback investigation process, it is ascertained that there is no valid reason for the chargeback, the Collector will not be entitled to receive such amount.</li>
</ul> 
<br />

{/* 7. Privacy and Data Protection Policy */}
<h3 className="text-white"> <strong> 7. Privacy and Data Protection Policy </strong></h3>
{/* Subsections for Privacy Policy */}
<h4 className="text-white">1. What information do we collect?</h4>
<ul className="text-white pl-4">
  1.1. Artbeat handles user data in compliance with the Protection of Personal Information (POPI) Act of South Africa. Users must request any changes to their personal information or any actions on the platform, such as, but not limited to, deleting a post, changing personal information, or deleting their account, from Artbeat, in writing, delivered to Artbeat. Users do not have access to any information held by Artbeat, including backend databases.<br/><br/>
  1.2. We collect information from artists and users when they register, with or use our site.<br/><br/>
  1.3. We also collect information when you visit our site, via cookies and via web stats programs.<br/><br/>
  1.4. We collect technical information such as your IP address, Web browser, and other information.<br/><br/>
  1.5. When registering on our site, as appropriate, you may also be asked to provide your name, date of birth, image, gender, e-mail address, phone number, biography and banking details. You may, however, visit our site anonymously.<br/><br/>
</ul>
<h4 className="text-white">2. What do we use your information for?</h4>
<ul className="text-white pl-4">
  2.2. To administer a contest, promotion, survey or other site features. <br/><br/>
  2.3. To keep track of the usage and user experience. <br/><br/>
  2.4. To target ads to your interests, browsing history or profile. <br/><br/>
  2.5. To give the audience a brief background about the Artists. <br/><br/>
  2.6. For marketing purposes. <br/><br/>
  2.7. We may share to third parties for marketing or other business purposes without your consent. <br/><br/>
</ul>
<h4 className="text-white">3. How do we protect your information?</h4>
<p className="text-white">
  We implement web security measures to maintain the safety of information we collect.
</p>
<h4 className="text-white">4. Do we disclose any information to outside parties?</h4>
<p className="text-white">
  We may disclose your information to third parties when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety. Visitor information may also be provided to other parties for marketing, advertising, or other business purposes.
</p>
<h4 className="text-white">5. Third party links</h4>
<p className="text-white">
  Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.
</p>
<br />
{/* 6. Your rights */}
<h3 className="text-white"> <strong> 6. Your Rights </strong></h3>
<ul className="text-white pl-4">
  6.1. YOU HAVE THE RIGHT TO REQUEST A COPY OF THE PERSONAL INFORMATION WE HOLD ABOUT YOU. TO DO THIS, SIMPLY CONTACT US AT THE NUMBERS/ADDRESSES LISTED BELOW AND SPECIFY WHAT INFORMATION YOU WOULD LIKE. WE RESERVE THE RIGHT TO CONFIRM YOUR IDENTITY BEFORE PROVIDING DETAILS OF YOUR PERSONAL INFORMATION.
  <br/><br/>
  6.2. IN CERTAIN CASES YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL INFORMATION. YOU HAVE THE RIGHT TO ASK US TO UPDATE, CORRECT OR DELETE YOUR PERSONAL INFORMATION. YOU MAY REVIEW AND CHANGE YOUR PERSONAL INFORMATION OR BY CONTACTING US AT THE NUMBERS/ADDRESSES PROVIDED BELOW.
</ul>
<br />

{/* 7. Your consent */}
<h3 className="text-white"> <strong> 7. Your Consent </strong></h3>
<p className="text-white">
  By using our site, you consent to our online privacy policy.
</p>
<br />

{/* 8. Changes to our privacy policy */}
<h3 className="text-white"> <strong> 8. Changes to Our Privacy Policy </strong></h3>
<ul className="text-white pl-4">
8.1. WE RESERVE THE RIGHT TO CHANGE OUR PRIVACY POLICY AT ANY TIME. IF WE DO THIS, WE WILL POST THOSE CHANGES ON THIS WEBSITE AND WILL INDICATE THE DATE THIS POLICY WAS LAST REVISED. <br/><br/>
8.2. ANY REVISIONS TO THIS PRIVACY POLICY WILL BECOME EFFECTIVE ON THE FIRST TIME YOU ACCESS OR USE THE SERVICE AFTER ANY SUCH CHANGES. IF YOU DO NOT AGREE TO ABIDE BY THIS OR ANY FUTURE PRIVACY POLICY, DO NOT USE, ACCESS OR PARTICIPATE IN (OR CONTINUE TO USE, ACCESS OR PARTICIPATE IN) THIS WEBSITE. WE ENCOURAGE YOU TO PERIODICALLY REVIEW THIS PAGE FOR THE LATEST INFORMATION ON OUR PRIVACY PRACTICES. <br/>
</ul>
<p className="text-white">
  This privacy policy was last updated on 7 December 2023. If you have any questions, contact us at support@artbeat.ink.
</p>
<br />

{/* 8. Non-Disclosure Agreement (NDA) */}
<h3 className="text-white"> <strong> 8. Non-Disclosure Agreement (NDA) </strong></h3>
<p className="text-white">
  By using this platform, the user agrees to the terms and conditions of Artbeat&apos;s Non-Disclosure Agreement, a copy of which is attached hereto and signed by the parties simultaneously with the signing and acceptance of these terms and conditions. You agree not to disclose any information regarding Artbeat&apos;s operations, including information about its backend databases.
</p>
<br />

{/* 9. Changes to the Terms and Conditions */}
<h3 className="text-white"> <strong> 9. Changes to the Terms and Conditions </strong></h3>
<p className="text-white">
  Artbeat reserves the right to modify these Terms and Conditions at any time. Changes to these Terms and Conditions will become effective upon such changes being posted to this website. It is the user&apos;s obligation to periodically check these Terms and Conditions at the website for changes or updates. The User&apos;s continued use of this website following the posting of changes or updates will be considered notice of the User&apos;s acceptance to abide by and be bound by these Terms and Conditions, including such changes or updates.
</p>
<br />

{/* 10. Governing Law */}
<h3 className="text-white"> <strong> 10. Governing Law </strong></h3>
<p className="text-white">
  These Terms and Conditions are governed by the laws of South Africa, including, but not limited to the Protection of Personal Information (POPI) Act, South African Copyright Law, and the Consumer Protection Act South Africa Refund Policy.
</p>
<br />

{/* Artbeat Terms And Conditions For Artists */}
<h2 className="text-white">Artbeat Terms and Conditions for Artists</h2>

{/* 1. Introduction */}
<h3 className="text-white"> <strong> 1. Introduction </strong></h3>
<p className="text-white">
  Welcome to Artbeat, a social media and e-commerce platform for the buying and selling of physical art. By using our platform, you agree to these Terms and Conditions. Please read them carefully.
</p>
<br />

{/* 2. Definitions */}
<h3 className="text-white"> <strong> 2. Definitions </strong></h3>
<ul className="text-white">
  <li><strong>Artbeat:</strong> A social media and e-commerce platform that serves as an intermediary for the buying and selling of physical art.</li><br/>
  <li><strong>Artist:</strong> A user who has registered on the Artbeat platform with the intention to post and offer for sale their original Artworks.</li><br/>
  <li><strong>Artwork(s):</strong> Collectively means Physical Arts and Prints or either of them as the context may indicate.</li><br/>
  <li><strong>Physical Art:</strong> Original, tangible works of art that are created by the artists and offer for sale on the Artbeat platform.</li><br/>
  <li><strong>Print(s):</strong> A high-quality digital reproduction of a Physical Art that is then printed onto a new material like paper or canvas.</li><br/>
  <li><strong>Commission:</strong> The 10% fee that Artbeat shall earn in respect of the sale of each Artwork posted on the Artbeat platform.</li><br/>
  <li><strong>Delivery Costs:</strong> The costs associated with delivering the purchased Artwork to the Collector, which are added to the final price and charged to the Collector.</li><br/>
  <li><strong>Third Party Payment Gateway:</strong> A service that processes credit card transactions for online business. This service is used to facilitate transactions on the Artbeat platform.</li><br/>
  <li><strong>Dispute:</strong> Any disagreement or conflict that arises between the Collector and the seller regarding a transaction on the Artbeat platform.</li><br/>
  <li><strong>Non-Disclosure Agreement (NDA):</strong> An agreement that restricts both users and Artbeat from disclosing certain information.</li><br/>
</ul>
<br />

{/* 3. Artbeat's Role */}
<h3 className="text-white"> <strong> 3. Artbeat&apos;s Role </strong></h3>
<p className="text-white">
  Artbeat acts as an intermediary between the Artist (seller) and the Collector. Artbeat is responsible for posting and selling Artworks on behalf of its users. In respect of its services as provided for herein, Artbeat shall earn an agreed commission equal to 10% of the selling price of each Artwork (exclusive of VAT), which amount shall be added to the purchase price and shall be paid by the Collector, in addition to the cost of delivery and the transaction fee of the Third Party Payment Gateway, which sum shall also be paid by the Collector.
</p>
<br />

{/* Artist's Agreement */}
<h3 className="text-white"> <strong> The Artist&apos;s Agreement </strong></h3>
<p className="text-white">
  The Artist hereby agrees that any transaction concluded between them pursuant to the services contemplated in these Terms and Conditions shall constitute an independent sale agreement between the Artist and the Collector. Accordingly, the Artist hereby indemnifies and holds Artbeat in respect of any claim, loss, damage or harm which may be suffered by the Artist as a result of sale transaction concluded between the Artist and the Artist pursuant to these Terms and Conditions.
</p>
<p className="text-white">
  The Artist hereby grants Artbeat a licence to market and promote the Artworks for purposes of selling same in accordance with these Terms and Conditions.
</p>
<br />

{/* 4. Artwork Posting and Removal */}
<h3 className="text-white"> <strong> 4. Artwork Posting and Removal </strong></h3>
<p className="text-white">
  Artbeat reserves the right, in its sole and absolute discretion, to disallow the posting of any Artwork and/or to remove any Artwork from the platform at any time, including Artworks that have been purchased and/or are in the possession of a collector or another artist.
</p>
<br />

{/* 5. Disputes and Refunds */}
<h3 className="text-white"> <strong> 5. Disputes and Refunds </strong></h3>
<p className="text-white">
  Any disputes must be handled by the parties involved in the sale and purchase of the Artwork (i.e., the artist and the collector), and shall not involve Artbeat, except during the 5-day cooling-off period.
</p>
<br />

{/* 6. Privacy and Data Protection */}
<h3 className="text-white"> <strong> 6. Privacy and Data Protection </strong></h3>
<p className="text-white">
  Artbeat handles user data in compliance with the Protection of Personal Information (POPI) Act of South Africa. Users must request any changes to their personal information or any actions on the platform, such as, but not limited to, deleting a post, changing personal information, or deleting their account, from Artbeat, in writing, delivered to Artbeat. Users do not have access to any information held by Artbeat, including backend databases.
</p>
<p className="text-white">
  We collect information from artists and users when they register, with or use our site. We also collect information when you visit our site, via cookies and via web stats programs. We collect technical information such as your IP address, Web browser, and other information. When registering on our site, as appropriate, you may also be asked to provide your name, date of birth, image, gender, e-mail address, phone number, biography and banking details. You may, however, visit our site anonymously.
</p>
<br />

{/* What do we use your information for? */}
<h4 className="text-white">What do we use your information for?</h4>
<ul className="text-white">
  <li>To improve our website and business processes.</li>
  <li>To give the audience a brief background about the Artists.</li>
  <li>For marketing purposes.</li>
  <li>We may share to third parties for marketing or other business purposes without your consent.</li>
</ul>
<br />

{/* How do we protect your information? */}
<h4 className="text-white">How do we protect your information?</h4>
<p className="text-white">
  We implement web security measures to maintain the safety of information we collect.
</p>
<br />

{/* Do we disclose any information to outside parties? */}
<h4 className="text-white">Do we disclose any information to outside parties?</h4>
<p className="text-white">
  We may disclose your information to third parties when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety. Visitor information may also be provided to other parties for marketing, advertising, or other business purposes.
</p>
<br />

{/* Third party links */}
<h4 className="text-white">Third party links</h4>
<p className="text-white">
  Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.
</p>
<br />

{/* 7. Non-Disclosure Agreement (NDA) */}
<h3 className="text-white"> <strong> 7. Non-Disclosure Agreement (NDA) </strong></h3>
<p className="text-white">
  By using this platform, the user agrees to the terms and conditions of Artbeat&apos;s Non-Disclosure Agreement, a copy of which is attached hereto and signed by the parties simultaneously with the signing and acceptance of these terms and conditions. You agree not to disclose any information regarding Artbeats operations, including information about its backend databases.
</p>
<br />

{/* 8. Changes to the Terms and Conditions */}
<h3 className="text-white"> <strong> 8. Changes to the Terms and Conditions </strong></h3>
<p className="text-white">
  Artbeat reserves the right to modify these Terms and Conditions at any time. Changes to these Terms and Conditions will become effective upon such changes being posted to this website. It is the user’s obligation to periodically check these Terms and Conditions at the website for changes or updates. The User’s continued use of this website following the posting of changes or updates will be considered notice of the User’s acceptance to abide by and be bound by these Terms and Conditions, including such changes or updates.
</p>
<br />

{/* 9. Governing Law */}
<h3 className="text-white"> <strong> 9. Governing Law </strong></h3>
<p className="text-white">
  These Terms and Conditions are governed by the laws of South Africa, including, but not limited to the Protection of Personal Information (POPI) Act, South African Copyright Law, and the Consumer Protection Act South Africa Refund Policy.
</p>
<br />

{/* Non-Disclosure Agreement */}
<h3 className="text-white"> <strong> Non-Disclosure Agreement </strong></h3>
<p className="text-white">
  <strong>Non-Disclosure Agreement</strong> made and entered into by and between: HOCKNEY INVESTMENTS AND DEVELOPMENTS TRADING AS ARTBEAT (hereinafter referred to as “Artbeat”) Registration No.: 2022/707652/07 and Users of Artbeat (hereinafter referred to as the “Artist / Collector”).
</p>
<p className="text-white">
  WHEREAS: <br/>
  <br/> A. Artbeat possesses certain proprietary information, knowledge, experience, and data of a secret and confidential nature, all of which are regarded by it as valuable commercial assets of a highly confidential nature.
  <br/> B. During the course of business discussions, negotiations, meetings and activities with Artbeat, the Artist / Collector may receive, observe or otherwise have access to information that: a. relates to Artbeat’s past, present or future research, development, business activities, services and technical knowledge; and b. either has been identified in writing as confidential or is of such a nature (or has been disclosed in such a way) that it is obvious to the Artist / Collector that it is claimed as confidential (hereinafter referred to as “Confidential Information”). 
  <br/> C. Artbeat requires the Artist / Collector to enter into and be bound by a restriction as to the disclosure of Confidential Information and requires such undertaking to be reduced to writing.
</p>
<br />

{/* Definitions in Non-Disclosure Agreement */}
<h4 className="text-white">1. Definitions</h4>
<ul className="text-white pl-4">
  1.1. “Artbeat” shall mean Artbeat [The registered company Hockney Investments and Developments trading as Artbeat], as more fully described on the face of these Terms and Conditions; <br/> <br/>
  1.2. “Artist” shall mean an individual or entity registered on the Artbeat platform with the express intent to post, display, and sell original physical Artworks. The Artist is responsible for the creation of tangible works of art and engages with the platform as a seller, subject to the terms, conditions, and legal obligations outlined in the Artbeat Terms and Conditions for Artists, and in compliance with applicable South African laws, including but not limited to South African Copyright Law, as more fully described on the face of these Terms and Conditions; <br/>  <br/>
  1.3. “Collector” shall mean an individual or entity registered on the Artbeat platform with the express intent to follow artists and other collectors, and to purchase physical Artworks. The Collector engages with the platform as a Collector, subject to the terms, conditions, and legal obligations outlined in the Artbeat Terms and Conditions for Collectors, and in compliance with applicable South African laws, including but not limited to the Consumer Protection Act South Africa Refund Policy, as more fully described on the face of these Terms and Conditions; <br/> <br/>
  1.4. “Confidential Information” shall mean any and all information in relation to Artbeat’s past, present and/or future research, development, business activities, patents, products, services, technical knowledge, financial affairs, modus operandi and in particular any information relating to the operations of Artbeat of whatsoever nature, which has either been identified in writing as being confidential, or is of such a nature (or has been disclosed in such a way) that it is obvious to the Artist / Collector that such information is considered and deemed to be confidential, with particular reference to products and/or services which the parties contemplate shall be supplied by Artbeat to the Artist /Collector; <br/> <br/>
  1.5. “Effective Date” shall mean the date of signature of these Terms and Conditions by the last signing party hereto; <br/> <br/>
  1.6. an expression which denotes: 1.6.1. the singular shall include the plural and vice versa; 1.6.2. any gender shall include the other genders.<br/> <br/>
</ul>
<br />


{/* 2. Use of Confidential Information */}
<h4 className="text-white">2. Use of Confidential Information</h4>
<ul className="text-white pl-4">
  2.1. Confidential Information of Artbeat may be used by the Artist / Collector only in connection with the purpose(s) set forth in the agreement to which this is attached as an annexure. The Artist / Collector agree to protect the confidentiality of Artbeat’s Confidential Information in the same manner as they protect the confidentiality of their own proprietary and Confidential Information of like kind, but in any case, using reasonable care. <br/><br/>
  2.2. Except as necessary for the purpose(s) set forth in these Terms and Conditions, Confidential Information of Artbeat may not be copied or reproduced or disclosed to any other party by the Artist / Collector without Artbeat’s prior written consent. <br/><br/>
  2.3. With respect to the purpose(s) set forth in these Terms and Conditions, the Artist / Collector is not authorised to use the patent, name, logo or trademarks of the other in connection with any advertising, publicity or marketing or promotional materials or activities without the prior written consent of Artbeat. <br/><br/>
  2.4. Artbeat provides the Confidential Information as is. <br/> <br/>
  2.5. The Artist / Collector shall:<br/><br/>
    <ul className="pl-4">
      2.5.1. treat as strictly confidential any and all Confidential Information given or made known to him/her arising from this association; <br/><br/>
      2.5.2. keep all such Confidential Information obtained secret insofar as third parties are concerned and only use it in co-operation with Artbeat for the purpose expressly agreed upon and to disclose same to their necessary and authorised representatives and/or employees only on the basis of “need to know”; <br/><br/>
      2.5.3. accept responsibility for the observance of this secrecy agreement by their authorised and necessary representatives and/or employees; <br/><br/>
      2.5.4. if required, cause all of their necessary and authorised representatives and/or employees who are directly or indirectly given access to the said proprietary and Confidential Information to execute secrecy undertakings in a form acceptable to Artbeat in order to protect Artbeat against the unauthorised disclosure of such Confidential Information to any third party and to fully co-operate in the enforcement of such secrecy undertakings. <br/><br/>
    </ul>
</ul>
<br />

{/* 3. Ownership of Confidential Information */}
<h4 className="text-white">3. Ownership of Confidential Information</h4>
<p className="text-white">
  Confidential Information disclosed under these Terms and Conditions shall at all times remain the property of Artbeat. No license or other rights in or to the material disclosed is granted by these Terms and Conditions or any disclosure of Confidential Information under these Terms and Conditions, except as provided herein. All Confidential Information made available under these Terms and Conditions, including copies thereof, shall be returned to Artbeat (or, upon Artbeat’s request or consent, destroyed) upon the first to occur of: completion of the purpose(s) set forth in these Terms and Conditions; or the reasonable request of Artbeat; or cancellation of these Terms and Conditions for any reason whatsoever.
</p>
<br />

{/* 4. Exclusions */}
<h4 className="text-white">4. Exclusions</h4>
<ul className="text-white pl-4">
  4.1. Nothing in these Terms and Conditions shall prohibit or limit the Artist’s / Collector’s use of information (including but not limited to, ideas, concepts, know-how, techniques, and methodologies): <br/> <br/>
    <ul className="ml-0 pl-4">
      4.1.1. which at the time of disclosure is published or otherwise generally available to the public; <br/><br/>
      4.1.2. which after disclosure by Artbeat is published or becomes generally available to the public, otherwise than through any act or omission on the part of Artbeat; <br/><br/>
      4.1.3. which the Artist / Collector can show was in its possession at the time of disclosure and which was not acquired directly or indirectly from Artbeat; <br/><br/>
      4.1.4. rightfully acquired from other/s who did not obtain it under pledge of secrecy to Artbeat; <br/> <br/>
      4.1.5. which the Artist / Collector is obliged to disclose in terms of an order of court, subpoena or other legal process; <br/><br/>
      4.1.6. in the event that the Artist / Collector receives a subpoena or other validly issued administrative or judicial process requesting Confidential Information of Artbeat, the Artist / Collector shall promptly notify Artbeat thereof. <br/><br/>
    </ul>
</ul>
<br />

{/* 5. Non-Solicitation */}
<h4 className="text-white">5. Non-Solicitation</h4>
<p className="text-white">
  The Artist / Collector undertakes, in favour of Artbeat, not to approach, solicit or become, directly and/or indirectly, engaged or involved with any existing customers or suppliers or business of Artbeat, with effect from the Effective Date and for a period of 24 (twenty four) months after the termination or cancellation of the agreement in respect of which this document is an annexure.
</p>
<br />

{/* 6. Warranties */}
<h4 className="text-white">6. Warranties</h4>
<ul className="text-white pl-4">
  6.1. Each party warrants and represents that it possesses all necessary powers, rights and authority to lawfully enter into these Terms and Conditions and to make the disclosures subject to these Terms and Conditions. <br/> <br/>
  6.2. Save as specifically provided for in these Terms and Conditions, Artbeat makes no representation, warranty, assurance, guarantee or endorsement to the Artist / Collector concerning the Confidential Information and/or the reports, whether with regard to their accuracy, completeness or otherwise and the disclosure will have no liability to the Artist / Collector or any other party in connection therewith. <br/>
</ul>
<br />

{/* 7. Dispute Resolution */}
<h4 className="text-white">7. Dispute Resolution</h4>
<ul className="text-white pl-4">
  7.1. Should any dispute, disagreement or deadlock or claim arise between the parties concerning these Terms and Conditions, the parties shall attempt to resolve the dispute by a process of negotiation within 10 (ten) business days from the date of the written invitation. <br/><br/>
  7.2. If the dispute has not been resolved by such negotiation within 7 (seven) days of the commencement of attempts to resolve it, then the dispute shall be referred to arbitration in terms of the remaining provisions of this clause 7.<br/><br/>
  7.3. The arbitration shall be held in Johannesburg unless otherwise agreed, and in a summary manner to be completed as soon as possible. <br/><br/>
  7.4. The arbitrator shall be agreed upon between the parties, with specific qualifications based on the nature of the dispute, and if not agreed within 14 (fourteen) days, appointed by the Arbitration Foundation of Southern Africa. <br/><br/>
  7.5. The arbitration shall be held in accordance with the rules of AFSA, with the arbitrator providing written reasons for any decision made. <br/><br/>
  7.6. The award shall be subject to a right of appeal within 10 (ten) days, with the appeal considered by an arbitration appeal panel. <br/><br/>
  7.7. The decision of the arbitrator or the arbitration appeal panel is final and binding and may be made an order of any court of competent jurisdiction. <br/><br/>
  7.8. The provisions of this clause 7 shall survive any termination of these Terms and Conditions. <br/><br/>
  7.9. Nothing in this clause 7 shall preclude any party from seeking interim or urgent relief from any competent court pending arbitration proceedings. <br/><br/>
</ul>
<br />

{/* 8. Breach */}
<h4 className="text-white">8. Breach</h4>
<p className="text-white">
  In the event of a breach or threat of a breach of these Terms and Conditions by the Artist / Collector, Artbeat is entitled to a restraining order, preliminary injunction, or similar relief to specifically enforce the terms or prevent, cure, or reduce the adverse effects of the breach.
</p>
<br />

{/* 9. Governing Law and Jurisdiction */}
<h4 className="text-white">9. Governing Law and Jurisdiction</h4>
<p className="text-white">
  These Terms and Conditions shall be governed by and interpreted in accordance with the laws of the Republic of South Africa and the parties submit to the exclusive jurisdiction of the High Court of South Africa for the adjudication of disputes not resolved amicably.
</p>
<br />

{/* 10. Indemnity and Licensing */}
<h4 className="text-white">10. Indemnity and Licensing</h4>
<p className="text-white">
  10.1 The Collector and the Artist agree that any transaction concluded between them shall constitute an independent sale agreement. Both parties indemnify and hold Artbeat in respect of any claim, loss, damage, or harm resulting from such transactions.
</p>
<br />

{/* 11. Entire Agreement */}
<h4 className="text-white">11. Entire Agreement</h4>
<p className="text-white">
  These Terms and Conditions represent the exclusive agreement between the parties regarding its subject matter and supersede all prior representations, promises, inducements, proposals, discussions, and communications.
</p>
<br />

{/* 11. Cancellation */}
<h4 className="text-white">11. Cancellation</h4>
{/* Content for Cancellation goes here */}

{/* Termination, Cancellation, or Breach */}
<h4 className="text-white">Termination, Cancellation, or Breach</h4>
<p className="text-white">
  The termination, cancellation or breach of these Terms and Conditions by a party shall not relieve any party from its obligation to preserve, safeguard and protect the Confidential Information disclosed hereunder. The parties agree that from the date of cancellation for whatever reason, they shall be entitled to rely on such patents or other intellectual property as they may then own for the protection of any information disclosed to each other pursuant to these Terms and Conditions. Confidentiality undertakings furnished by the parties in terms of these Terms and Conditions shall survive the termination or cancellation of these Terms and Conditions.
</p>
<br />

{/* 12. Domicilium */}
<h4 className="text-white">12. Domicilium</h4>
<p className="text-white">
  12.1. The parties choose as their domicilia citandi et executandi for all purposes under these Terms and Conditions, whether in respect of court process, notices or other documents or communications of whatsoever nature, the following addresses:
</p>
<ul className="text-white pl-4">
  12.1.1. Artbeat: 9 Boundary Road Green Point 8001, Cape Town. E-mail: support@artbeat.ink <br/><br/>
  12.1.2. Artist / Collector: Physical address given by user on checkout and e-mail address used to sign up for Artbeat. <br/><br/>
  12.2. Any notice or communication required or permitted to be given in terms of these Terms and Conditions shall be valid and effective only if in writing. <br/><br/>
  12.3. Any party may change its domicilium to another physical address, postal address, or telefax number within the Republic of South Africa by giving notice to the other parties, effective on the 7th business day from the receipt of the notice. <br/><br/>
  12.4. Notices sent by various methods shall be deemed received under certain conditions specified. <br/><br/>
  12.5. A written notice or communication actually received by a party shall be an adequate notice or communication, even if not sent to or delivered at its chosen domicilium citandi et executandi. <br/><br/>
</ul>
<br />

{/* 13. General Provisions */}
<h4 className="text-white">13. General Provisions</h4>
<ul className="text-white pl-4">
  13.1. Variation: No variation of these Terms and Conditions shall be effective unless reduced to writing and signed by both parties.<br/><br/>
  13.2. Indulgence: No indulgence granted by any party shall constitute a waiver of any of its rights under these Terms and Conditions; such a party shall not be precluded from exercising any rights against the other that may have arisen in the past or may arise in the future.<br/><br/>
</ul>
<br />









      </div>
    </div>
  );
};

export default TermsAndConditions;