import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { List } from "antd";
import LoadingSpinner from "components/LoadingSpinner/loadingSpinner";
import { useMessage } from "components/Messages/MessageProvider";
import { useAuth } from "hooks/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import ArtworkPost from "../../components/ArtworkPost/ArtworkPost";
import { apiService } from "../../services/api";
import { storageService } from "../../services/storage";
import { RootState } from "../../store";
import useScrollPosition from "hooks/useScrollPosition";
import {
	prependArtworks,
	setArtworks,
	setAspectRatios,
	setfeedNextPageToken,
	updateAspectRatios,
} from "../../store/feed/feedSlice";

export default function HomePage() {
	const user = useAuth();
	const artworksMap = useSelector((state: RootState) => state.feed.artworks);
	const artworks = Object.values(artworksMap); // Convert the map to an array for rendering
	useScrollPosition("homePageScrollPosition"); 
	const nextPageToken = useSelector(
		(state: RootState) => state.feed.feedNextPageToken
	);

	// Dispatch hook to dispatch actions to the store
	const dispatch = useDispatch();

	const [artworksLoading, setArtworksLoading] = useState(true);

	const [upToDate, setUpToDate] = useState(false);
	const { errorMessage, successMessage } = useMessage();

	//function to retrieve aspect ratios in list
	const fetchAspectRatios = async (artworkList) => {
		const aspectRatioPromises = artworkList.map((artwork) =>
			storageService.getImageMetadata(artwork.name).then((metadata) => {
				if (metadata.customMetadata.aspectRatio) {
					return [
						artwork.name,
						parseFloat(metadata.customMetadata.aspectRatio),
					];
				} else {
					return [artwork.name, 1];
				}
			})
		);

		const aspectRatiosArray = await Promise.all(aspectRatioPromises);
		const aspectRatios = Object.fromEntries(aspectRatiosArray);
		return aspectRatios;
	};

	const fetchArtworks = useCallback(
		async (isRefresh = false) => {
			if (!user) {
				return;
			}

			// If artworks are already present in the store and it" s not a refresh action, don" t fetch them again
			if (artworks.length > 15 && !isRefresh) {
				setArtworksLoading(false);
				return;
			}

			try {
				console.log("Fetching Artworks");
				setArtworksLoading(true);
				const response = await apiService.listArtworks({
					pageSize: 10,
					orderBy: "create_time desc",
				});

				// Filter out artworks that already exist in the state
				const newArtworks = response.artworks.filter(
					(newArtwork) =>
						!artworks.some(
							(existingArtwork) =>
								existingArtwork.name === newArtwork.name
						)
				);

				const newAspectRatios = await fetchAspectRatios(
					response.artworks
				);

				if (isRefresh) {
					dispatch(prependArtworks(newArtworks));
				} else {
					dispatch(setArtworks(response.artworks));
					dispatch(setfeedNextPageToken(response.nextPageToken));
					dispatch(setAspectRatios(newAspectRatios));
				}

				if (response.artworks.length < 10) {
					setUpToDate(true);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setArtworksLoading(false);
			}
		},
		[dispatch, artworks, user]
	);

	const fetchMoreArtworks = async () => {
		if (!upToDate) {
			try {
				console.log("Fetching more artworks");
				setArtworksLoading(true);
				const newArtworks = await apiService.listArtworks({
					readPaths: ["name"],
					pageSize: 10,
					orderBy: "create_time desc",
					pageToken: nextPageToken,
				});

				const allArtworks = [...artworks, ...newArtworks.artworks];
				const newAspectRatios = await fetchAspectRatios(
					newArtworks.artworks
				);
				dispatch(updateAspectRatios(newAspectRatios));
				dispatch(setArtworks(allArtworks));

				if (newArtworks.artworks.length === 0) {
					setUpToDate(true);
				} else {
					dispatch(setfeedNextPageToken(newArtworks.nextPageToken));
				}
			} catch (error) {
				errorMessage("There was a problem loading more artworks");
			} finally {
				setArtworksLoading(false);
			}
		}
	};

	useEffect(() => {
		document.title = "Home | Artbeat ";
	}, []);

	useEffect(() => {
		fetchArtworks();
	}, []);


	return (
		<>
			<div
				className="bg-black"
				id="feed"
				style={{
					height: "100%",
				}}
			>
				<InfiniteScroll
					dataLength={artworks.length}
					scrollThreshold={0.8}
					next={fetchMoreArtworks}
					hasMore={!upToDate}
					loader={
						artworksLoading && (
							<div
								className="flex justify-center items-center"
								style={{ height: "100px" }}
							>
								{LoadingSpinner("#1EB9A5")}
							</div>
						)
					}
					refreshFunction={() => fetchArtworks(true)}
					pullDownToRefresh
					pullDownToRefreshThreshold={50}
					pullDownToRefreshContent={
						<h3 style={{ textAlign: "center", color: "white" }}>
							&#8595; Pull down to refresh
						</h3>
					}
					releaseToRefreshContent={
						<h3 style={{ textAlign: "center", color: "white" }}>
							&#8593; Release to refresh
						</h3>
					}
				>
					<List
						itemLayout="vertical"
						dataSource={artworks}
						split={false}
						locale={{
							emptyText: <></>,
						}}
						className="pb-14"
						renderItem={(artwork: Artwork) => (
							<List.Item className="p-0 m-0">
								<ArtworkPost
									name={artwork.name}
									key={artwork.name}
									artworkIn={artwork}
								></ArtworkPost>
							</List.Item>
						)}
					></List>
				</InfiniteScroll>
			</div>
		</>
	);
}
