import React, { useCallback, useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  Payment,
  Payment_State,
} from "@hockney-app/proto/payments/v1alpha1/payments_pb";
import { Artwork } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { apiService} from "../../services/api";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { Skeleton } from "antd";
import {PrimaryButton} from "../../components/Buttons/PrimaryButton";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import { useMessage } from "components/Messages/MessageProvider";


const PaymentResultPage = () => {

  const { paymentId } = useParams(); // Get the paymentId from the URL params
  const [ payment, setPayment] = useState(new Payment()); // Store the payment object
  const [loading, setLoading] = useState(false);
  const [artwork, setArtwork] = useState(new Artwork());
  const {errorMessage, successMessage} = useMessage();  


  const navigate = useNavigate();

  // Constants for confetti animation
  const { width, height } = useWindowSize();

  // Get payment polls the API for the payment details. Once the payment is no longer pending, it returns the payment object.
  const getPayment = async (paymentId: string): Promise<Payment> => {
    const payment = await apiService.getPayment(`payments/${paymentId}`);

    if (payment.state === Payment_State.PENDING) {
      // If the payment is still pending, we need to wait for it to be successful
      // wait 1 second and try again
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return getPayment(paymentId);
    } else {
      return payment;
    }
  };

  const getPaymentDetails = useCallback(
    async (paymentId: string) => {
    try {
      setLoading(true);

      const payment = await getPayment(paymentId);
      setPayment(payment); // Set the payment object

      const artwork = await apiService.getArtwork(payment.artworks[0], {});
      setArtwork(artwork); // Set the artwork

    } catch (error) {
        console.error(error);
        errorMessage("Error getting payment details.");
    } finally {
      // Wait 1 second and then stop the loading indicator
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    // Get the details of the payment
    getPaymentDetails(paymentId);
  }, [paymentId]);

  // Render logic based on the payment status
  const renderPaymentStatus = () => {
    switch (payment.state) {
      case Payment_State.STATE_UNSPECIFIED:
        // Show a loading indicator or appropriate message
        return <div>Loading payment status...</div>;
      case Payment_State.PENDING:
        // Show the pending message
        return <div>Payment Pending</div>;
      case Payment_State.SUCCESSFUL:
        // Show the success message and order ID
        return (
          <div className="text-[#FFFF]">
            Congratulations!
          </div>
        );
      case Payment_State.CANCELLED:
        // Show the cancelled message
        return <div>Payment Cancelled</div>;
      case Payment_State.FAILED:
        // Show the failure message
        return <div>Payment Failed</div>;
      default:
        // Show a default message if the payment status is unknown
        return <div>Unknown Payment Status</div>;
    }
  };

  const renderPaymentMessage = () => {
    switch (payment.state) {
      case Payment_State.STATE_UNSPECIFIED:
        // Show a loading indicator or appropriate message
        return <div>Waiting for your artwork...</div>;
      case Payment_State.PENDING:
        // Show the pending message
        return <div>Payment Pending</div>;
      case Payment_State.SUCCESSFUL:
        return (
          <div className="text-[#FFFF]">
            We’re getting your artwork ready. We’ll be in touch soon with your delivery details.
          </div>
        );
      case Payment_State.CANCELLED:
        // Show the cancelled message
        return <div>It seems like your payment has been cancelled. Please try again.</div>;
      case Payment_State.FAILED:
        // Show the failure message
        return <div>Unfortunately your payment has failed. Please try again.</div>;
      default:
        // Show a default message if the payment status is unknown
        return <div></div>;
    }
  };

  const renderConfetti = () => {
    if (payment.state === Payment_State.SUCCESSFUL) {
      return <Confetti
          width={width}
          height={height}
          run={true}
          colors={["#4BC7B7", "#7B61FF", "#0C4A42"]}
          recycle={false}
          numberOfPieces={100}
      />;
    } else {
      return <></>;
    }
  };

  const renderButton = () => {
    switch (payment.state) {
      case Payment_State.STATE_UNSPECIFIED:
        return <PrimaryButton onClick={() => {
                navigate("/home");
            }} text={"Return"}/>;
      case Payment_State.PENDING:
        return <div>Payment Pending</div>;
      case Payment_State.SUCCESSFUL:
        return <PrimaryButton onClick={() => {
          logEvent( analytics, "purchase", {
            transaction_id: paymentId, // Unique ID for the transaction (important for deduplication)
            item_id: artwork.name,
            item_name: artwork.title,
            item_category: artwork.medium,
            item_variant: artwork.style,
            item_price: artwork.currentPrice,         
        });
          navigate("/home");
        }} text={"Return to home"}/>;
      case Payment_State.CANCELLED:
        return <PrimaryButton onClick={() => {
          // Get the artworkId by taking the artwork name
          // and splitting the string of the from artworks/artworkId
          const artworkId = artwork.name.split("/")[1];
          navigate("/checkout/"+ artworkId);
        }} text={"Try again"}/>;
      case Payment_State.FAILED:
        return <PrimaryButton onClick={() => {
          // Get the artworkId by taking the artwork name
          // and splitting the string of the from artworks/artworkId
          const artworkId = artwork.name.split("/")[1];
          navigate("/checkout/"+ artworkId);
        }} text={"Try again"}/>;
      default:
        // Show a default message if the payment status is unknown
        return <div></div>;
    }
  };

  return (
      <>
        { loading ? (
            <>
              <div className="flex flex-col items-center justify-center h-screen bg-[#000]">
                <div className="bg-[#191919] items-center justify-center rounded-xl flex-col gap-0 w-[21.4375rem] absolute overflow-hidden">
                  <div className="pb-5 flex flex-col items-center justify-start self-stretch shrink-0 relative">
                    <div
                        className="rounded-xl shrink-0 h-[15.12rem] w-[15.12rem] relative overflow-hidden mx-4 mt-5"
                    >
                      <Skeleton.Image active style={{
                        width: "100%",
                        height: "100%",
                      }}/>
                    </div>
                  </div>

                  <div className="flex-col gap-0 items-center justify-start self-stretch shrink-0 relative pb-5 px-4">
                    <div className="pt-1.25rem pr-1rem pb-1.25rem pl-1rem flex flex-col gap-0.75rem items-center justify-start self-stretch shrink-0 relative">
                      <div className="flex flex-col gap-0.25rem items-center justify-start self-stretch shrink-0 relative">
                        <div
                            className="text-[#FFF] text-center relative self-stretch"
                            style={{
                              font: "var(--textlg-semibold, 600 1.125rem/1.75rem 'Inter', sans-serif)",
                            }}
                        >
                          <Skeleton active paragraph={false}/>
                        </div>
                        <div
                            className="text-[#737373] text-center relative self-stretch "
                            style={{
                              font: "var(--textsm-regular, 400 0.875rem/1.25rem 'Inter', sans-serif)",
                            }}
                        >
                          <Skeleton active paragraph={false}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        ) : (
            <div className="flex flex-col items-center justify-center h-screen bg-[#000]">
              {renderConfetti()}
              <div className="bg-[#191919] items-center justify-center rounded-xl flex-col gap-0 w-[21.4375rem] absolute overflow-hidden">
                <div className="pb-5 flex flex-col items-center justify-start self-stretch shrink-0 relative">
                  <img
                      className="rounded-xl shrink-0 h-[15.12rem] relative overflow-hidden mx-4 mt-5"
                      src={artwork.imageUrls[0]}
                      alt="Artwork Placeholder"
                  />
                </div>

                <div className="flex-col gap-0 items-center justify-start self-stretch shrink-0 relative pb-5 px-4">
                  <div className="pt-1.25rem pr-1rem pb-1.25rem pl-1rem flex flex-col gap-0.75rem items-center justify-start self-stretch shrink-0 relative">
                    <div className="flex flex-col gap-0.25rem items-center justify-start self-stretch shrink-0 relative">
                      <div
                          className="text-[#FFF] text-center relative self-stretch"
                          style={{
                            font: "var(--textlg-semibold, 600 1.125rem/1.75rem 'Inter', sans-serif)",
                          }}
                      >
                        {renderPaymentStatus()}
                      </div>

                      <div
                          className="text-[#737373] text-center relative self-stretch"
                          style={{
                            font: "var(--textsm-regular, 400 0.875rem/1.25rem 'Inter', sans-serif)",
                          }}
                      >
                        {renderPaymentMessage()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-auto mb-10">
                {renderButton()}
              </div>
            </div>
        )}
      </>
  );
};

export default PaymentResultPage;