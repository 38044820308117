import { Artwork_Medium } from "@hockney-app/proto/artworks/v1alpha1/artworks_pb";
import { Switch } from "antd";
import { ReactComponent as Coins } from "assets/icons/coins-02.svg";
import { ReactComponent as XClose } from "assets/icons/x-close.svg";
import PillButton from "components/Buttons/PillButton";
import React, { useEffect, useRef, useState } from "react";

interface FilterModalProps {
	selectedMedium: Artwork_Medium | ""; // Replace `Artwork_Medium` with the actual type if different
	handleMediumClick: (medium: any) => void; // Replace `any` with the actual type if known
	artworkMediums: any[]; // Replace `any[]` with the actual type if known
	isExpanded: boolean;
	setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
	setPriceRangeMin: React.Dispatch<React.SetStateAction<number>>;
	setPriceRangeMax: React.Dispatch<React.SetStateAction<number>>;
	priceRangeMin: number;
	priceRangeMax: number;
	setSelectedPriceOrder: React.Dispatch<React.SetStateAction<string>>;
	selectedPriceOrder: string;
}

export default function FilterModal({
	selectedMedium,
	handleMediumClick,
	artworkMediums,
	setPriceRangeMax,
	setPriceRangeMin,
	isExpanded,
	setIsExpanded,
	priceRangeMax,
	priceRangeMin,
	setSelectedPriceOrder,
	selectedPriceOrder,
}: FilterModalProps) {
	// temporary values of priceRangeMin and priceRangeMax.
	const [tempPriceRangeMin, setTempPriceRangeMin] = useState(0);
	const [tempPriceRangeMax, setTempPriceRangeMax] = useState(0);

	// temporary values for medium
	const [tempSelectedMedium, setTempSelectedMedium] =
		useState(selectedMedium);

	const modalRef = useRef(null); // <-- create a ref for the modal

	// handles clicking outside the modal to close the modal
	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsExpanded(false);
		}
	};

	// Handle toggle switch
	const handlePriceOrderToggle = (checked) => {
		setSelectedPriceOrder(checked ? "asc" : "desc");
	};

	// useEffect for updating state
	useEffect(() => {
		if (!isExpanded) {
			// This block will run when modal is closed
			if (priceRangeMin !== tempPriceRangeMin) {
				setPriceRangeMin(tempPriceRangeMin);
			}
			if (priceRangeMax !== tempPriceRangeMax) {
				setPriceRangeMax(tempPriceRangeMax);
			}
			if (selectedMedium !== tempSelectedMedium) {
				handleMediumClick(tempSelectedMedium);
			}
		}
	}, [isExpanded]);

	useEffect(() => {
		if (isExpanded) {
			setTempPriceRangeMin(priceRangeMin);
			setTempPriceRangeMax(priceRangeMax);
			setTempSelectedMedium(selectedMedium);
			// If modal is expanded, add the event listener to check for clicks outside
			document.addEventListener("mousedown", handleOutsideClick);
		} else {
			// If modal is collapsed, remove the event listener
			document.removeEventListener("mousedown", handleOutsideClick);
		}

		// Clean up the event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [isExpanded, priceRangeMin, priceRangeMax, selectedMedium]);

	return (
		<div ref={modalRef}>
			{isExpanded && (
				<div className="fixed bottom-0 w-full h-3/4 transform transition-transform duration-1000 translate-y-0 origin-bottom z-30 rounded-t-3xl bg-greyscale-700 flex flex-col">
					<div className="flex items-center justify-center bg-greyscale-650 rounded-t-3xl w-full border-b-2 border-greyscale-300">
						<p className="text-white font-ubuntu text-lg font-bold text-center m-4 flex items-center space-x-2">
							<span>Filter</span>
						</p>
					</div>

					{/* Medium Pill buttons */}
					<div className="flex flex-col hide-scrollbar gap-y-3 overflow-x-auto my-4 px-4">
						{Array.from({
							length: Math.ceil(artworkMediums.length / 10),
						}).map((_, i) => (
							<div key={i} className="flex space-x-2">
								{" "}
								{/* Array of 11 mediums per line, and rest wrap to next line */}
								{artworkMediums
									.slice(i * 11, (i + 1) * 11)
									.map((medium, index) => (
										<PillButton
											key={medium.enum}
											label={medium.displayName}
											icon={
												tempSelectedMedium ===
												medium.enum ? (
													<XClose
														className="mt-1 w-4 h-4"
														onClick={() =>
															handleMediumClick(
																medium.enum
															)
														}
													/>
												) : null
											}
											className={`text-sm font-inter py-1.5 px-2.5 whitespace-nowrap ${
												tempSelectedMedium ===
												medium.enum
													? "bg-teal-900 border-teal-100 text-white border"
													: ""
											}`}
											onClick={() =>
												setTempSelectedMedium(
													medium.enum
												)
											}
										/>
									))}
							</div>
						))}
					</div>

					{/* Price Filter */}
					<div className="flex flex-col p-4 items-center  self-stretch">
						<div className="flex p-0 justify-start gap-0 self-stretch">
							<p className="font-ubuntu text-base font-normal text-white">
								Filter by Price
							</p>
						</div>

						<div className="flex flex-row justify-center items-center gap-x-2 self-stretch">
							<div className="flex flex-row gap-x-2 p-3 items-center flex-1 rounded-lg border border-greyscale-600 bg-greyscale-650">
								<Coins />
								<input
									type="number"
									onChange={(e) => {
										const value = Number(e.target.value);
										setTempPriceRangeMin(value);
									}}
									className="w-full rounded-md text-body placeholder-greyscale-450 bg-transparent text-white font-inter border-none focus:outline-none"
									placeholder="Min"
									value={tempPriceRangeMin || ""}
								/>
							</div>
							<div className="flex flex-row gap-x-2 p-3 items-center flex-1 rounded-lg border border-greyscale-600 bg-greyscale-650">
								<Coins />
								<input
									type="number"
									onChange={(e) => {
										const value = Number(e.target.value);
										setTempPriceRangeMax(value);
									}}
									className="w-full rounded-md text-body placeholder-greyscale-450 bg-transparent text-white font-inter border-none focus:outline-none"
									placeholder="Max"
									value={tempPriceRangeMax || ""}
								/>
							</div>
						</div>
					</div>

					{tempPriceRangeMax || tempPriceRangeMin ? (
						<div className="flex flex-col pr-4 pl-4 pb-4 items-start self-stretch">
							<div className="flex p-0 justify-start gap-0 self-stretch">
								<p className="font-ubuntu text-base font-normal text-white">
									Toggle Price High/Low
								</p>
							</div>
							<Switch
								checked={selectedPriceOrder === "asc"}
								onChange={handlePriceOrderToggle}
								className="mb-2"
							/>
							{selectedPriceOrder === "asc"
								? "Low to High"
								: "High to Low"}
						</div>
					) : (
						<div></div>
					)}
				</div>
			)}
		</div>
	);
}
